import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Spinner } from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import { newCurrencyFunc } from "../../../store/Slices/currency/NewCurrencySlicer";
const newCurrencySchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter name"),
  
    code: Yup.string()
    //   .min(6, "Job postion must consist of at least 3 characters ")
    //   .max(300, "Job postion must consist of at most 300 characters ")
      .required("Please enter currency code"),
    symbol: Yup.string()
    //   .min(6, "Job postion must consist of at least 3 characters ")
    //   .max(300, "Job postion must consist of at most 300 characters ")
      .required("Please enter currency symbol"),
  
    exchange_rate: Yup.string()
    //   .min(6, "Job location must consist of at least 3 characters ")
    //   .max(300, "Job location must consist of at most 300 characters ")
      .required("Please enter currency exchange rate"),
    // arlocation: Yup.string()
    //   .min(6, "Job postion must consist of at least 3 characters ")
    //   .max(300, "Job postion must consist of at most 300 characters ")
    //   .required("Please enter a Job arabic loction"),
    // enCompanyName: Yup.string()
      // .min(6, "Job postion must consist of at least 3 characters ")
      // .max(300, "Job postion must consist of at most 300 characters ")
    //   .required("Please enter English Company Name"),
    // arCompanyName: Yup.string()
      // .min(6, "Job postion must consist of at least 3 characters ")
      // .max(300, "Job postion must consist of at most 300 characters ")
    //   .required("Please enter Arabic Company Name"),
  });
const NewCurrencyForm = () => {
  const { loading, error } = useSelector((state) => state.newCurrencySlicer);

  const dispatch = useDispatch();
  const [userdata, setUserData] = useState();
  const [status , setStatus] = useState(false);
  const history = useHistory();
  // File Refrances Basic Images

  // Get All Data Function
  const handleSubmitData = (value, { resetForm }) => {
    const formData = new FormData();
    formData.append("name" , value.name);
    formData.append("symbol" , value.symbol);
    formData.append("exchange_rate" , value.exchange_rate);
    formData.append("code" , value.code);
    formData.append("status" , status);
    const sendDataFunc = () => {
      dispatch(newCurrencyFunc(
        {
            name : value.name,
            symbol : value.symbol,
            exchange_rate : value.exchange_rate,
            code : value.code,
            status : status,
        }
      )).then((resulte) => {
        if (resulte.payload.message === " Currency created Successfully") {
          Swal.fire({
            title: resulte.payload.message,
            text: "Go to Currencies Page !",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, stay and add new Currency",
            confirmButtonText: "Yes, Go to Currencies Page",
          }).then((result) => {
            if (result.isConfirmed) {
                history.push(`/currencies`);
            } else {
              resetForm();
              setStatus(false)
            }
          });
          setStatus(false);
        } else {
          swal("error", resulte.payload.message, "warning");
        }
      });
    }
    sendDataFunc();
  };

  useEffect(() => {
    window.scroll(0, 0);
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setUserData(data);
    } else {
      history.push("/login");
    }
  }, []);


  return (
    <Fragment>
      <PageTitle activeMenu="New Currency" motherMenu="Currencies" />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-body">
                <Formik
                    initialValues={{
                        name: newCurrencySchema.name,
                        symbol: newCurrencySchema.symbol,
                        code: newCurrencySchema.code,
                        exchange_rate: newCurrencySchema.exchange_rate,
                    }}
                    validationSchema={newCurrencySchema}
                    onSubmit={handleSubmitData}
                >
                    {
                    (
                        {
                    values,
                    errors,
                    setValues,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    }) => (
                    <form className="row" onSubmit={handleSubmit}>
                        {console.log(values)}
                        <div>
                        <h1>Currency Infromation</h1>
                        <section>
                            <div className="row">
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.name
                                    ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter Name
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Name.."
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.name && errors.name}
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.code
                                    ? errors.code
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter Code
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Currency Code.."
                                    name="code"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.code}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.code && errors.code}
                                </div>
                                </div>
                            </div>
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.symbol
                                    ? errors.symbol
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter Symbol
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Symbol.."
                                    name="symbol"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.symbol}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.symbol && errors.symbol}
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                    values.exchange_rate
                                        ? errors.exchange_rate
                                        ? "is-invalid"
                                        : "is-valid"
                                        : ""
                                    }`}
                                >
                                    <label className="text-label">
                                    Enter Exchange Rate
                                    </label>
                                    <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fa-solid fa-file-signature"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Exchange Rate.."
                                        name="exchange_rate"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.exchange_rate}
                                    />
                                    <div
                                        id="val-name-error"
                                        className="invalid-feedback animated fadeInUp"
                                        style={{ display: "block" }}
                                    >
                                        {errors.exchange_rate && errors.exchange_rate}
                                    </div>
                                    </div>
                                </div>
                                {/* <div
                                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                    values.arlocation
                                        ? errors.arlocation
                                        ? "is-invalid"
                                        : "is-valid"
                                        : ""
                                    }`}
                                >
                                    <label className="text-label">
                                    Enter Arabic Job Location
                                    </label>
                                    <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fa-solid fa-file-signature"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter a arabic location.."
                                        name="arlocation"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.arlocation}
                                    />
                                    <div
                                        id="val-name-error"
                                        className="invalid-feedback animated fadeInUp"
                                        style={{ display: "block" }}
                                    >
                                        {errors.arlocation && errors.arlocation}
                                    </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="visiblaty-div row mb-2">
                                <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                                <input id="visiblaty"  type="checkbox" className="mr-1 cursor-pointer scale2"
                                    onChange={(e) => {
                                    setStatus(!status);
                                    }}
                                    checked = {status ? true : false}
                                />
                                <label htmlFor="visiblaty" className="is-free-label cursor-pointer pl-2 mb-0">Currency Status</label>
                                </div>
                            </div>
                            </div>
                        </section>
                        </div>
                        <div className="row">
                            <div className="col">
                                {loading ? (
                                <>
                                    <Button variant="primary" disabled>
                                    <Spinner animation="grow" variant="info" />
                                    </Button>
                                </>
                                ) : (
                                <>
                                    <Button variant="primary" type="submit">
                                    Create
                                    </Button>
                                </>
                                )}
                            </div>
                        </div>
                    </form>
                    )
                    }
                </Formik>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewCurrencyForm;
