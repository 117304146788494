import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const deleteCourseSubscriptionApi = createAsyncThunk(
  "subscriptions/course/delete",
  async (subscriptionId) => {
    try {
      const tokenData = await JSON.parse(localStorage.getItem("userData"))
        .token;
      return await fetch(
        `${process.env.REACT_APP_VERSEL_API}/api/user/subscripers/delete/subscriptions/course/${subscriptionId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${tokenData}`,
          },
        }
      ).then((res) => res.json());
    } catch (error) {
      swal("Error", error, "info");
      return error;
    }
  }
);

const deleteCourseSubscriptionSclicer = createSlice({
  name: "deleteCourseSubscription",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteCourseSubscriptionApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCourseSubscriptionApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteCourseSubscriptionApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data Deleted Successfully") {
        state.response = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default deleteCourseSubscriptionSclicer.reducer;
