import React, { Fragment, useRef, useState } from "react";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import swal from "sweetalert";
import { Alert } from "react-bootstrap";
//** Import Image */
import avatarMain from "./../../../../images/avatar/avatar-main.png";

import profile01 from "../../../../images/profile/1.jpg";
import profile02 from "../../../../images/profile/2.jpg";
import profile03 from "../../../../images/profile/3.jpg";
import profile04 from "../../../../images/profile/4.jpg";
import profile05 from "../../../../images/profile/5.jpg";
import profile06 from "../../../../images/profile/6.jpg";
import profile07 from "../../../../images/profile/7.jpg";
import profile08 from "../../../../images/profile/8.jpg";
import profile09 from "../../../../images/profile/9.jpg";
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";
import InstructorCourses from "../../Instructor/InstructorCourses";
import CustomCouseCard from "../../Courses/CustomCouseCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getsingleUserAPi } from "../../../../store/GetSingleUSerSlicer";
import { updateUserApi } from "../../../../store/UpdateUserDataSlicer";

const AppProfile = () => {
  const { user_id } = useParams();
  const { loading, data, error } = useSelector((state) => state.getSingleUser);
  const { loading: updateLoading, error: updateError } = useSelector(
    (state) => state.updateUser
  );
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const [activeToggle, setActiveToggle] = useState("aboutMe");

  ///////////////////////////////
  //Update Form
  const [showUpdateDataForm, setShowUpdateDataForm] = useState(false);

  const [fullName, setFullName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [imgWallpaper, setImgWallpaper] = useState("");
  const [imgWallpaperFile, setImgWallpaperFile] = useState("");
  const fileUploaderImgWallpaper = useRef(null);
  const openFileUploaderImgWallpaper = () => {
    fileUploaderImgWallpaper.current.click();
  };
  //form errors
  const [fullNameErrorMessage, setFullNameErrorMessage] = useState("");
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

  const checkErrorMessages = () => {
    if (userData.role == "teacher") {
      if (
        fullNameErrorMessage === "" &&
        titleErrorMessage === "" &&
        emailErrorMessage === "" &&
        phoneErrorMessage === ""
      ) {
        return true;
      }
      return false;
    } else {
      if (
        fullNameErrorMessage === "" &&
        emailErrorMessage === "" &&
        phoneErrorMessage === ""
      ) {
        return true;
      }
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkErrorMessages()) {
      swal("Worrning!", "Pleace Enter Right Data", "error");
    } else {
      var formData;
      if (userData.role == "teacher") {
        formData = {
          id: userData.id,
          name: fullName,
          title: title,
          mobile: phone,
          email: email,
          image: imgWallpaperFile,
          role: "teacher",
        };
      } else {
        formData = {
          id: userData.id,
          name: fullName,
          mobile: phone,
          email: email,
          image: imgWallpaperFile,
          role: "admin",
        };
      }

      dispatch(updateUserApi(formData)).then((result) => {
        if (result.payload.message === " User updated Successfully") {
          swal("Good job!", result.payload.message, "success");
          setFullName("");
          setEmail("");
          setTitle("");
          setPhone("");
          setImgWallpaper("");
          setImgWallpaperFile("");
          handleCloseUpdateForm();
          getSingleUserDataFromApi();
        }
      });
    }
  };

  const handleCloseUpdateForm = () => {
    setFullName("");
    setTitle("");
    setEmail("");
    setPhone("");
    setShowUpdateDataForm(false);
  };

  const handleShowUpdateForm = () => {
    if (userData.role == "teacher") {
      setTitle(userData.title);
    }
    setFullName(userData.name);
    setEmail(userData.email);
    setPhone(userData.mobile);
    setShowUpdateDataForm(true);
  };

  // Add Lesson Model

  // File Refrances Lesson Video
  const fileUploaderLessonVideo = useRef(null);
  // Data States Lesson Infromation
  const [instractorName, setInstractorName] = useState("");
  const [instractorTitle, setInstractorTitle] = useState("");
  const [bioDescription, setBioDescription] = useState("");

  const [uploadImgName, setUploadImgName] = useState();
  const [uploadImgFile, setUploadImgFile] = useState();
  const inputFileRef = useRef();

  const openFileInput = () => {
    inputFileRef.current.open();
  };

  const handleLessonFormUlpoader = (e) => {
    e.preventDefault();
  };
  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };
  const getSingleUserDataFromApi = () => {
    dispatch(getsingleUserAPi(user_id)).then((res) => {
      if (res.payload.message === "fetched successfully") {
        setUserData(res.payload.data);
      }
    });
  };
  useEffect(() => {
    getSingleUserDataFromApi();
  }, []);
  useEffect(() => {
    if (userData == null) {
      getSingleUserDataFromApi();
    }
    return;
  }, [userData]);

  const userRole = JSON.parse(localStorage.getItem("userData")).data.role;
console.log(userRole);

  return (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="App" />

      {loading ? (
        <div className="row d-flex justify-content-center gap-2">
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="warning" />
        </div>
      ) : (
        <>
          {error ? (
            <h1>{error}</h1>
          ) : (
            <>
              {userData ? (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                          {/* <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div> */}
                          <div className="profile-info">
                            <div
                              className="profile-photo"
                              style={{ marginTop: 0 }}
                            >
                              {userData.image ? (
                                <img
                                  src={`${process.env.REACT_APP_VERSEL_API}/${userData.image}`}
                                  className="img-fluid rounded-circle"
                                  alt={userData.name}
                                />
                              ) : (
                                <img
                                  src={avatarMain}
                                  className="img-fluid rounded-circle"
                                  alt={userData.name}
                                />
                              )}
                            </div>
                            <div className="profile-details">
                              <div className="profile-name px-3 pt-2">
                                <h4 className="text-primary mb-0">
                                  {userData.name}
                                </h4>
                                <p>{userData.role}</p>
                              </div>
                              <div className="profile-email px-2 pt-2">
                                <h4 className="text-muted mb-0">
                                  {userData.email}
                                </h4>
                                <p>Email</p>
                              </div>
                              {userRole === "admin"&&(<>
                                <Dropdown className="dropdown ms-auto">
                                <Dropdown.Toggle
                                  variant="primary"
                                  className="btn btn-primary light sharp i-false"
                                  data-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    //    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={handleShowUpdateForm}
                                  >
                                    <i className="fa fa-user-circle text-primary me-2" />
                                    Edite Profile
                                  </Dropdown.Item>

                                  {/* <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-users text-primary me-2" />
                        Add to close friends
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-plus text-primary me-2" />
                        Add to group
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-ban text-primary me-2" />
                        Block
                      </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                              </>)}
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="profile-tab">
                            <div className="custom-tab-1">
                              <div className="tab-content">
                                <div
                                  id="about-me"
                                  className={`tab-pane fade ${
                                    activeToggle === "aboutMe"
                                      ? "active show"
                                      : ""
                                  }`}
                                >
                                  <div className="profile-personal-info">
                                    <h4 className="text-primary mb-4">
                                      Personal Information
                                    </h4>
                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          Name
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.name}</span>
                                      </div>
                                    </div>
                                    {userData.title && (
                                      <>
                                        <div className="row mb-2">
                                          <div className="col-3">
                                            <h5 className="f-w-500">
                                              Title
                                              <span className="pull-right">
                                                :
                                              </span>
                                            </h5>
                                          </div>
                                          <div className="col-9">
                                            <span> {userData.title}</span>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          Email
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.email}</span>
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          Phone
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.mobile}</span>
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          {" "}
                                          role
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.role}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-12">
                      <h1 className="card-title">Courses</h1>
                      {userData.courses.map((item, index) => {
                        return (
                          <>
                            <CustomCouseCard
                              title={item.name.en}
                              subtitle={userData.name}
                              price={item.price}
                              _id={item._id}
                              image={item.image}
                            />
                          </>
                        );
                      })}
                    </div> */}
                  </div>
                  <Modal
                    show={showUpdateDataForm}
                    onHide={handleCloseUpdateForm}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Body>
                      <form className="form-valide" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-username"
                              >
                                Full Name
                                <span className="text-danger">*</span>
                              </label>
                              <div>
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  id="val-username"
                                  name="val-username"
                                  placeholder="Enter a full name.."
                                  onChange={(e) => {
                                    if (
                                      e.target.value.length < 3 ||
                                      e.target.value.length > 50
                                    ) {
                                      setFullNameErrorMessage(
                                        "Min 3 Characters Max 50 Characters"
                                      );
                                      setFullName(e.target.value);
                                    } else {
                                      setFullNameErrorMessage("");
                                      setFullName(e.target.value);
                                    }
                                  }}
                                  value={fullName}
                                />
                                {fullNameErrorMessage && (
                                  <Alert
                                    variant="warning"
                                    className="alert-dismissible fade mt-1 mb-0"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="me-2"
                                    >
                                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </svg>
                                    <strong>Warrning </strong>
                                    {fullNameErrorMessage}
                                  </Alert>
                                )}
                              </div>
                            </div>
                            {userData.role == "teacher" && (
                              <>
                                <div className="form-group mb-3 row">
                                  <label
                                    className="col-lg-4 col-form-label"
                                    htmlFor="val-title"
                                  >
                                    Teacher Title
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <input
                                      required
                                      type="text"
                                      className="form-control"
                                      id="val-title"
                                      name="val-title"
                                      placeholder="Enter a full name.."
                                      onChange={(e) => {
                                        if (
                                          e.target.value.length < 3 ||
                                          e.target.value.length > 50
                                        ) {
                                          setTitleErrorMessage(
                                            "Min 3 Characters Max 50 Characters"
                                          );
                                          setTitle(e.target.value);
                                        } else {
                                          setTitleErrorMessage("");
                                          setTitle(e.target.value);
                                        }
                                      }}
                                      value={title}
                                    />
                                    {titleErrorMessage && (
                                      <Alert
                                        variant="warning"
                                        className="alert-dismissible fade mt-1 mb-0"
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          fill="none"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="me-2"
                                        >
                                          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                          <line
                                            x1="15"
                                            y1="9"
                                            x2="9"
                                            y2="15"
                                          ></line>
                                          <line
                                            x1="9"
                                            y1="9"
                                            x2="15"
                                            y2="15"
                                          ></line>
                                        </svg>
                                        <strong>Warrning </strong>
                                        {titleErrorMessage}
                                      </Alert>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-email"
                              >
                                Email <span className="text-danger">*</span>
                              </label>
                              <div>
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  id="val-email"
                                  name="val-email"
                                  placeholder="Your valid email.."
                                  onChange={(e) => {
                                    if (e.target.value.length < 10) {
                                      setEmailErrorMessage(
                                        "invaild email min 10 characters"
                                      );
                                      setEmail(e.target.value);
                                    } else {
                                      setEmailErrorMessage("");
                                      setEmail(e.target.value);
                                    }
                                  }}
                                  value={email}
                                />
                                {emailErrorMessage && (
                                  <Alert
                                    variant="warning"
                                    className="alert-dismissible fade mt-1 mb-0"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="me-2"
                                    >
                                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </svg>
                                    <strong>Warrning </strong>
                                    {emailErrorMessage}
                                  </Alert>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-email"
                              >
                                Upload Image{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div>
                                <div className="custom-upload-file form-group mb-3">
                                  <label
                                    htmlFor="img-wallpaper"
                                    className="form-label"
                                    onClick={() => openFileUploaderImgWallpaper}
                                  >
                                    <i className="fa-solid fa-upload p-2"></i>
                                    {imgWallpaper
                                      ? imgWallpaper
                                      : "Upload Image Wallpaper  700 X 450"}
                                  </label>
                                  <input
                                    ref={fileUploaderImgWallpaper}
                                    hidden
                                    className="form-control form-control-md"
                                    type="file"
                                    id="img-wallpaper"
                                    onChange={(e) => {
                                      setImgWallpaperFile(e.target.files[0]);
                                      setImgWallpaper("");
                                      setImgWallpaper(e.target.files[0].name);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-phoneus"
                              >
                                Phone (US)
                                <span className="text-danger">*</span>
                              </label>
                              <div>
                                <input
                                  maxLength={11}
                                  type="text"
                                  className="form-control"
                                  id="val-phoneus"
                                  name="val-phoneus"
                                  placeholder="212-999-0000"
                                  onChange={(e) => {
                                    if (e.target.value[0] !== "0") {
                                      setPhoneErrorMessage(
                                        "Should Start With 0"
                                      );
                                    } else if (e.target.value[1] !== "1") {
                                      setPhoneErrorMessage(
                                        "Should Start With 01"
                                      );
                                    } else if (e.target.value.length < 11) {
                                      setPhoneErrorMessage(
                                        "Not Valid Egyption Number 01xxxxxxxxx"
                                      );
                                    } else {
                                      setPhoneErrorMessage("");
                                    }
                                    setPhone(e.target.value);
                                  }}
                                  value={phone}
                                />
                                {phoneErrorMessage && (
                                  <Alert
                                    variant="warning"
                                    className="alert-dismissible fade mt-1 mb-0"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="me-2"
                                    >
                                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </svg>
                                    <strong>Warrning </strong>
                                    {phoneErrorMessage}
                                  </Alert>
                                )}
                              </div>
                            </div>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleCloseUpdateForm}
                              >
                                Close
                              </Button>
                              {updateLoading ? (
                                <>
                                  <Button variant="primary" disabled>
                                    Loding....
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button variant="primary" type="submit">
                                    Update User
                                  </Button>
                                </>
                              )}
                            </Modal.Footer>
                          </div>
                        </div>
                      </form>
                    </Modal.Body>
                  </Modal>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <h1>No data</h1>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </Fragment>
  );
};

export default AppProfile;
