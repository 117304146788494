import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getAllAdmins = createAsyncThunk("user/getAdmins", async () => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

  return await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/users/admin`
  ,{
    method:"GET",
    headers:{
        "Authorization": `Bearer ${tokenData}`
    }
  })
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => error);
});

const getAllAdminsSlicer = createSlice({
  name: "getAllAdmins",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAdmins.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllAdmins.fulfilled, (state, action) => {
      state.loading = false;

      if(action.payload.message === "fetched successfully"){
        state.data = action.payload.data;
    }else{
        state.error = action.payload.message;
    }
    });
    builder.addCase(getAllAdmins.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});



export default getAllAdminsSlicer.reducer;