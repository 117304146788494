import React, { useEffect, useRef, useState } from "react";

import { Tab, Nav, Accordion, Spinner } from "react-bootstrap";
import "react-modal-video/css/modal-video.min.css";

import { Formik } from "formik";
import * as Yup from "yup";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import diplomaAvatar from "./../../../images/avatar/diplome-avatar.jpg";
import { getAll } from "../../../store/GetUsersSlicer";
import { getDiplomaByIdForTeacherApi } from "../../../store/Slices/teacher/getByIdTeacherDiplomaSlicer";

const updateDiolpme = Yup.object().shape({
  enname: Yup.string()
    .min(6, " name must consist of at least 6 characters ")
    .max(200, " name must consist of at most 200 characters ")
    .required("Please enter a  name"),
  arname: Yup.string()
    .min(6, " name must consist of at least 6 characters ")
    .max(200, " name must consist of at most 200 characters ")
    .required("Please enter a  name"),

  entitle: Yup.string()
    .min(10, "title  must consist of at least 10 characters ")
    .max(500, "title must consist of at most 500 characters ")
    .required("Please enter a course title"),
  artitle: Yup.string()
    .min(10, "title  must consist of at least 10 characters ")
    .max(500, "title must consist of at most 500 characters ")
    .required("Please enter a course title"),

  endescription: Yup.string()
    .min(10, "description  must consist of at least 10 characters ")
    .max(3000, "description must consist of at most 3000 characters ")
    .required("Please enter a course description"),
  ardescription: Yup.string()
    .min(10, "description  must consist of at least 10 characters ")
    .max(3000, "description must consist of at most 3000 characters ")
    .required("Please enter a course description"),

  price: Yup.number().required("Please enter a price"),
  access_duration: Yup.number().required("Please enter access duration"),
  teacherId: Yup.array().required("Please select  teacher"),
});

const AccordionItemCourse = ({ data }) => {
  return (
    <>
      <div className="acc-courses my-3">
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-start">
            <span className="acc-icon">
              <img
                style={{
                  height: "100%",
                }}
                src={`${process.env.REACT_APP_VERSEL_API}/${data.image}`}
              />
            </span>
            <h4 className="m-0">{data.name && data.name.en}</h4>
          </div>
        </div>
        {data.file && (
          <>
            <div>
              <h5>
                <a
                  className="p-1 text-danger"
                  href={`${process.env.REACT_APP_VERSEL_API}/${data.file.path}`}
                  target="_blank"
                >
                  file name :{data.file.name}
                </a>
              </h5>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default function TeacherDiplomeDetail() {
  /// Update Lesson Area

  const { _id } = useParams();

  const dispatch = useDispatch();
  const [courseStudnet, setCourseStudnet] = useState([]);
  const [studentData, setStudentData] = useState([]);

  //#region Diplome Data
  const {
    loading: getDiplomeByIdLoading,
    data: getDiplomeByIdData,
    error: getDiplomeByIdError,
  } = useSelector((state) => state.getByIdTeacherDiplomaSlicer);

  const { data: getAllCourseToDiplomeData } = useSelector(
    (state) => state.getAllCoursesToDiplomeSlicer
  );

  const [diplomeData, setDiplomeData] = useState();

  const getDiplome = async () => {
    dispatch(getDiplomaByIdForTeacherApi(_id)).then((res) => {
      console.log(res.payload);
    });
    console.log("Data ", getDiplomeByIdData);
  };

  useEffect(() => {
    getDiplomeByIdData && setDiplomeData(getDiplomeByIdData.data.diploma);
    getDiplomeByIdData && setCourseStudnet(getDiplomeByIdData.data.students);
    getDiplomeByIdData &&
      setStudentData(getDiplomeByIdData.data.studentDataAndPayment);
  }, [getDiplomeByIdData]);

  //#endregion

  useEffect(() => {
    getDiplome();
  }, []);

  return (
    <>
      {getDiplomeByIdLoading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {getDiplomeByIdError ? (
            <>{getDiplomeByIdError.message}</>
          ) : (
            <>
              {diplomeData && (
                <>
                  {diplomeData ? (
                    <>
                      <div className="row">
                        <div className="col-xl-6 col-xxl-6">
                          <div className="row">
                            <div className="card">
                              <div className="card-body">
                                <div className="course-content ">
                                  <div className="mb-2"></div>

                                  <div>
                                    <h3>
                                      {diplomeData.name && diplomeData.name.en}
                                    </h3>
                                    <p>
                                      Instructors :<br />
                                      {diplomeData.teacher_id != null &&
                                        diplomeData.teacher_id.length > 0 && (
                                          <>
                                            {diplomeData.teacher_id.map(
                                              (teacher, index) => (
                                                <span key={index}>
                                                  {teacher.name} |{" "}
                                                </span>
                                              )
                                            )}
                                          </>
                                        )}
                                    </p>
                                    <p>
                                      Price :{" "}
                                      {diplomeData.price &&
                                        diplomeData.price / 100}
                                      $
                                    </p>
                                    <p>
                                      Access Duration :{" "}
                                      {diplomeData.access_duration &&
                                        diplomeData.access_duration}{" "}
                                      Month
                                    </p>
                                  </div>
                                </div>
                                <div className="video-img style-1 my-3">
                                  <div className="view-demo">
                                    {diplomeData.image !== "" ? (
                                      <>
                                        <img
                                          src={`${process.env.REACT_APP_VERSEL_API}/${diplomeData.image}`}
                                          className="d-block w-100"
                                          alt={`diplome image`}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={diplomaAvatar}
                                          className="d-block w-100"
                                          alt={`diplome image`}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                                <Tab.Container defaultActiveKey="About">
                                  <div className="course-details-tab style-2 mt-4">
                                    <nav>
                                      <Nav
                                        as="div"
                                        className="nav nav-tabs tab-auto"
                                        id="nav-tab"
                                      >
                                        <Nav.Link
                                          as="button"
                                          className="nav-link"
                                          id="nav-about-tab"
                                          eventKey="About"
                                          type="button"
                                        >
                                          English Data
                                        </Nav.Link>
                                        <Nav.Link
                                          as="button"
                                          className="nav-link"
                                          id="nav-aboutar-tab"
                                          eventKey="Aboutar"
                                          type="button"
                                        >
                                          Arabic Data
                                        </Nav.Link>
                                      </Nav>
                                    </nav>
                                    <Tab.Content
                                      className="tab-content"
                                      id="nav-tabContent"
                                      style={{
                                        textAlign: "justify",
                                      }}
                                    >
                                      <Tab.Pane id="nav-about" eventKey="About">
                                        <div className="about-content">
                                          <h4>Diplome Name</h4>
                                          <p>
                                            {diplomeData.name &&
                                              diplomeData.name.en}
                                          </p>
                                          <h4>title</h4>
                                          <p>
                                            {diplomeData.title &&
                                              diplomeData.title.en}
                                          </p>
                                          <h4>About This Diplome</h4>
                                          <p>
                                            {diplomeData.description &&
                                              diplomeData.description.en}
                                          </p>
                                        </div>
                                      </Tab.Pane>
                                      <Tab.Pane
                                        id="nav-about"
                                        eventKey="Aboutar"
                                        dir="rtl"
                                      >
                                        <div className="about-content">
                                          <h4>اسم الدبلومة</h4>
                                          <p>
                                            {diplomeData.name &&
                                              diplomeData.name.ar}
                                          </p>
                                          <h4>عنوان الدبلومة</h4>
                                          <p>
                                            {diplomeData.title &&
                                              diplomeData.title.ar}
                                          </p>
                                          <h4>وصف الدبلومة</h4>
                                          <p>
                                            {diplomeData.description &&
                                              diplomeData.description.ar}
                                          </p>
                                        </div>
                                      </Tab.Pane>
                                    </Tab.Content>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-xxl-6">
                          {diplomeData.video && diplomeData.video !== "" && (
                            <div className="card h-auto">
                              <div className="card-header border-0 pb-0">
                                <h4>Demo Video</h4>
                              </div>

                              <div className="card-body pt-0 d-flex gap-1 row">
                                <div className="col-sm-12">
                                  {diplomeData.video &&
                                    diplomeData.video !== "" && (
                                      <>
                                        <a
                                          className="btn btn-info mt-2 mx-3"
                                          href={diplomeData.video}
                                          target="_blank"
                                        >
                                          watch
                                        </a>
                                      </>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="custome-accordion">
                            <Accordion
                              className="accordion"
                              defaultActiveKey={[`0`]}
                              alwaysOpen
                            >
                              <Accordion.Item className="card" eventKey={"0"}>
                                <Accordion.Header
                                  as="h2"
                                  className="accordion-header border-0"
                                >
                                  <span className="acc-heading">
                                    Courses Diplome
                                  </span>
                                </Accordion.Header>
                                {diplomeData.courses_id.length == 0 ? (
                                  <h3 className="text-center">
                                    No Courses Added yet
                                  </h3>
                                ) : (
                                  <>
                                    <Accordion.Collapse eventKey={"0"}>
                                      <div className="accordion-body card-body pt-0">
                                        {diplomeData.courses_id.map(
                                          (item, index) => (
                                            <AccordionItemCourse
                                              key={index}
                                              data={item}
                                            />
                                          )
                                        )}
                                      </div>
                                    </Accordion.Collapse>
                                  </>
                                )}
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="card students-list">
                          <div className="card-header border-0 flex-wrap pb-0">
                            <h4>Students List ({studentData.length})</h4>
                          </div>
                          <div className="card-body py-0">
                            <div className="table-responsive">
                              <div
                                id="student_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table
                                  className="table display mb-4 dataTablesCard order-table card-table text-black application "
                                  id="application-tbl1_next"
                                >
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Name</th>
                                      <th>Email</th>
                                      <th>mobile</th>
                                      <th>Payment</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {studentData &&
                                      studentData.map(
                                        (item, index) =>
                                          item &&
                                          item.user_id != null && (
                                            <>
                                              <tr key={index}>
                                                <td>##</td>
                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    <h4 className="mb-0 fs-16 font-w500">
                                                      {item.user_id.name}
                                                    </h4>
                                                  </div>
                                                </td>
                                                <td>{item.user_id.email}</td>
                                                <td>{item.user_id.mobile}</td>
                                                <td className="text-start">
                                                  {item.amount_cents / 100} EG
                                                </td>
                                              </tr>
                                            </>
                                          )
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row text-center">
                        <h1>No Data</h1>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
