import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const deleteUserSubscription = createAsyncThunk(
  "user/subscrption/delete",
  async (subscriptionData) => {


    console.log(subscriptionData);

    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/deleteUserSubscription/${subscriptionData.user_id}/${subscriptionData.courseId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.log(error);
        formatError(error);
        return error;
      });
    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const deleteCourseSubscriptionSlicer = createSlice({
  name: "deleteCubsicription",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteUserSubscription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUserSubscription.fulfilled, (state, action) => {
      state.loading = true;
      if (action.payload.message === "Deleted successfully") {
        state.response = action.payload.message;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(deleteUserSubscription.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      formatError(state.error);
    });
  },
});

export default deleteCourseSubscriptionSlicer.reducer;
