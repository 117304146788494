import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const getDiplomeByIdApi = createAsyncThunk(
  "diplome/getbyid",
  async (_id) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/diplome/get-by-id/${_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log(error);
        formatError(error);
      });

    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const getDiplomeByIdSlicer = createSlice({
  name: "get diplome by id",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getDiplomeByIdApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDiplomeByIdApi.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getDiplomeByIdApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default getDiplomeByIdSlicer.reducer;
