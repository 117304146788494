import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const deleteUserAccessApi = createAsyncThunk(
  "user/userAccess/delete",
  async (userAccessData) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/update-student-access`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studentId: userAccessData.studentId,
          userIpAdress: userAccessData.userIpAdress,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.log(error);
        formatError(error);
        return error;
      });
    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const deleteStudentAccessSlicer = createSlice({
  name: "deleteUserAccrss",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteUserAccessApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUserAccessApi.fulfilled, (state, action) => {
      state.loading = true;
      if (action.payload.message === "Data updated successfully") {
        state.response = action.payload.message;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(deleteUserAccessApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      formatError(state.error);
    });
  },
});

export default deleteStudentAccessSlicer.reducer;
