import { createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../services/LogoutFunction";

const isLogedIn = createSlice({
    name : "isLogedIn",
    initialState :{
        isLogedInState : false
    },
    reducers :{
        LogedIn(state){
            state.isLogedInState = true;
        },
        LogedOut(state){
            state.isLogedInState = false;
            
                LogoutFunction();
              
        }
    }
});


export const {LogedIn,LogedOut}  = isLogedIn.actions;
export default isLogedIn.reducer;