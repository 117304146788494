import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../../../services/LogoutFunction";

export const addJobFunc = createAsyncThunk(
  "user/addJobFunc",
  async (jobData) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/carrers/create`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokenData}`,
          // "Content-Type": "application/json",
        },
        body: jobData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        formatError(error);
        return error;
      });

    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}
const addJobSlicer = createSlice({
  name: "addJobSlicer",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(addJobFunc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addJobFunc.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      } else if (action.payload.message === "Postion created successfully") {
        state.data = action.payload.data;
      } else {
        state.error = action.payload.message;
        formatError(state.error);
      }
    });
    builder.addCase(addJobFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      formatError(state.error);
    });
  },
});

export default addJobSlicer.reducer;
