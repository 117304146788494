import React, { Fragment, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./style.css";
import nextId from "react-id-generator";
import PageTitle from "../../../layouts/PageTitle";
import Accordion from "react-bootstrap/Accordion";
import { Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import Swal from "sweetalert2";

function DropAction({ itemId, action }) {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          as="a"
          className="btn-link i-false btn sharp tp-btn-light btn-dark"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
              fill="#A098AE"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-end"
          align="right"
        >
          <Dropdown.Item onClick={() => action("delete", itemId)}>
            Delete
          </Dropdown.Item>
          {/* <Dropdown.Item onClick={() => action("edit", itemId)}>
            Edit
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

function LessonCard({ id, title, minutes, action }) {
  return (
    <>
      <div className="card d-flex flex-row justify-content-between align-items-center p-2">
        <h5 className="px-2">{title}</h5>
        <h5 className="d-flex flex-row justify-content-between gap-3 align-items-center">
          {minutes} min
          <DropAction className="pr-1" itemId={id} action={action} />
        </h5>
      </div>
    </>
  );
}

const id = nextId();

const Wizard = () => {
  // File Refrances Basic Images
  const fileUploaderImgWallpaper = useRef(null);
  const fileUploaderImgCourse = useRef(null);

  // File Refrances Lessons Data
  const lessonFileUpload = useRef(null);

  // Data States Basic Infromation
  const [courseTitle, setCourseTitle] = useState("");
  const [coursePrice, setCoursePrice] = useState(0);
  const [courseLanguage, setCourseLanguage] = useState("");
  const [imgWallpaper, setImgWallpaper] = useState("");
  const [imgWallpaperFile, setImgWallpaperFile] = useState("");
  const [imgCourse, setImgCourse] = useState("");
  const [imgCourseFile, setImgCourseFile] = useState("");
  // Data States Descriptions Infromation
  const [subDescription, setSubDescription] = useState();
  const [superDescription, setSuperDescription] = useState(
    ` <h3>About Course (heading 3)</h3>
   <p>It is a long-established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution (Paragraph)</p>
   <p>of letters, instead of using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web pages. (Paragraph)</p>
   <p>&nbsp;</p>
   <h3>what you will learn from this course (heading 3)</h3>
   <ul>
   <li>Introduction and something&nbsp;</li>
   <li>How to do something</li>
   <li>etc...</li>
   </ul>`
  );

  // Data States Lessons
  const [lessonVideoFile, setLessonVideoFile] = useState("");
  const [counterID, setCounterID] = useState(1);
  const [sectionCard, setSectionCard] = useState([]);

  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionDataCreation, setSectionDataCreation] = useState([]);
  const [lesson, setLesson] = useState({
    id: id + "###" + counterID,
    type: "lesson",
    title: "",
    uploadFile: null,
    minutes: 0,
  });

  // File Open Functions
  const openFileUploaderImgWallpaper = () => {
    fileUploaderImgWallpaper.current.click();
  };
  const openFileUploaderImgCourse = () => {
    fileUploaderImgCourse.current.click();
  };
  const openlessonFileUpload = () => {
    lessonFileUpload.current.click();
  };

  // Handle Editor Function
  const handleEditorChange = (content, editor) => {
    setSuperDescription(content);
  };

  //Handle Lesson/Section Data
  const handleLesson = (e) => {
    e.preventDefault();
    setCounterID((preVal) => preVal + 1);
    setSectionDataCreation((preVal) => {
      return [...preVal, lesson];
    });
    swal("Add Lesson", `Added new Lesson : ${lesson.title} `, "");
    setLesson({
      id: id + "###" + counterID,
      type: "lesson",
      title: "",
      uploadFile: null,
      minutes: 0,
    });
    setLessonVideoFile("");
  };
  const handleActionLesson = (action, id) => {
    console.log("lesson Action", action, id);
    if (action === "delete") {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this lesson data",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setSectionDataCreation((preVal) =>
            preVal.filter((item) => item.id !== id)
          );
          Swal.fire(
            "Deleted!",
            "lesson has been delered successfuly",
            "success"
          );
        }
      });
    }
  };
  const handleActionSection = (action, id) => {
    if (action === "delete") {
      setSectionCard((preVal) =>
        preVal.filter((item) => item.sectionId !== id)
      );
    }
  };
  const createSectionFunction = () => {
    setSectionCard((preVal) => [
      ...preVal,
      {
        sectionId: "##000000P01" + counterID,
        sectionTitle: sectionTitle,
        lessonsDate: sectionDataCreation,
      },
    ]);
    swal(
      "Add Section ",
      `Added New Section : ${sectionCard.sectionTitle} `,
      ""
    );
    setSectionTitle("");
    setSectionDataCreation([]);
    setLesson({
      id: id + "###" + counterID,
      type: "lesson",
      title: "",
      uploadFile: null,
      minutes: 0,
    });
  };


  // Get All Data Function
  const createCourseFunction= ()=>{
	const formData = new FormData();
	formData.append('title',courseTitle)
	formData.append('price',coursePrice)
	formData.append('language',courseLanguage)
	formData.append('titshort_descriptionle',subDescription)
	formData.append('long_description',superDescription)
	formData.append('sections',sectionCard)
	
	formData.forEach((key,val)=>{
		console.log(key,val);
	})
	console.log("-----------------------");
	const object ={
		title : courseTitle,
		price :coursePrice,
		language : courseLanguage,
		short_description :subDescription,
		long_description:superDescription,
		sections:sectionCard
	}
	console.log(object);
	// formData.append('object',object)

  }

  return (
    <Fragment>
      <PageTitle activeMenu="Add Course" motherMenu="Courses" />

      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-body">
              <div className="form-wizard ">
                <div>
                  <h1>Basic Infromation</h1>
                  <section>
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <div className="form-group mb-3">
                          <label className="text-label">Course Name*</label>
                          <input
                            type="text"
                            name="courseName"
                            className="form-control"
                            placeholder="Introduction to something ....."
                            required
                            onChange={(e) => {
                              setCourseTitle(e.target.value);
                            }}
                            value={courseTitle}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="row">
                          <div className="col-sm-6">
                            <label className="text-label">
                              Course Price* (Egyption pound)
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Couse Price"
                              onChange={(e) => {
                                setCoursePrice(e.target.value);
                              }}
                              value={coursePrice}
                            />
                          </div>
                          <div className="col-sm-6 mt-2 mt-sm-0">
                            <label className="text-label">
                              Course Language*
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setCourseLanguage(e.target.value);
                              }}
                              value={courseLanguage}
                            >
                              <option defaultValue={null}>
                                Select Language
                              </option>
                              <option value={"arabic"}>Arabic</option>
                              <option value={"english"}>English</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="custom-upload-file form-group mb-3">
                          <label
                            htmlFor="img-wallpaper"
                            className="form-label"
                            onClick={() => openFileUploaderImgWallpaper}
                          >
                            <i className="fa-solid fa-upload p-2"></i>
                            {imgWallpaper
                              ? imgWallpaper
                              : "Upload Image Wallpaper 1920 X 700"}
                          </label>
                          <input
                            ref={fileUploaderImgWallpaper}
                            hidden
                            className="form-control form-control-md"
                            type="file"
                            id="img-wallpaper"
                            onChange={(e) => {
                              setImgWallpaperFile(e.target.files[0]);
                              setImgWallpaper("");
                              setImgWallpaper(e.target.files[0].name);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="custom-upload-file form-group mb-3">
                          <label
                            htmlFor="img-course"
                            className="form-label"
                            onClick={() => openFileUploaderImgCourse}
                          >
                            <i className="fa-solid fa-upload p-2"></i>
                            {imgCourse
                              ? imgCourse
                              : "Upload Image Course 700 X 450"}
                          </label>
                          <input
                            ref={fileUploaderImgCourse}
                            hidden
                            className="form-control form-control-md"
                            type="file"
                            id="img-course"
                            onChange={(e) => {
                              setImgCourseFile(e.target.files[0]);
                              setImgCourse("");
                              setImgCourse(e.target.files[0].name);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div>
                  <h1>Descriptions </h1>
                  <section>
                    <div className="row ">
                      <div className="col-lg-12 mb-2">
                        <div className="form-group mb-3">
                          <label className="text-label">
                            Small Descriptions For Course*
                          </label>
                          <textarea
                            id="subdescription-textarea"
                            rows={3}
                            resize={"none"}
                            className="form-control"
                            placeholder="Sub Description"
                            required
                            onChange={(e) => {
                              setSubDescription(e.target.value);
                            }}
                            value={subDescription}
                          ></textarea>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-12 col-xxl-12">
                          <h4>
                            write Details Description For The Course
                            *(Commitment to description){" "}
                          </h4>
                          <div className="summernote">
                            <Editor
                              initialValue={superDescription}
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image code charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount ",
                                ],
                                toolbar:
                                  "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
                                  "bullist numlist outdent indent | removeformat | help ",
                                content_style: "body { color: #828282 }",
                              }}
                              onEditorChange={handleEditorChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mb-2"></div>
                    </div>
                  </section>
                </div>

                <div>
                  <h1>Lessons </h1>
                  <section className="create-course-lessons">
                    <div className="row">
                      <div className="card  bg-light">
                        <div className="row mb-3 mt-3" id="section-description">
                          <div>
                            <h4>Section Description</h4>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Week ONE: The true value of infinity"
                                onChange={(e) => {
                                  setSectionTitle(e.target.value);
                                }}
                                value={sectionTitle}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row section-lessons-quizes m-3">
                          {sectionDataCreation.map((item) => {
                            return (
                              <LessonCard
                                key={item.id}
                                id={item.id}
                                title={item.title}
                                minutes={item.minutes}
                                action={handleActionLesson}
                              />
                            );
                          })}
                        </div>
                        <div className="col card p-3">
                          <div className="card-title">
                            <h2>Add Lesson/Quizes Forms</h2>
                          </div>
                          <form
                            className="row add-lesson"
                            onSubmit={handleLesson}
                          >
                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label
                                  htmlFor="lesson-description"
                                  className="form-label"
                                >
                                  Lesson Description
                                </label>
                                <input
                                  id="lesson-description"
                                  className="form-control"
                                  type="text"
                                  onChange={(e) => {
                                    setLesson((preVal) => {
                                      return {
                                        ...preVal,
                                        title: e.target.value,
                                      };
                                    });
                                  }}
                                  value={lesson.title}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="custom-upload-file form-group">
                                <label
                                  htmlFor="lesson-video"
                                  className="form-label"
                                  onClick={() => openlessonFileUpload}
                                >
                                  <i className="fa-solid fa-upload p-2"></i>
                                  {lessonVideoFile
                                    ? lessonVideoFile
                                    : "Upload Lesson Video"}
                                </label>
                                <input
                                  ref={lessonFileUpload}
                                  hidden
                                  className="form-control form-control-md"
                                  type="file"
                                  id="lesson-video"
                                  onChange={(e) => {
                                    setLesson((preVal) => {
                                      return {
                                        ...preVal,
                                        uploadFile: e.target.files[0],
                                      };
                                    });
                                    setLessonVideoFile("");
                                    setLessonVideoFile(e.target.files[0].name);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 my-2">
                              <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                  <div className="form-group ">
                                    <label
                                      htmlFor="lesson-duration"
                                      className="form-label"
                                    >
                                      Enter Video Duration (* minutes)
                                    </label>
                                    <input
                                      id="lesson-duration"
                                      className="form-control"
                                      type="number"
                                      maxLength={2}
                                      onChange={(e) => {
                                        setLesson((preVal) => {
                                          return {
                                            ...preVal,
                                            minutes: e.target.value,
                                          };
                                        });
                                      }}
                                      value={lesson.minutes}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 align-self-end">
                                  <button
                                    className="btn btn-success "
                                    type="submit"
                                  >
                                    Add Lesson
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          
                        </div>
                        <div>
                          <button
                            className="btn btn-warning mb-2"
                            onClick={createSectionFunction}
                          >
                            Create Section
                          </button>
                        </div>
                      </div>
                    </div>

                    {sectionCard.length == 0 ? (
                      ""
                    ) : (
                      <>
                        <div className="section-display">
                          <div className="card-title">
                            <h3 className="p-3">Section Preview</h3>
                          </div>
                          <div className="card-body">
                            <Accordion>
                              {sectionCard.map((sectrion, index) => {
                                return (
                                  <>
                                    <Accordion.Item
                                      key={sectrion.sectionId}
                                      eventKey={`${index}`}
                                    >
                                      <DropAction
                                        className="pr-1"
                                        itemId={sectrion.sectionId}
                                        action={handleActionSection}
                                      />
                                      <Accordion.Header className="bg-light">
                                        <h4>{sectrion.sectionTitle}</h4>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        {sectrion.lessonsDate.map((lesson) => {
                                          return (
                                            <>
                                              <div
                                                key={lesson.id}
                                                className="card d-flex flex-row justify-content-between align-items-center p-2"
                                              >
                                                <h5 className="px-2">
                                                  {lesson.title}
                                                </h5>
                                                <h5 className="d-flex flex-row justify-content-between gap-3 align-items-center">
                                                  {lesson.minutes} min
                                                </h5>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </>
                                );
                              })}
                            </Accordion>
                          </div>
                        </div>
                      </>
                    )}
                  </section>
                </div>

                {/* <div >
                  <h1>Go Live </h1>
                  <StepFour />
                </div> */}

                <div className="text-end toolbar toolbar-bottom p-2">
                  <button className="btn btn-primary sw-btn-next ms-1" onClick={()=>createCourseFunction()}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Wizard;

/**
 * 
 * 
 * 		<Fragment>
			<PageTitle activeMenu="Add Course" motherMenu="Courses" />

			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Form step</h4>
						</div>
						<div className="card-body">
						
							<div className="form-wizard ">
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
									<Step className="nav-link" onClick={() => setGoSteps(3)} />
								</Stepper>
							  {goSteps === 0 && (
								<>
								<h1>Basic Infromation</h1>
									<StepOne />	
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-primary sw-btn-next" onClick={() => setGoSteps(1)}>Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 1 && (
								<>
								<h1>Descriptions </h1>

									<StepTwo />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1" onClick={() => setGoSteps(2)}>Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 2 && (
								<>
								<h1>Lessons </h1>

									<StepThree />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(3)}>Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 3 && (
								<>
								<h1>Go Live  </h1>
									<StepFour />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(2)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(4)}>Submit</button>
									</div>	
								</>	
							  )}
							  
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
*/
