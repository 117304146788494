import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const addQuizeToCourse = createAsyncThunk("quize/addToCourse", async (data) => {
  try {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/quize/addQuiezToCourse/${data.quizeId}`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData}`
        },
        body: JSON.stringify({
          courseId:data.courseId
        })
      }
    ).then((res) => res.json());
  } catch (error) {
    swal("Error", error, "info");
    return error;
  }
});

const addQuizeToCourseSlicer = createSlice({
  name: "Add Quize To Course",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(addQuizeToCourse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addQuizeToCourse.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(addQuizeToCourse.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload.message === "Quize Add To Course Successfully") {
        state.response = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default addQuizeToCourseSlicer.reducer;
