import { useEffect } from "react";
import AddDiplomeForm from "./AddDiplomeForm";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function AddDiplomes() {
    const {inst_id} = useParams()
    useEffect(()=>{
        window.scroll(0,0)
    })
    return <>

    <AddDiplomeForm  />

    </>
};
