import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../../../services/LogoutFunction";
import axios from "axios";

export const newCurrencyFunc = createAsyncThunk(
  "user/newCurrencyFunc",
  async (data) => {
    console.log(data)
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    // const request = await fetch(
    //   `${process.env.REACT_APP_VERSEL_API}/api/user/currencies`,
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${tokenData}`,
    //       // "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(data),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     return result;
    //   })
    //   .catch((error) => {
    //     formatError(error);
    //     return error;
    //   });
    const config  = {
      headers: {
        Authorization: `Bearer ${tokenData}`,
        // "Content-Type": "application/json",
      },
  }
  const request =await axios.post(`${process.env.REACT_APP_VERSEL_API}/api/user/currencies` , data , config);
  console.log(request)
  return request?.data;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const newCurrencySlicer = createSlice({
  name: "newCurrencySlicer",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(newCurrencyFunc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(newCurrencyFunc.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      } else if (action.payload.message === " Currency created Successfully") {
        state.data = action.payload.data;
      } else {
        state.error = action.payload.message;
        formatError(state.error);
      }
    });
    builder.addCase(newCurrencyFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      formatError(state.error);
    });
  },
});

export default newCurrencySlicer.reducer;
