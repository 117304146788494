import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Spinner from "react-bootstrap/Spinner";

//images
import palette from "./../../../images/svg/color-palette.svg";
import education from "./../../../images/svg/education-website.svg";
import brain from "./../../../images/svg/brain.svg";
import microscope from "./../../../images/svg/microscope.svg";
import course1 from "./../../../images/courses/course1.jpg";
import course2 from "./../../../images/courses/course2.jpg";
import course3 from "./../../../images/courses/course3.jpg";
import course4 from "./../../../images/courses/course4.jpg";
import course5 from "./../../../images/courses/course5.jpg";
import course6 from "./../../../images/courses/course6.jpg";
import { useDispatch, useSelector } from "react-redux";
import { FetchCourses } from "../../../store/GetAllCoursesSlicer";
import Swal from "sweetalert2";
import { deleteCourseApi } from "../../../store/DeleteCourseSlicer";

function CoursesMain() {
  const [user, setUser] = useState({});
  const [coursesData, setCoursesData] = useState([]);
  const [backupData, setBackupData] = useState([]);

  const dispatch = useDispatch();
  const { loading, data, error } = useSelector((state) => state.getAllCourses);

  const getAllCourses = (pageNumber) => {
    dispatch(FetchCourses(pageNumber)).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        setCoursesData(resulet.payload.data.courses);
        setBackupData(resulet.payload.data.courses);
      } else {
        setCoursesData([]);
      }
    });
  };

  const getUserDate = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData;
  };

  const handleDeleteCourse = (id) => {
    const couresData = {
      courseId: id,
      token: user.token,
    };
    console.log("form courese", couresData);
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this course",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCourseApi(couresData)).then((res) => {
          if (res.payload.date != []) {
            Swal.fire("Deleted!", "Lesson has been deleted", "success");
            getAllCourses(1);
          }
        });
      }
    });
  };
  const coursesFilters = (e) => {
    if (e.target.value === "") {
      setCoursesData(backupData);
      return;
    }

    const filterArray = backupData.filter((item) => {
      console.log(item);
      return (
        item.name.en.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.teacher_id.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });
    console.log(filterArray);
    setCoursesData(filterArray);
  };

  useEffect(() => {
    if (user) {
      setUser(getUserDate());
    }
    getAllCourses(1);
  }, []);

  useEffect(() => {
    if (!coursesData) {
      getAllCourses(1);
    }
    return;
  }, [coursesData]);

  return (
    <>
      {/* <div className="widget-heading d-flex justify-content-between align-items-center">
				<h3 className="m-0">Popular This Week</h3>
				<Link to={"./courses"} className="btn btn-primary btn-sm">View all</Link>
			</div>
			<div className="row">
				<Swiper className="swiper course-slider"	
					speed= {1500}					
					slidesPerView= {4}
					spaceBetween= {20}
					loop={false}
					breakpoints = {{
						1600: {
							slidesPerView: 4,
						},
						
						1200: {
							slidesPerView: 3,
						},
						575: {
							slidesPerView: 2,
						},
						360: {
							slidesPerView: 1,
						},
					}}
				>	
					{widgetData.map((d,i)=>(
						<SwiperSlide key={i}>	
							<div className="card">
								<div className="card-body">
									<div className="widget-courses align-items-center d-flex justify-content-between flex-wrap">
										<div className="d-flex align-items-center flex-wrap">
											<img src={d.image} alt="" />
											<div className="flex-1 ms-3">
												<h4>{d.title}</h4>
												<span>Lorem ipsum dolor</span>
											</div>	
										</div>	
										<Link to={"#"}><i className="las la-angle-right text-primary"></i></Link>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}				
				</Swiper>			
			</div> */}

      {loading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          <div className="col-xl-12">
            <div className="card students-list">
              <div className="card-header border-0 flex-wrap ">
                <h4>All Courses ({backupData.length})</h4>
                <div className="input-group search-area w-auto">
                  <span className="input-group-text">
                    <Link to={"#"}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                          fill="var(--primary)"
                        ></path>
                      </svg>
                    </Link>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    onChange={coursesFilters}
                  />
                </div>
              </div>
            </div>
          </div>
          {coursesData.length == 0 ? (
            <>
              <div className="row d-flex text-center gap-2">
                <h3>Data Not Found</h3>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                {coursesData.map((item) => (
                  <CustomCourseCard
                    key={item._id}
                    title={item.name.en}
                    price={item.price}
                    image={item.image}
                    _id={item._id}
                    handleDeleteCourse={handleDeleteCourse}
                    subtitle={item.teacher_id != null && item.teacher_id}
                    freeStatus = {item.is_free}
                    discountPrice = {item.discount_price}
                    finalPrice = {item.final_price / 100}
                    discountType = {item.discount_type}
                  />
                ))}

                <>
                  {/* <div className="col-xl-4 col-md-6" key={index}>
            <div className="card all-crs-wid">
              <div className="card-body">
                <div className="courses-bx">
                  <div className="dlab-media">
                    <img src={data.image} alt="" />
                  </div>
                  <div className="dlab-info">
                    <div className="dlab-title d-flex justify-content-between">
                      <div>
                        <h4>
                          <Link to={"./course-details-1"}>{data.title}</Link>
                        </h4>
                        <p className="m-0">
                          {data.subtitle}
                          <svg
                            className="ms-1"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="2" cy="2.5" r="2" fill="#DBDBDB" />
                          </svg>
                          <span>
                            5.0
                            <svg
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z"
                                fill="#FEC64F"
                              />
                            </svg>
                          </span>
                        </p>
                      </div>
                      <h4 className="text-primary">
                        <span>$</span>50.99
                      </h4>
                    </div>
                    <div className="d-flex justify-content-between content align-items-center">
                      <span>
                        <svg
                          className="me-2"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.2 18.6C20.5 18.5 19.8 18.4 19 18.4C16.5 18.4 14.1 19.1 12 20.5C9.90004 19.2 7.50005 18.4 5.00005 18.4C4.30005 18.4 3.50005 18.5 2.80005 18.6C2.30005 18.7 1.90005 19.2 2.00005 19.8C2.10005 20.4 2.60005 20.7 3.20005 20.6C3.80005 20.5 4.40005 20.4 5.00005 20.4C7.30005 20.4 9.50004 21.1 11.4 22.5C11.7 22.8 12.2 22.8 12.6 22.5C15 20.8 18 20.1 20.8 20.6C21.3 20.7 21.9 20.3 22 19.8C22.1 19.2 21.7 18.7 21.2 18.6ZM21.2 2.59999C20.5 2.49999 19.8 2.39999 19 2.39999C16.5 2.39999 14.1 3.09999 12 4.49999C9.90004 3.09999 7.50005 2.39999 5.00005 2.39999C4.30005 2.39999 3.50005 2.49999 2.80005 2.59999C2.40005 2.59999 2.00005 3.09999 2.00005 3.49999V15.5C2.00005 16.1 2.40005 16.5 3.00005 16.5C3.10005 16.5 3.10005 16.5 3.20005 16.5C3.80005 16.4 4.40005 16.3 5.00005 16.3C7.30005 16.3 9.50004 17 11.4 18.4C11.7 18.7 12.2 18.7 12.6 18.4C15 16.7 18 16 20.8 16.5C21.3 16.6 21.9 16.2 22 15.7C22 15.6 22 15.6 22 15.5V3.49999C22 3.09999 21.6 2.59999 21.2 2.59999Z"
                            fill="#c7c7c7"
                          />
                        </svg>
                        110+ Content
                      </span>
                      <Link to={"./courses"} className="btn btn-primary btn-sm">
                        View all
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
                  {/* {cardInfoBlog.map((data, index)=>(
					<div className="col-xl-4 col-md-6" key={index}>
						<div className="card all-crs-wid">
							<div className="card-body">
								<div className="courses-bx">
									<div className="dlab-media">
										<img src={data.image} alt="" />
									</div>
									<div className="dlab-info">
										<div className="dlab-title d-flex justify-content-between">
											<div>
												<h4><Link to={"./course-details-1"}>{data.title}</Link></h4>
												<p className="m-0">{data.subtitle}
													<svg className="ms-1" width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="2" cy="2.5" r="2" fill="#DBDBDB"/>
													</svg>
													<span>5.0<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z" fill="#FEC64F"/>
													</svg>
													</span>
												</p>
											</div>	
											<h4 className="text-primary"><span>$</span>50.99</h4>
										</div>
										<div className="d-flex justify-content-between content align-items-center">
											<span>
												<svg className="me-2" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M21.2 18.6C20.5 18.5 19.8 18.4 19 18.4C16.5 18.4 14.1 19.1 12 20.5C9.90004 19.2 7.50005 18.4 5.00005 18.4C4.30005 18.4 3.50005 18.5 2.80005 18.6C2.30005 18.7 1.90005 19.2 2.00005 19.8C2.10005 20.4 2.60005 20.7 3.20005 20.6C3.80005 20.5 4.40005 20.4 5.00005 20.4C7.30005 20.4 9.50004 21.1 11.4 22.5C11.7 22.8 12.2 22.8 12.6 22.5C15 20.8 18 20.1 20.8 20.6C21.3 20.7 21.9 20.3 22 19.8C22.1 19.2 21.7 18.7 21.2 18.6ZM21.2 2.59999C20.5 2.49999 19.8 2.39999 19 2.39999C16.5 2.39999 14.1 3.09999 12 4.49999C9.90004 3.09999 7.50005 2.39999 5.00005 2.39999C4.30005 2.39999 3.50005 2.49999 2.80005 2.59999C2.40005 2.59999 2.00005 3.09999 2.00005 3.49999V15.5C2.00005 16.1 2.40005 16.5 3.00005 16.5C3.10005 16.5 3.10005 16.5 3.20005 16.5C3.80005 16.4 4.40005 16.3 5.00005 16.3C7.30005 16.3 9.50004 17 11.4 18.4C11.7 18.7 12.2 18.7 12.6 18.4C15 16.7 18 16 20.8 16.5C21.3 16.6 21.9 16.2 22 15.7C22 15.6 22 15.6 22 15.5V3.49999C22 3.09999 21.6 2.59999 21.2 2.59999Z" fill="#c7c7c7"/>
												</svg>
												110+ Content
											</span>
											<Link to={"./courses"} className="btn btn-primary btn-sm">View all</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))} */}
                </>
              </div>
              {/* <div className="pagination-down">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <h4 className="sm-mb-0 mb-3">
                    Showing <span>1-6 </span>from <span>100 </span>data
                  </h4>
                  <ul>
                    <li>
                      <Link to={"#"}>
                        <i className="fas fa-chevron-left"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={"#"} className="active">
                        1
                      </Link>
                    </li>
                    <li>
                      <Link to={"#"}>2</Link>
                    </li>
                    <li>
                      <Link to={"#"}>3</Link>
                    </li>
                    <li>
                      <Link to={"#"}>
                        <i className="fas fa-chevron-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </>
          )}
        </>
      )}
    </>
  );
}
export default CoursesMain;

function CustomCourseCard({
  title,
  subtitle,
  price,
  _id,
  handleDeleteCourse,
  image,
  freeStatus,
  discountPrice,
  finalPrice,
  discountType
}) {
  console.log(subtitle.name);

  console.log(`${process.env.REACT_APP_VERSEL_API}/${image}`);
  return (
    <>
      <div className="col-xl-4 col-md-6">
        <div className="card all-crs-wid">
          <div className="card-body">
            <div className="courses-bx">
              <div className="dlab-media">
                <img
                  src={`${process.env.REACT_APP_VERSEL_API}/${image}`}
                  alt=""
                />
                {
                  parseInt(discountPrice) > 0
                  ?
                  <div className="discount-icon">
                    {
                      discountType === "fixed"
                      ?
                      `OFF ${discountPrice/100} EG`
                      :
                      `OFF ${discountPrice} %`

                    }
                  </div>
                  :
                  ""
                }
              </div>
              <div className="dlab-info">
                <div className="dlab-title d-flex justify-content-between">
                  <div>
                    <h4>
                      <Link to={`./course-details-2/${_id}`}>{title}</Link>
                    </h4>
                    {
                      parseInt(discountPrice) > 0
                      ?
                      <h4 className="text-primary w-100 position-relative">
                      {
                        freeStatus
                        ?
                        "Free Course"
                        :
                        <>
                          {/* <span>$</span> */}
                          {
                            discountPrice && parseInt(discountPrice) > 0
                            ?
                            <div className="position-relative flex items-center justify-content-around w-100">
                              <del className="mr-1 text-danger">${price / 100}</del>
                              <span className=" d-inline-block final-price">${finalPrice}</span>
                            </div>
                            :
                            <>${price / 100}</>
                          }
                        </>
                      }
                      </h4>
                      :
                      ""
                    }
                    <p className="m-0">
                      {subtitle && subtitle.name}
                      <svg
                        className="ms-1"
                        width="4"
                        height="5"
                        viewBox="0 0 4 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2" cy="2.5" r="2" fill="#DBDBDB" />
                      </svg>
                      {/* <span>
                        5.0
                        <svg
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z"
                            fill="#FEC64F"
                          />
                        </svg>
                      </span> */}
                    </p>
                  </div>
                  {
                    parseInt(discountPrice) === 0
                    ?
                    <h4 className="text-primary">
                      {
                        freeStatus
                        ?
                        "Free Course"
                        :
                        <>
                          <span>$</span>
                          {
                            price / 100
                          }
                        </>
                      }
                    </h4>
                    :
                    ""
                  }
                </div>
                <div className="d-flex justify-content-between content align-items-center">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDeleteCourse(_id)}
                  >
                    Delete
                  </button>
                  <Link
                    to={`./course-details-2/${_id}`}
                    className="btn btn-primary btn-sm"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/**
 * 	<div className="widget-heading d-flex justify-content-between align-items-center">
				<h3 className="m-0">Popular This Week</h3>
				<Link to={"./courses"} className="btn btn-primary btn-sm">View all</Link>
			</div>
			<div className="row">
				<Swiper className="swiper course-slider"	
					speed= {1500}					
					slidesPerView= {4}
					spaceBetween= {20}
					loop={false}
					breakpoints = {{
						1600: {
							slidesPerView: 4,
						},
						
						1200: {
							slidesPerView: 3,
						},
						575: {
							slidesPerView: 2,
						},
						360: {
							slidesPerView: 1,
						},
					}}
				>	
					{widgetData.map((d,i)=>(
						<SwiperSlide key={i}>	
							<div className="card">
								<div className="card-body">
									<div className="widget-courses align-items-center d-flex justify-content-between flex-wrap">
										<div className="d-flex align-items-center flex-wrap">
											<img src={d.image} alt="" />
											<div className="flex-1 ms-3">
												<h4>{d.title}</h4>
												<span>Lorem ipsum dolor</span>
											</div>	
										</div>	
										<Link to={"#"}><i className="las la-angle-right text-primary"></i></Link>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}				
				</Swiper>			
			</div>
			<div className="widget-heading d-flex justify-content-between align-items-center">
				<h3 className="m-0">All Courses</h3>
				<Link to={"./courses"} className="btn btn-primary btn-sm">View all</Link>
			</div>
			<div className="row">
				{cardInfoBlog.map((data, index)=>(
					<div className="col-xl-4 col-md-6" key={index}>
						<div className="card all-crs-wid">
							<div className="card-body">
								<div className="courses-bx">
									<div className="dlab-media">
										<img src={data.image} alt="" />
									</div>
									<div className="dlab-info">
										<div className="dlab-title d-flex justify-content-between">
											<div>
												<h4><Link to={"./course-details-1"}>{data.title}</Link></h4>
												<p className="m-0">{data.subtitle}
													<svg className="ms-1" width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="2" cy="2.5" r="2" fill="#DBDBDB"/>
													</svg>
													<span>5.0<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z" fill="#FEC64F"/>
													</svg>
													</span>
												</p>
											</div>	
											<h4 className="text-primary"><span>$</span>50.99</h4>
										</div>
										<div className="d-flex justify-content-between content align-items-center">
											<span>
												<svg className="me-2" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M21.2 18.6C20.5 18.5 19.8 18.4 19 18.4C16.5 18.4 14.1 19.1 12 20.5C9.90004 19.2 7.50005 18.4 5.00005 18.4C4.30005 18.4 3.50005 18.5 2.80005 18.6C2.30005 18.7 1.90005 19.2 2.00005 19.8C2.10005 20.4 2.60005 20.7 3.20005 20.6C3.80005 20.5 4.40005 20.4 5.00005 20.4C7.30005 20.4 9.50004 21.1 11.4 22.5C11.7 22.8 12.2 22.8 12.6 22.5C15 20.8 18 20.1 20.8 20.6C21.3 20.7 21.9 20.3 22 19.8C22.1 19.2 21.7 18.7 21.2 18.6ZM21.2 2.59999C20.5 2.49999 19.8 2.39999 19 2.39999C16.5 2.39999 14.1 3.09999 12 4.49999C9.90004 3.09999 7.50005 2.39999 5.00005 2.39999C4.30005 2.39999 3.50005 2.49999 2.80005 2.59999C2.40005 2.59999 2.00005 3.09999 2.00005 3.49999V15.5C2.00005 16.1 2.40005 16.5 3.00005 16.5C3.10005 16.5 3.10005 16.5 3.20005 16.5C3.80005 16.4 4.40005 16.3 5.00005 16.3C7.30005 16.3 9.50004 17 11.4 18.4C11.7 18.7 12.2 18.7 12.6 18.4C15 16.7 18 16 20.8 16.5C21.3 16.6 21.9 16.2 22 15.7C22 15.6 22 15.6 22 15.5V3.49999C22 3.09999 21.6 2.59999 21.2 2.59999Z" fill="#c7c7c7"/>
												</svg>
												110+ Content
											</span>
											<Link to={"./courses"} className="btn btn-primary btn-sm">View all</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
				
			</div>	
			<div className="pagination-down">
				<div className="d-flex align-items-center justify-content-between flex-wrap">
					<h4 className="sm-mb-0 mb-3">Showing <span>1-6 </span>from <span>100 </span>data</h4>
					<ul>
						<li><Link to={"#"}><i className="fas fa-chevron-left"></i></Link></li>
						<li><Link to={"#"} className="active">1</Link></li>
						<li><Link to={"#"}>2</Link></li>
						<li><Link to={"#"}>3</Link></li>
						<li><Link to={"#"}><i className="fas fa-chevron-right"></i></Link></li>
					</ul>
				</div>
			</div>
 */
