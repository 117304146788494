import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../services/LogoutFunction";

export const deleteFormJoinApi = createAsyncThunk(
  "form/join-delete",
  async (messageId) => {
    
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/contact_join/delete_join_us/${messageId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        
        return result;
      })
      .catch((error) => {
      
        return error;
      });
    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const DeleteFromJointSlicer = createSlice({
  name: "form/join-delete",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteFormJoinApi.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(deleteFormJoinApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      }
     else  if (action.payload.data != null) {
        state.data = action.payload.data;
        state.error = null;
      }  else {
        
        state.data = null;
        state.error = "Something went wronge";
        formatError(state.error);
      }
    });
    builder.addCase(deleteFormJoinApi.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error =  "Error Invaled Data";
      formatError(state.error);
    });
  },
});

export default DeleteFromJointSlicer.reducer;
