import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../services/LogoutFunction";

export const getLessonByID = createAsyncThunk(
  "lesson/getbyid",
   async (_id) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

  const request = await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/users/lessons/getLessonById/${_id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenData}`,
      },
    }
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
        console.log(error);
      formatError(error);
    });

  return request;
});

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const GetSingleLessonDataSlicer = createSlice({
  name: "getLesson",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonByID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLessonByID.fulfilled, (state, action) => {
      state.loading = false;
     if (action.payload.data) {
        state.data = action.payload.data;
      } else {
        
        state.error = action.payload.message;
        formatError(state.error);
      }
    });
    builder.addCase(getLessonByID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      formatError(state.error);
    });
  },
});

export default GetSingleLessonDataSlicer.reducer;

