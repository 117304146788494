import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const getAllQuizesApi = createAsyncThunk("quize/getAll", async () => {
  try {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/quize`,{
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    ).then((res) => res.json());
  } catch (error) {
    swal("Error", error, "info");
    return error;
  }
});

const getAllQuizesSlicer = createSlice({
  name: "get All Quizes",
  initialState: {
    loading: false,
    quizes: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllQuizesApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllQuizesApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getAllQuizesApi.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload.message === "Quizes fetched Successfully") {
        state.quizes = action.payload.quizes;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default getAllQuizesSlicer.reducer;
