import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../services/LogoutFunction";
import swal from "sweetalert";

export const statisticsApi = createAsyncThunk("statistics/get", async () => {
  const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
  return await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/users/statistics/getAllStatistics`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenData}`,
      },
    }
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
});

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}


const StatisticsSlicer = createSlice({
  name: "statistics/get",
  initialState: {
    loading: false,
    statistics: null,
  },
  extraReducers: (builder) => {
    builder.addCase(statisticsApi.pending, (state) => {
      state.loading = true;
      state.statistics = null;
    });
    
    builder.addCase(statisticsApi.fulfilled, (state,action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      }
      else if (action.payload.message === "fetched successfully") {
        state.statistics = action.payload;
      }else{
        state.statistics = "Error";
      }
    });
    
  },
});

export default StatisticsSlicer.reducer;
