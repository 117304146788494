import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { addCourseApi } from "../../../store/AddCourseSlicer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Spinner } from "react-bootstrap";
import { getAll } from "../../../store/GetUsersSlicer";
import { addDiplomeApi } from "../../../store/Slices/diplomes/addDiplomeSlicer";
import { toast } from "react-toastify";

const createDiplomSchema = Yup.object().shape({
  enname: Yup.string()
    .min(6, "name must consist of at least 6 characters ")
    .max(200, "name must consist of at most 200 characters ")
    .required("Please enter a name"),
  arname: Yup.string()
    .min(6, "name must consist of at least 6 characters ")
    .max(200, "name must consist of at most 200 characters ")
    .required("Please enter a name"),

  entitle: Yup.string()
    .min(6, "title must consist of at least 6 characters ")
    .max(500, "title must consist of at most 500 characters ")
    .required("Please enter a title"),
  artitle: Yup.string()
    .min(6, "title must consist of at least 6 characters ")
    .max(500, "title must consist of at most 500 characters ")
    .required("Please enter a title"),

  endescription: Yup.string()
    .min(10, "description  must consist of at least 10 characters ")
    .max(3000, "description must consist of at most 3000 characters ")
    .required("Please enter a  description"),
  ardescription: Yup.string()
    .min(10, "description  must consist of at least 10 characters ")
    .max(3000, "description must consist of at most 3000 characters ")
    .required("Please enter a  description"),
  price: Yup.number().required("Please enter a price"),
  teacherId: Yup.array().required("Please select  teacher"),
});

const AddCourseForm = () => {
  const { loading, error } = useSelector((state) => state.addCourse);

  const dispatch = useDispatch();
  const [userdata, setUserData] = useState();
  const [teachers, setTeachers] = useState();
  const [discountType, setDiscountType] = useState("fixed");
  const [discountPrice, setDiscountPrice] = useState(0);
  const history = useHistory();
  // File Refrances Basic Images
  const [courseFormIntilization, setCourseFormInitialization] = useState({
    enname: "",
    arname: "",

    entitle: "",
    artitle: "",

    endescription: "",
    ardescription: "",

    price: 1,
    teacherId: "",
  });

  const getALlTeachers = async () => {
    dispatch(getAll("teacher")).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        setTeachers(resulet.payload.data);
      }
    });
  };

  // Get All Data Function
  const createDiplomeFunction = (value, { resetForm }) => {
    console.log(value);

    const objectData = {
      name: {
        en: value.enname,
        ar: value.arname,
      },
      title: {
        en: value.entitle,
        ar: value.artitle,
      },
      description: {
        en: value.endescription,
        ar: value.ardescription,
      },
      price: value.price * 100,
      discount_type : discountType,
      discount_price : discountPrice < 0 ? 0 : discountPrice,
      teacher_id: value.teacherId,
    };

    const sendingFunc = () => {
      dispatch(addDiplomeApi(objectData)).then((resulte) => {
        if (resulte.payload.message === "Data fetched successfully") {
          Swal.fire({
            title: resulte.payload.message,
            text: "Go to diplome details to added courses",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, stay and add new diplome",
            confirmButtonText: "Yes, Go to add course",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`/diplome-detail/${resulte.payload.data._id}`);
            } else {
              resetForm();
              
            }
          });
        } else {
          swal("error", resulte.payload.message, "warning");
        }
      });
    }
    
    if(discountType === "percentage" && parseInt(objectData.discount_price) > 100){
      toast.error("Discount Must Not be More Than 100% !");
    }else {
      if(parseInt(objectData.discount_price)*100 > parseInt(objectData.price)){
        toast.error("Discount Must Not be More Than Diploma Price !");
      }else{
        sendingFunc();
      }
    }

  };

  useEffect(() => {
    window.scroll(0, 0);
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setUserData(data);
    } else {
      history.push("/login");
    }
    getALlTeachers();
  }, []);

  useEffect(() => {
    if (!teachers) {
      getALlTeachers();
    }
    return;
  }, [teachers]);

  return (
    <Fragment>
      <PageTitle activeMenu="Add Diplome" motherMenu="Diplomes" />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  enname: courseFormIntilization.enname,
                  arname: courseFormIntilization.arname,

                  entitle: courseFormIntilization.entitle,
                  artitle: courseFormIntilization.artitle,

                  endescription: courseFormIntilization.endescription,
                  ardescription: courseFormIntilization.ardescription,

                  price: courseFormIntilization.price,
                  teacherId: courseFormIntilization.teacherId,
                }}
                validationSchema={createDiplomSchema}
                onSubmit={createDiplomeFunction}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div>
                      <h1>Basic Infromation</h1>
                      <section>
                        {/* name */}
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.enname
                                ? errors.enname
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a english name.."
                                name="enname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.enname}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.enname && errors.enname}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.arname
                                ? errors.arname
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a arabic name.."
                                name="arname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.arname}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.arname && errors.arname}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* title */}
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.entitle
                                ? errors.entitle
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English title
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a english title.."
                                name="entitle"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.entitle}
                              />
                              <div
                                id="val-entitle-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.entitle && errors.entitle}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.artitle
                                ? errors.artitle
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic title
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a arabic title.."
                                name="artitle"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.artitle}
                              />
                              <div
                                id="val-artitle-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.artitle && errors.artitle}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* price */}
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.price
                                ? errors.price
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter New Price* (Egyption pound)
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter course price .."
                                name="price"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}
                              />
                              <div
                                id="val-price-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.price && errors.price}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 
                        
                            `}
                          >
                            <label className="text-label">
                              Choose discount type*
                            </label>
                            <div className="input-group">
                              <select
                                className="form-control"
                                name="discount-type"
                                onChange={(e) => setDiscountType(e.target.value)}
                                // onBlur={handleBlur}
                                defaultValue={discountType}
                              >
                                <option value={"Choose Discount Type.."}>Choose Discount Type..</option>
                                <option value={"fixed"}>Fixed</option>
                                <option value={"percentage"}>Percentage</option>
                              </select>
                              {/* <div
                                id="val-price-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discountType && errors.discountType}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 
                  
                            `}
                          >
                            <label className="text-label">
                              Enter Discount (optional)*
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Diploma discount .."
                                name="discount"
                                onChange={(e) => setDiscountPrice(e.target.value)}
                                onBlur={handleBlur}
                                value={discountPrice}
                                // min={10}
                              />
                            </div>
                          </div>
                        </div>
                        {/* teacher Data */}
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.teacherId
                                ? errors.teacherId
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">Select Teacher</label>
                            <div className="input-group">
                              <select
                                className="form-control"
                                name="teacherId"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.teacherId}
                                multiple
                              >
                                <option defaultValue={null}>
                                  Please select Teacher
                                </option>
                                {teachers &&
                                  teachers.map((item, index) => (
                                    <option key={index} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                              <div
                                id="val-teacherId-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.teacherId && errors.teacherId}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div>
                      <h1>Descriptions </h1>
                      <section>
                        <div className="row ">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.endescription
                                ? errors.endescription
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Description
                            </label>
                            <div className="input-group">
                              <textarea
                                id="subdescription-textarea"
                                rows={5}
                                className="form-control"
                                placeholder="Sub Description"
                                name="endescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endescription}
                              ></textarea>
                              <div
                                id="val-endescription-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endescription && errors.endescription}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.ardescription
                                ? errors.ardescription
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Description
                            </label>
                            <div className="input-group">
                              <textarea
                                id="subdescription-textarea"
                                rows={5}
                                className="form-control"
                                placeholder="Sub Description"
                                name="ardescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ardescription}
                              ></textarea>
                              <div
                                id="val-ardescription-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.ardescription && errors.ardescription}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="row">
                      <div className="col">
                        {loading ? (
                          <>
                            <Button variant="primary" disabled>
                              <Spinner animation="grow" variant="info" />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button variant="primary" type="submit">
                              Create
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddCourseForm;

// jshdj

// // // // // <form className="form-wizard" onSubmit={createCourseFunction}>
// // // // <div>
// // // //   <h1>Basic Infromation</h1>
// // // //   <section>
// // // //     <div className="row">
// // // //       <div className="col-lg-6 mb-2">
// // // //         <div className="form-group mb-3">
// // // //           <label className="text-label">Course Name*</label>
// // // //           <input
// // // //             type="text"
// // // //             name="courseName"
// // // //             className="form-control"
// // // //             placeholder="Introduction to something ....."
// // // //             required
// // // //             onChange={(e) => {
// // // //               setCourseTitle(e.target.value);
// // // //             }}
// // // //             value={courseTitle}
// // // //           />
// // // //         </div>
// // // //       </div>
// // // //       <div className="col-lg-6 mb-2">
// // // //         <div className="row">
// // // //           <div className="col-sm-12">
// // // //             <label className="text-label">
// // // //               Course Price* (Egyption pound)
// // // //             </label>
// // // //             <input
// // // //               required
// // // //               type="number"
// // // //               className="form-control"
// // // //               placeholder="Couse Price"
// // // //               onChange={(e) => {
// // // //                 setCoursePrice(e.target.value);
// // // //               }}
// // // //               value={coursePrice}
// // // //             />
// // // //           </div>
// // // //           {/* <div className="col-sm-6 mt-2 mt-sm-0">
// // // //             <label className="text-label">
// // // //               Course Language*
// // // //             </label>
// // // //             <select
// // // //               required
// // // //               className="form-control"
// // // //               onChange={(e) => {
// // // //                 setCourseLanguage(e.target.value);
// // // //               }}
// // // //               value={courseLanguage}
// // // //             >
// // // //               <option defaultValue={null}>
// // // //                 Select Language
// // // //               </option>
// // // //               <option value={"arabic"}>Arabic</option>
// // // //               <option value={"english"}>English</option>
// // // //             </select>
// // // //           </div> */}
// // // //         </div>
// // // //       </div>
// // // //       {/* <div className="col-lg-6 mb-2">
// // // //         <div className="custom-upload-file form-group mb-3">
// // // //           <label
// // // //             htmlFor="img-wallpaper"
// // // //             className="form-label"
// // // //             onClick={() => openFileUploaderImgWallpaper}
// // // //           >
// // // //             <i className="fa-solid fa-upload p-2"></i>
// // // //             {imgWallpaper
// // // //               ? imgWallpaper
// // // //               : "Upload Image Wallpaper 1920 X 700"}
// // // //           </label>
// // // //           <input
// // // //             required
// // // //             ref={fileUploaderImgWallpaper}
// // // //             hidden
// // // //             className="form-control form-control-md"
// // // //             type="file"
// // // //             id="img-wallpaper"
// // // //             onChange={(e) => {
// // // //               setImgWallpaperFile(e.target.files[0]);
// // // //               setImgWallpaper("");
// // // //               setImgWallpaper(e.target.files[0].name);
// // // //             }}
// // // //           />
// // // //         </div>
// // // //       </div> */}
// // // //       <div className="col-lg-12 mb-2">
// // // //         <div className="custom-upload-file form-group mb-3">
// // // //           <label
// // // //             htmlFor="img-course"
// // // //             className="form-label"
// // // //             onClick={() => openFileUploaderImgCourse}
// // // //           >
// // // //             <i className="fa-solid fa-upload p-2"></i>
// // // //             {imgCourse
// // // //               ? imgCourse
// // // //               : "Upload Image Course 700 X 450"}
// // // //           </label>
// // // //           <input

// // // //             ref={fileUploaderImgCourse}
// // // //             hidden
// // // //             className="form-control form-control-md"
// // // //             type="file"
// // // //             id="img-course"
// // // //             onChange={(e) => {
// // // //               setImgCourseFile(e.target.files[0]);
// // // //               setImgCourse("");
// // // //               setImgCourse(e.target.files[0].name);
// // // //             }}
// // // //           />
// // // //         </div>
// // // //       </div>
// // // //     </div>
// // // //   </section>
// // // // </div>
// // // // <div>
// // // //   <h1>Descriptions </h1>
// // // //   <section>
// // // //     <div className="row ">
// // // //       <div className="col-lg-12 mb-2">
// // // //         <div className="form-group mb-3">
// // // //           <label className="text-label">
// // // //             Small Descriptions For Course*
// // // //           </label>
// // // //           <textarea
// // // //             required
// // // //             id="subdescription-textarea"
// // // //             rows={5}
// // // //             className="form-control"
// // // //             placeholder="Sub Description"
// // // //             onChange={(e) => {
// // // //               setSubDescription(e.target.value);
// // // //             }}
// // // //             value={subDescription}
// // // //           ></textarea>
// // // //         </div>
// // // //       </div>

// // // //       {/* <div className="row">
// // // //         <div className="col-xl-12 col-xxl-12">
// // // //           <h4>
// // // //             write Details Description For The Course
// // // //             *(Commitment to description){" "}
// // // //           </h4>
// // // //           <div className="summernote">
// // // //             <Editor
// // // //               initialValue={superDescription}
// // // //               init={{
// // // //                 height: 500,
// // // //                 menubar: false,
// // // //                 plugins: [
// // // //                   "advlist autolink lists link image code charmap print preview anchor",
// // // //                   "searchreplace visualblocks code fullscreen",
// // // //                   "insertdatetime media table paste code help wordcount ",
// // // //                 ],
// // // //                 toolbar:
// // // //                   "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
// // // //                   "bullist numlist outdent indent | removeformat | help ",
// // // //                 content_style: "body { color: #828282 }",
// // // //               }}
// // // //               onEditorChange={handleEditorChange}
// // // //             />
// // // //           </div>
// // // //         </div>
// // // //       </div> */}
// // // //       {/* <div className="col-lg-12 mb-2">
// // // //       <div className="col-sm-4 mt-2 mt-sm-0">
// // // //             <label className="text-label">
// // // //              Number Of Sections *
// // // //             </label>
// // // //             <select
// // // //               required
// // // //               className="form-control"
// // // //               onChange={(e) => {
// // // //                 setNumberOfSection(e.target.value);
// // // //               }}
// // // //               value={numberOfSection}
// // // //             >
// // // //               <option defaultValue={null}>
// // // //                 Select Section Number
// // // //               </option>

// // // //               <option value={1}>1</option>
// // // //               <option value={2}>2</option>
// // // //               <option value={3}>3</option>
// // // //               <option value={4}>4</option>
// // // //               <option value={5}>5</option>
// // // //               <option value={6}>6</option>
// // // //               <option value={7}>7</option>
// // // //               <option value={8}>8</option>
// // // //               <option value={9}>9</option>
// // // //               <option value={10}>10</option>
// // // //             </select>
// // // //           </div>
// // // //       </div> */}
// // // //     </div>
// // // //   </section>
// // // // </div>
// // // // <div className="text-end toolbar toolbar-bottom p-2">
// // // // {loading ? <>
// // // //   <button
// // // //     className="btn btn-primary sw-btn-next ms-1"
// // // //     type="submit"
// // // //     disabled
// // // //   >
// // // //     Loading.....
// // // //   </button>
// // // // </> :
// // // // <>
// // // // <button
// // // //     className="btn btn-primary sw-btn-next ms-1"
// // // //     type="submit"
// // // //   >
// // // //     Submit
// // // //   </button>
// // // // </>}
// // // // </div>
// // // // </form>
