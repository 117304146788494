import React, { Fragment, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import swal from "sweetalert";
import { Alert } from "react-bootstrap";
//** Import Image */
import avatarMain from "./../../../images/avatar/avatar-main.png";
import PageTitle from "../../layouts/PageTitle";
import CustomCouseCard from "../Courses/CustomCouseCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getsingleUserAPi } from "../../../store/GetSingleUSerSlicer";
import { updateUserApi } from "../../../store/UpdateUserDataSlicer";
import { getStudentCourse } from "../../../store/Slices/users/getStudentCoursesSlicer";
import avatar from "../../../images/avatar/avatar-main.png";
import { deleteUserSubscription } from "../../../store/Slices/users/deleteCourseSubscriptionSlicer";
import Swal from "sweetalert2";
import { FetchCourses } from "../../../store/GetAllCoursesSlicer";
import { addSubscription } from "../../../store/Slices/users/addSubscriptionSlicer";
import { deleteCourseSubscriptionApi } from "../../../store/Slices/subscriptions/courses/deleteCourseSubscriptionSclicer";
import { getAllCourseAndDiplomasApi } from "../../../store/Slices/subscriptions/getAllCourseAndDiplomasSlicer";
import { createDiplomaSubscriptionApi } from "../../../store/Slices/subscriptions/diplomas/createSubscriptionDiplomasSlicer";
import { deleteDiplomaSubscriptionApi } from "../../../store/Slices/subscriptions/diplomas/deleteDiplomaSubscriptionSclicer";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateUserPasswordApi } from "../../../store/Slices/users/updateUserPasswordSlicer";
import { deleteUserAccessApi } from "../../../store/Slices/users/deleteStudentAccessSlicer";
import SendCertificateComponents from "../SendCertificates/components/SendCertificateComponents";

const updatePassword = Yup.object().shape({
  password: Yup.string()
    .min(8, "Your password must be at least 8 characters")
    .max(200, "Your password must be at most 200 characters")
    .required("Please provide a password"),
});

const StudentProfile = () => {
  const { user_id } = useParams();
  const { loading, data, error } = useSelector((state) => state.getSingleUser);
  const { loading: updateLoading, error: updateError } = useSelector(
    (state) => state.updateUser
  );
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const [showSendCertificateModal, setShowSendCertificateModal] = useState(false);
  const [allCourse, setAllCourse] = useState([]);
  const [allDiplomas, setAllDiplomas] = useState([]);
  const [addSubscriptionModle, setAddSubscrptionModle] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [addDiplomaSubscriptionModle, setAddDiplomaSubscrptionModle] =
    useState(false);
  const [selectedDiplomaId, setSelectedDiplomaId] = useState("");


  //#region Show And Hide Send Certificate Medule 
    const hideSendCertificateModal = () => {
      setShowSendCertificateModal(false)
    }
  //#endregion
  //#region  Student Data Updated

  //Update Form
  const [showUpdateDataForm, setShowUpdateDataForm] = useState(false);

  const [fullName, setFullName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [imgWallpaper, setImgWallpaper] = useState("");
  const [imgWallpaperFile, setImgWallpaperFile] = useState("");
  const fileUploaderImgWallpaper = useRef(null);
  const openFileUploaderImgWallpaper = () => {
    fileUploaderImgWallpaper.current.click();
  };
  //form errors
  const [fullNameErrorMessage, setFullNameErrorMessage] = useState("");
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

  const checkErrorMessages = () => {
    if (userData.role == "teacher") {
      if (
        fullNameErrorMessage === "" &&
        titleErrorMessage === "" &&
        emailErrorMessage === "" &&
        phoneErrorMessage === ""
      ) {
        return true;
      }
      return false;
    } else {
      if (
        fullNameErrorMessage === "" &&
        emailErrorMessage === "" &&
        phoneErrorMessage === ""
      ) {
        return true;
      }
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkErrorMessages()) {
      swal("Worrning!", "Pleace Enter Right Data", "error");
    } else {
      var formData;

      formData = {
        id: userData.id,
        name: fullName,
        title: title,
        mobile: phone,
        email: email,
        image: imgWallpaperFile,
        role: "student",
      };

      dispatch(updateUserApi(formData)).then((result) => {
        if (result.payload.message === " User updated Successfully") {
          swal("Good job!", result.payload.message, "success");
          setFullName("");
          setEmail("");
          setTitle("");
          setPhone("");
          setImgWallpaper("");
          setImgWallpaperFile("");
          handleCloseUpdateForm();
          getSingleUserDataFromApi();
        }
      });
    }
  };

  const handleCloseUpdateForm = () => {
    setFullName("");
    setTitle("");
    setEmail("");
    setPhone("");
    setShowUpdateDataForm(false);
  };

  const handleShowUpdateForm = () => {
    setTitle(userData.title);
    setFullName(userData.name);
    setEmail(userData.email);
    setPhone(userData.mobile);
    setShowUpdateDataForm(true);
  };

  //#endregion

  //#region get All Data Of Course And Diplomas To List

  const [userCourses, setUserCourses] = useState([]);
  const [userDiplomas, setUserDiplomas] = useState([]);

  //  get student Courses
  const getStudentCourses = async () => {
    dispatch(getStudentCourse(user_id)).then((res) => {
      if (res.payload.message === "fetched successfully") {
        setUserCourses(res.payload.data);
        setUserDiplomas(res.payload.diplomas);
      }
    });
  };

  const getSingleUserDataFromApi = () => {
    dispatch(getsingleUserAPi(user_id)).then((res) => {
      if (res.payload.message === "fetched successfully") {
        setUserData(res.payload.data);
      }
    });
  };
  //#endregion

  //#region  Subscriptions

  // Handle Delete
  const handleDelete = (data) => {
    console.log(data);
    if (data.type === "course") {
      Swal.fire({
        title: "Are you sure?",
        text: "You are going to delete Subscription",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteCourseSubscriptionApi(data.id)).then((resulet) => {
            if (resulet.payload.message === "Data Deleted Successfully") {
              Swal.fire("Deleted!", resulet.payload.message, "success");
              getStudentCourses();
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You are going to delete Subscription",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteDiplomaSubscriptionApi(data.id)).then((resulet) => {
            if (resulet.payload.message === "Data Deleted Successfully") {
              Swal.fire("Deleted!", resulet.payload.message, "success");
              getStudentCourses();
            }
          });
        }
      });
    }
  };

  const handleAddSubscriptions = () => {
    setSelectedCourseId("");
    setAddSubscrptionModle(true);
  };
  const handleAddDiplomaSubscriptions = () => {
    setSelectedDiplomaId("");
    setAddDiplomaSubscrptionModle(true);
  };

  const submittedHandleAddSubscriptions = () => {
    if (selectedCourseId === "") {
      swal("Oops", `Select Course To Add`, "error");
      return;
    }

    const formData = new FormData();
    formData.append("courseId", selectedCourseId);
    formData.append("userId", user_id);

    dispatch(addSubscription(formData)).then((res) => {
      if (res.payload.message === "Data Add Successfully") {
        swal("Success", res.payload.message, "success");
        setAddSubscrptionModle(false);
        setSelectedCourseId("");
        getSingleUserDataFromApi();
        getStudentCourses();
      } else {
        swal("Oops", res.payload.message, "error");
      }
    });
  };

  const submittedHandleAddDiplomasSubscriptions = () => {
    if (selectedDiplomaId === "") {
      swal("Oops", `Select Course To Add`, "error");
      return;
    }

    const objectData = {
      studentId: user_id,
      diplomaId: selectedDiplomaId,
    };

    dispatch(createDiplomaSubscriptionApi(objectData)).then((res) => {
      if (res.payload.message === "subscription added successfully") {
        swal("Success", res.payload.message, "success");
        setAddDiplomaSubscrptionModle(false);
        setSelectedDiplomaId("");
        getSingleUserDataFromApi();
        getStudentCourses();
        getAllCourseAndDiplomasFunction();
      } else {
        swal("Oops", res.payload.message, "error");
      }
    });
  };
  //#endregion

  //#region Update Password
  const { loading: updateUserPasswordLoading } = useSelector(
    (state) => state.updateUserPasswordSlicer
  );

  const [updateUserPasswordFormModule, setUpdateUserPasswordFormModule] =
    useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleFormSubmite = (value, { resetForm }) => {
    console.log(value);

    const objectData = {
      userId: user_id,
      newPassword: value.password,
    };

    dispatch(updateUserPasswordApi(objectData)).then((res) => {
      if (res.payload.message === "Password updated Successfully") {
        setUpdateUserPasswordFormModule(false);
        resetForm();
        swal("!Succedd", res.payload.message, "success");
      }
    });
  };

  //#endregion

  //#region delete Student Access
  const handleDeleteAccess = (id) => {
    const objectData = {
      studentId: user_id,
      userIpAdress: id,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete Subscription",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUserAccessApi(objectData)).then((resulet) => {
          if (resulet.payload.message === "Data updated successfully") {
            Swal.fire("Deleted!", resulet.payload.message, "success");
            getSingleUserDataFromApi();
          }
        });
      }
    });
  };
  //#endregion

  //get all course and diplomas to add to users
  const getAllCourseAndDiplomasFunction = async () => {
    dispatch(getAllCourseAndDiplomasApi()).then((res) => {
      if (res.payload.message === "fetched successfully") {
        console.log(res.payload.data);
        setAllCourse(res.payload.data.course);
        setAllDiplomas(res.payload.data.diplomas);
      }
    });
  };
  useEffect(() => {
    getSingleUserDataFromApi();
    getStudentCourses();
    getAllCourseAndDiplomasFunction();
  }, []);
  useEffect(() => {
    if (userData == null) {
      getSingleUserDataFromApi();
    }
    return;
  }, [userData]);
  return (
    <Fragment>
      <PageTitle activeMenu="Student Profile" motherMenu="profile" />

      {loading ? (
        <div className="row d-flex justify-content-center gap-2">
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="warning" />
        </div>
      ) : (
        <>
          {error ? (
            <h1>{error}</h1>
          ) : (
            <>
              {userData ? (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                          <div className="profile-info">
                            <div
                              className="profile-photo"
                              style={{ marginTop: 0 }}
                            >
                              {userData.image ? (
                                <img
                                  src={`${process.env.REACT_APP_VERSEL_API}/${userData.image}`}
                                  className="img-fluid rounded-circle"
                                  alt={userData.name}
                                />
                              ) : (
                                <img
                                  src={avatarMain}
                                  className="img-fluid rounded-circle"
                                  alt={userData.name}
                                />
                              )}
                            </div>
                            <div className="profile-details">
                              <div className="profile-name px-3 pt-2">
                                <h4 className="text-primary mb-0">
                                  {userData.name}
                                </h4>
                                <p>{userData.role}</p>
                              </div>
                              <div className="profile-email px-2 pt-2">
                                <h4 className="text-muted mb-0">
                                  {userData.email}
                                </h4>
                                <p>Email</p>
                              </div>
                              <Dropdown className="dropdown ms-auto">
                                <Dropdown.Toggle
                                  variant="primary"
                                  className="btn btn-primary light sharp i-false"
                                  data-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    //    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={handleShowUpdateForm}
                                  >
                                    <i className="fa fa-user-circle text-primary me-2" />
                                    Edite Profile
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={handleAddSubscriptions}
                                  >
                                    <i className="fa fa-plus text-primary me-2" />{" "}
                                    Add Course
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={handleAddDiplomaSubscriptions}
                                  >
                                    <i className="fa fa-plus text-primary me-2" />
                                    Add Track
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={()=>setShowSendCertificateModal(true)}
                                  >
                                    <i className="fa fa-plus text-primary me-2" />
                                    Send Certificate
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() =>
                                      setUpdateUserPasswordFormModule(true)
                                    }
                                  >
                                    <i className="fa fa-ban text-primary me-2" />
                                    Update Password
                                  </Dropdown.Item>

                                  {/* <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-users text-primary me-2" />
                        Add to close friends
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-plus text-primary me-2" />
                        Add to group
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-ban text-primary me-2" />
                        Block
                      </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="profile-tab">
                            <div className="custom-tab-1">
                              <div className="tab-content">
                                <div
                                  id="about-me"
                                  className={`tab-pane fade active show`}
                                >
                                  <div className="profile-personal-info">
                                    <h4 className="text-primary mb-4">
                                      Personal Information
                                    </h4>
                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          Name
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.name}</span>
                                      </div>
                                    </div>
                                    {userData.title && (
                                      <>
                                        <div className="row mb-2">
                                          <div className="col-3">
                                            <h5 className="f-w-500">
                                              Title
                                              <span className="pull-right">
                                                :
                                              </span>
                                            </h5>
                                          </div>
                                          <div className="col-9">
                                            <span> {userData.title}</span>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          Email
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.email}</span>
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          Phone
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.mobile}</span>
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          {" "}
                                          role
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.role}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {userData.ips && userData.ips.length !== 0 && (
                      <>
                        <div className="col-12">
                          <div className="row">
                            <h3>User Acceess</h3>

                            {userData.ips &&
                              userData.ips.map((item, index) => (
                                <IdernfiyerCard
                                  key={index}
                                  userIpAdress={item.ip}
                                  userId={item.uderIdData}
                                  handleDelete={handleDeleteAccess}
                                />
                              ))}
                          </div>
                        </div>
                      </>
                    )}

                    {userCourses.length == 0 && userDiplomas.length == 0 ? (
                      <>
                        <div className="row">
                          <div className="col-lg-12">
                            <h1>No Subscriptions</h1>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-xl-12">
                          <div className="row">
                            <h1 className="card-title">Courses</h1>

                            {userCourses && userCourses.length > 0 ? (
                              userCourses.map((item, index) => {
                                return (
                                  <>
                                    <CustomCouseCardStudent
                                      key={index}
                                      title={item.course_id.name.en}
                                      price={item.course_id.price}
                                      course_data={item.course_id}
                                      _id={item._id}
                                      image={item.course_id.image}
                                      handleDelete={handleDelete}
                                    />
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <h3>No Course</h3>
                              </>
                            )}
                          </div>
                          <div className="row">
                            <h1 className="card-title">Trackes</h1>

                            {userDiplomas && userDiplomas.length > 0 ? (
                              <>
                                {userDiplomas.map((item, index) => {
                                  return (
                                    <>
                                      <CustomDiplomaCardStudent
                                        key={item._id}
                                        title={item.diploms_id.name.en}
                                        price={item.diploms_id.price}
                                        _id={item._id}
                                        image={item.diploms_id.image}
                                        handleDelete={handleDelete}
                                      />
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                <h3>No Trakes</h3>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <Modal
                    show={showUpdateDataForm}
                    onHide={handleCloseUpdateForm}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Body>
                      <form className="form-valide" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-username"
                              >
                                Full Name
                                <span className="text-danger">*</span>
                              </label>
                              <div>
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  id="val-username"
                                  name="val-username"
                                  placeholder="Enter a full name.."
                                  onChange={(e) => {
                                    if (
                                      e.target.value.length < 3 ||
                                      e.target.value.length > 50
                                    ) {
                                      setFullNameErrorMessage(
                                        "Min 3 Characters Max 50 Characters"
                                      );
                                      setFullName(e.target.value);
                                    } else {
                                      setFullNameErrorMessage("");
                                      setFullName(e.target.value);
                                    }
                                  }}
                                  value={fullName}
                                />
                                {fullNameErrorMessage && (
                                  <Alert
                                    variant="warning"
                                    className="alert-dismissible fade mt-1 mb-0"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="me-2"
                                    >
                                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </svg>
                                    <strong>Warrning </strong>
                                    {fullNameErrorMessage}
                                  </Alert>
                                )}
                              </div>
                            </div>
                            {userData.role == "teacher" && (
                              <>
                                <div className="form-group mb-3 row">
                                  <label
                                    className="col-lg-4 col-form-label"
                                    htmlFor="val-title"
                                  >
                                    Teacher Title
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <input
                                      required
                                      type="text"
                                      className="form-control"
                                      id="val-title"
                                      name="val-title"
                                      placeholder="Enter a full name.."
                                      onChange={(e) => {
                                        if (
                                          e.target.value.length < 3 ||
                                          e.target.value.length > 50
                                        ) {
                                          setTitleErrorMessage(
                                            "Min 3 Characters Max 50 Characters"
                                          );
                                          setTitle(e.target.value);
                                        } else {
                                          setTitleErrorMessage("");
                                          setTitle(e.target.value);
                                        }
                                      }}
                                      value={title}
                                    />
                                    {titleErrorMessage && (
                                      <Alert
                                        variant="warning"
                                        className="alert-dismissible fade mt-1 mb-0"
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          fill="none"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="me-2"
                                        >
                                          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                          <line
                                            x1="15"
                                            y1="9"
                                            x2="9"
                                            y2="15"
                                          ></line>
                                          <line
                                            x1="9"
                                            y1="9"
                                            x2="15"
                                            y2="15"
                                          ></line>
                                        </svg>
                                        <strong>Warrning </strong>
                                        {titleErrorMessage}
                                      </Alert>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-email"
                              >
                                Email <span className="text-danger">*</span>
                              </label>
                              <div>
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  id="val-email"
                                  name="val-email"
                                  placeholder="Your valid email.."
                                  onChange={(e) => {
                                    if (e.target.value.length < 10) {
                                      setEmailErrorMessage(
                                        "invaild email min 10 characters"
                                      );
                                      setEmail(e.target.value);
                                    } else {
                                      setEmailErrorMessage("");
                                      setEmail(e.target.value);
                                    }
                                  }}
                                  value={email}
                                />
                                {emailErrorMessage && (
                                  <Alert
                                    variant="warning"
                                    className="alert-dismissible fade mt-1 mb-0"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="me-2"
                                    >
                                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </svg>
                                    <strong>Warrning </strong>
                                    {emailErrorMessage}
                                  </Alert>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-email"
                              >
                                Upload Image{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div>
                                <div className="custom-upload-file form-group mb-3">
                                  <label
                                    htmlFor="img-wallpaper"
                                    className="form-label"
                                    onClick={() => openFileUploaderImgWallpaper}
                                  >
                                    <i className="fa-solid fa-upload p-2"></i>
                                    {imgWallpaper
                                      ? imgWallpaper
                                      : "Upload Image Wallpaper  700 X 450"}
                                  </label>
                                  <input
                                    ref={fileUploaderImgWallpaper}
                                    hidden
                                    className="form-control form-control-md"
                                    type="file"
                                    id="img-wallpaper"
                                    onChange={(e) => {
                                      setImgWallpaperFile(e.target.files[0]);
                                      setImgWallpaper("");
                                      setImgWallpaper(e.target.files[0].name);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-phoneus"
                              >
                                Phone (US)
                                <span className="text-danger">*</span>
                              </label>
                              <div>
                                <input
                                  maxLength={11}
                                  type="text"
                                  className="form-control"
                                  id="val-phoneus"
                                  name="val-phoneus"
                                  placeholder="212-999-0000"
                                  onChange={(e) => {
                                    if (e.target.value[0] !== "0") {
                                      setPhoneErrorMessage(
                                        "Should Start With 0"
                                      );
                                    } else if (e.target.value[1] !== "1") {
                                      setPhoneErrorMessage(
                                        "Should Start With 01"
                                      );
                                    } else if (e.target.value.length < 11) {
                                      setPhoneErrorMessage(
                                        "Not Valid Egyption Number 01xxxxxxxxx"
                                      );
                                    } else {
                                      setPhoneErrorMessage("");
                                    }
                                    setPhone(e.target.value);
                                  }}
                                  value={phone}
                                />
                                {phoneErrorMessage && (
                                  <Alert
                                    variant="warning"
                                    className="alert-dismissible fade mt-1 mb-0"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="me-2"
                                    >
                                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </svg>
                                    <strong>Warrning </strong>
                                    {phoneErrorMessage}
                                  </Alert>
                                )}
                              </div>
                            </div>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleCloseUpdateForm}
                              >
                                Close
                              </Button>
                              {updateLoading ? (
                                <>
                                  <Button variant="primary" disabled>
                                    Loding....
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button variant="primary" type="submit">
                                    Update User
                                  </Button>
                                </>
                              )}
                            </Modal.Footer>
                          </div>
                        </div>
                      </form>
                    </Modal.Body>
                  </Modal>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <h1>No data</h1>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
      <>
        <Modal className="fade" show={addSubscriptionModle}>
          <Modal.Header>
            <Modal.Title>Add Subscription </Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setAddSubscrptionModle(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <h4>Select Course</h4>

            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                e.target.value && setSelectedCourseId(e.target.value);
              }}
              value={selectedCourseId}
            >
              <option defaultValue={null}>
                Select an option from this select menu
              </option>
              {allCourse &&
                allCourse.length > 0 &&
                allCourse.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.name.en && item.name.en}
                  </option>
                ))}
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setAddSubscrptionModle(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={submittedHandleAddSubscriptions}>
              Add Subscription
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal className="fade" show={addDiplomaSubscriptionModle}>
          <Modal.Header>
            <Modal.Title>Add Track Subscription </Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setAddDiplomaSubscrptionModle(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <h4>Select Track</h4>

            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                e.target.value && setSelectedDiplomaId(e.target.value);
              }}
              value={selectedDiplomaId}
            >
              <option defaultValue={null}>
                Select an option from this select menu
              </option>
              {allDiplomas &&
                allDiplomas.length > 0 &&
                allDiplomas.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.name.en && item.name.en}
                  </option>
                ))}
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setAddDiplomaSubscrptionModle(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={submittedHandleAddDiplomasSubscriptions}
            >
              Add Subscription
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal className="fade" show={updateUserPasswordFormModule}>
          <Modal.Header>
            <Modal.Title>Update Password</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setUpdateUserPasswordFormModule(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div className="basic-form">
              <Formik
                initialValues={{
                  password: "",
                }}
                validationSchema={updatePassword}
                onSubmit={handleFormSubmite}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div
                        className={`col-12 form-group mb-3 ${
                          values.password
                            ? errors.password
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">Password *</label>
                        <div className="input-group transparent-append mb-2">
                          <span className="input-group-text">
                            {" "}
                            <i className="fa fa-lock" />{" "}
                          </span>

                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="val-password1"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Choose a safe one.."
                          />

                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {" "}
                            {showPassword === false ? (
                              <i className="fa fa-eye-slash" />
                            ) : (
                              <i className="fa fa-eye" />
                            )}
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.password && errors.password}
                          </div>
                        </div>
                      </div>
                    </div>

                    {updateUserPasswordLoading ? (
                      <>
                        <button className="btn me-2 btn-primary" disabled>
                          <Spinner animation="grow" variant="warning" />
                        </button>
                      </>
                    ) : (
                      <>
                        <button type="submit" className="btn me-2 btn-primary">
                          Submit
                        </button>
                      </>
                    )}
                    <Button
                      variant="secondary"
                      onClick={() => setUpdateUserPasswordFormModule(false)}
                    >
                      Close
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>

        <SendCertificateComponents show={showSendCertificateModal} onHide ={hideSendCertificateModal}  //onFinshed ={} 
        userData={userData} />
      </>
    </Fragment>
  );
};
function CustomCouseCardStudent({
  title,
  course_data,
  price,
  _id,
  image,
  handleDelete,
}) {
  return (
    <>
      <div className="col-xl-4 col-md-6 ">
        <div className="card all-crs-wid">
          <div className="card-body">
            <div className="courses-bx">
              <div className="dlab-media">
                {image ? (
                  <img
                    src={`${process.env.REACT_APP_VERSEL_API}/${image}`}
                    alt={title}
                  />
                ) : (
                  <img src={avatar} alt={title} />
                )}
              </div>
              <div className="dlab-info">
                <div className="dlab-title d-flex justify-content-between">
                  <div>
                    <h4>
                      <Link to={`/course-details-2/${course_data._id}`}>
                        {title}
                      </Link>
                    </h4>
                  </div>
                  <h4 className="text-primary">
                    <span>$</span>
                    {price / 100}
                  </h4>
                </div>
                <div className="d-flex justify-content-between content align-items-center gap-1">
                  <Link
                    to={`/course-details-2/${course_data._id}`}
                    className="btn btn-primary btn-sm"
                  >
                    View
                  </Link>
                  <button
                    onClick={() => handleDelete({ type: "course", id: _id })}
                    className="btn btn-danger btn-sm"
                  >
                    Delete From Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function CustomDiplomaCardStudent({ title, price, _id, image, handleDelete }) {
  return (
    <>
      <div className="col-xl-6 col-md-6">
        <div className="card all-crs-wid">
          <div className="card-body">
            <div className="courses-bx">
              <div className="dlab-media">
                {image ? (
                  <img
                    src={`${process.env.REACT_APP_VERSEL_API}/${image}`}
                    alt={title}
                  />
                ) : (
                  <img src={avatar} alt={title} />
                )}
              </div>
              <div className="dlab-info">
                <div className="dlab-title d-flex justify-content-between">
                  <div>
                    <h4>
                      <Link to={`/diplome-detail/${_id}`}>{title}</Link>
                    </h4>
                  </div>
                  <h4 className="text-primary">
                    <span>$</span>
                    {price / 100}
                  </h4>
                </div>
                <div className="d-flex justify-content-between content align-items-center gap-1">
                  <Link
                    to={`/diplome-detail/${_id}`}
                    className="btn btn-primary btn-sm"
                  >
                    View
                  </Link>
                  <button
                    onClick={() => handleDelete({ type: "diploma", id: _id })}
                    className="btn btn-danger btn-sm"
                  >
                    Delete From Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const IdernfiyerCard = ({ userIpAdress, userId, handleDelete }) => {
  return (
    <>
      <div className="col-lg-4 col-sm-6">
        <div className="card">
          <div className="card-body">
            <ul>
              <li>
                IP : <strong>{userIpAdress}</strong>
              </li>
              <li>
                User Device : <strong>{userId}</strong>
              </li>
            </ul>
            <Link to={"#"} onClick={() => handleDelete(userIpAdress)}>
              <span className="badge badge-danger">
                <i className="fa-solid fa-trash" /> DELETE
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentProfile;
