import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../services/LogoutFunction";

export const deleteCourseApi = createAsyncThunk(
  "course/delete",
  async (courseData) => {
    console.log(courseData);
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/courses/deleteCourseById/${courseData.courseId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.log(error);
        formatError(error);
        return error;
      });
    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const DeleteCourseSlicer = createSlice({
  name: "deleteCourse",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteCourseApi.pending, (state) => {
      state.loading = true;
      state.response = null;
      state.error = null;
    });
    builder.addCase(deleteCourseApi.fulfilled, (state, action) => {
      state.loading = true;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      } else {
        state.response = action.payload;
        state.error = null;
        console.log(action.payload);
      }

      //   if (action.payload.message === "fetched successfully") {
      //     state.loading = false;
      //     state.response = action.payload;
      //     state.error = null;
      //   } else {
      //     state.loading = false;
      //     state.response = null;
      //     state.error = action.payload.message;
      //     formatError(state.error);
      //   }
    });
    builder.addCase(deleteCourseApi.rejected, (state, action) => {
      state.loading = false;
      state.response = null;
      state.error = action.payload.message;
      formatError(state.error);
    });
  },
});

export default DeleteCourseSlicer.reducer;
