import { lazy, Suspense, useEffect } from "react";

/// Components
import Index from "./jsx";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { useState } from "react";
import  checkAutoLogin from "./services/LogoutFunction";

// const SignUp = lazy(() => import("./jsx/pages/Registration"));
// import ForgotPassword  from "./jsx/pages/ForgotPassword";
// import Login  from "./jsx/pages/Login";
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function App(props) {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(checkAutoLogin());
  });

  let routes = (
    <Switch>
      <Route path="/" component={Login} />
      <Route path="/login" component={Login} />
      {/* <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} /> */}
      {/* <Route path="*" component={Login} /> */}
    </Switch>
  
);


if (isLoggedIn) {
  return (
    <>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Index />
      </Suspense>
    </>
  );
} else {
  return (
    <div className="vh-100">
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {routes}
      </Suspense>
    </div>
  );
}

}

export default App;
