import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getStudentCourse = createAsyncThunk(
  "user/getSubscripeCourse",
  async (userId) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/userCourse/${userId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => res)
      .catch((error) => error);
  }
);

const getStudentCoursesSlicer = createSlice({
  name: "getStudentCourse",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentCourse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStudentCourse.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload);
      if (action.payload.message === "fetched successfully") {
        state.data = action.payload.data;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(getStudentCourse.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default getStudentCoursesSlicer.reducer;
