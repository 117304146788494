import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const removeCourseToDiplomeApi = createAsyncThunk(
  "diplome/remove course",
  async (diplomeData) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/diplome/remove-courses/${diplomeData.diplomeId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData}`,
        },
        body: JSON.stringify({
          courseId : diplomeData.courseId,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        formatError(error);
        return error;
      });

    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}
const removeCourseToDiplomeSlicer = createSlice({
  name: "remove course from diplome",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(removeCourseToDiplomeApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeCourseToDiplomeApi.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(removeCourseToDiplomeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      formatError(state.error);
    });
  },
});

export default removeCourseToDiplomeSlicer.reducer;
