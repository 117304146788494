import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const createPromocodeApi = createAsyncThunk("promocode/create", async (promocodeData) => {
  try {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/user/promocode/create`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData}`
        },
        body: JSON.stringify(promocodeData)
      }
    ).then((res) => res.json());
  } catch (error) {
    swal("Error", error, "info");
    return error;
  }
});

const createPromocodeSlicer = createSlice({
  name: "create New Promocode",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(createPromocodeApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPromocodeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(createPromocodeApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Promocode created Successfully") {
        state.response = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default createPromocodeSlicer.reducer;
