import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const getQuizeApi = createAsyncThunk("quize/getById", async (quizeId) => {
  try {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/quize/getById/${quizeId}`,{
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    ).then((res) => res.json());
  } catch (error) {
    swal("Error", error, "info");
    return error;
  }
});

const getQuizeByIdSlicer = createSlice({
  name: "get Quize By Id",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getQuizeApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getQuizeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getQuizeApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Quize fetched Successfully") {
        state.data = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default getQuizeByIdSlicer.reducer;
