import "./loading-screen.css";
function LoadingScreen({loading}) {
    return (
        <>
            <div className={`fixed loading-parent`}>
                <div className="loader">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                </div>
            </div>
        </>
    )
}
export default LoadingScreen;