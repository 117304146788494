import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const deleteQuizeById = createAsyncThunk("quize/DeteteById", async (qizeId) => {
  try {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/quize/deleteById/${qizeId}`,{
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    ).then((res) => res.json());
  } catch (error) {
    swal("Error", error, "info");
    return error;
  }
});

const deleteQuizeByIdSlicer = createSlice({
  name: "Delete Quize",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteQuizeById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteQuizeById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteQuizeById.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload.message === "Quize Deleted Successfully") {
        state.response = action.payload.message;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default deleteQuizeByIdSlicer.reducer;
