import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const uploadDemoVideoApi = createAsyncThunk(
  "course/uploadDemo",
  async (courseData) => {
    const token = JSON.parse(localStorage.getItem("userData")).token;
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/courses/uploadDemo`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          courseId: courseData.courseId,
          demo_video: courseData.demo_video,
        }),
      }
    );
  }
);

const UploadDemoVideoSlicer = createSlice({
  name: "uploadDemoVideo",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(uploadDemoVideoApi.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(uploadDemoVideoApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.status == 200) {
        state.response = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(uploadDemoVideoApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default UploadDemoVideoSlicer.reducer;
