import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../../../services/LogoutFunction";
import { handleError } from "../../../services/ErrorHandle";

export const sendMessateToNewslistApi = createAsyncThunk(
  "newslist/sendMessage",
  async (data) => {

    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/user/message/sendMessage`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        body:JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        return error;
      });

    return request;
  }
);

const NewsListMessageSlicer = createSlice({
  name: "Message",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(sendMessateToNewslistApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendMessateToNewslistApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        handleError(state.error);
        LogoutFunction();
      } else if (action.payload.message === "Email Sent Successfully") {
        state.data = action.payload;
      } else {
        state.error = action.payload;
        handleError(state.error);
      }
    });
    builder.addCase(sendMessateToNewslistApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      handleError(state.error);
    });
  },
});

export default NewsListMessageSlicer.reducer;
