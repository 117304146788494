import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const createLessonQuestionFunc = createAsyncThunk("question/create", async (questionData) => {
  try {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/questions/create/${questionData?.lessonId}/lesson`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData}`
        },
        body: JSON.stringify(questionData.data)
      }
    ).then((res) => res.json());
  } catch (error) {
    swal("Error", error, "info");
    return error;
  }
});

const createLessonQuestionSlicer = createSlice({
  name: "create New Question",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(createLessonQuestionFunc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createLessonQuestionFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(createLessonQuestionFunc.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload.message === "Question created Successfully") {
        state.response = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default createLessonQuestionSlicer.reducer;
