import React, { Fragment, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { addUserFunction } from "../../../../store/AddUserSlicer";
import { useDispatch, useSelector } from "react-redux";

const creatInstrtactorSchema = Yup.object().shape({
  enname: Yup.string()
    .min(6, "Your name must consist of at least 6 characters ")
    .max(100, "Your name must consist of at most 100 characters ")
    .required("Please enter a name"),
  arname: Yup.string()
    .min(6, "Your name must consist of at least 6 characters ")
    .max(100, "Your name must consist of at most 100 characters ")
    .required("Please enter a name"),

  entitle: Yup.string()
    .min(6, "Your title must consist of at least 6 characters ")
    .max(200, "Your title must consist of at most 200 characters ")
    .required("Please enter a title"),
  artitle: Yup.string()
    .min(6, "Your title must consist of at least 6 characters ")
    .max(200, "Your title must consist of at most 200 characters ")
    .required("Please enter a title"),

  endescription: Yup.string()
    .min(10, "Your title must consist of at least 10 characters ")
    .max(2000, "Your title must consist of at most 2000 characters ")
    .required("Please enter a description"),
  ardescription: Yup.string()
    .min(10, "Your description must consist of at least 10 characters ")
    .max(2000, "Your description must consist of at most 2000 characters ")
    .required("Please enter a description"),

  email: Yup.string()
    .min(3, "Your email is to short ")
    .email()
    .required("Please enter a email"),
  password: Yup.string()
    .min(8, "Your password must be at least 8 characters")
    .max(200, "Your password must be at most 200 characters")
    .required("Please provide a password"),
  mobile: Yup.number()
    .moreThan(11, "phone number must be 11 degite")
    .required("Please provide a mobile number"),
  role: Yup.string().required("Please select role"),
});

export default function AddInstrtactorForm(props) {
  console.log(props.show);
  const { loading } = useSelector((state) => state.addUsers);
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [imgWallpaper, setImgWallpaper] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageError, setImageError] = useState("Image Is Requierd");

  const fileUploaderImgWallpaper = useRef(null);
  const openFileUploaderImgWallpaper = () => {
    fileUploaderImgWallpaper.current.click();
  };
  const handleFormSubmite = (value, { resetForm }) => {
    console.log(value);

    if (!imgWallpaper && imageError !== "") {
      swal(
        "Data Not Completed!",
        "Insert instractor image to complete ",
        "error"
      );
    } else {
      var formData;
      formData = {
        name: value.enname,
        title: value.entitle,
        mobile: value.mobile,
        email: value.email,
        password: value.password,
        image: imgWallpaper,
        role: value.role,
        extra_info: {
          enname: value.enname,
          arname: value.arname,
          entitle: value.entitle,
          artitle: value.artitle,
          endescription: value.endescription,
          ardescription: value.ardescription,
        },
      };

      dispatch(addUserFunction(formData)).then((result) => {
        if (result.payload.message === " User created Successfully") {
          swal("Good job!", result.payload.message, "success");
          setImgWallpaper("");
          setImageName("");
          setImageError("Image Is Requierd");
          resetForm();
          props.hideAndReload(true);
        } else {
          swal("Error", result.payload.message, "error");
        }
      });
    }
  };
  return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={() => props.hideAndReload(false)}
        backdrop="static"
        // keyboard={false}
      >
        <Modal.Body>
          <h4 className="card-title">Add Instractor Form</h4>

          <div className="basic-form">
            <Formik
              initialValues={{
                enname: "",
                arname: "",
                entitle: "",
                artitle: "",
                endescription: "",
                ardescription: "",
                email: "",
                mobile: "",
                role: "teacher",
                password: "",
              }}
              validationSchema={creatInstrtactorSchema}
              onSubmit={handleFormSubmite}
            >
              {({
                values,
                errors,
                setFieldValue,
                setValues,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    {/* Name  */}
                    <div
                      className={`col-6 form-group mb-3 ${
                        values.enname
                          ? errors.enname
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">Enter English Name</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa fa-user" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a name.."
                          name="enname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.enname}
                        />
                        <div
                          id="val-enname-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.enname && errors.enname}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-6 form-group mb-3 ${
                        values.arname
                          ? errors.arname
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">Enter Arabic Name</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa fa-user" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a name.."
                          name="arname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.arname}
                        />
                        <div
                          id="val-arname-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.arname && errors.arname}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* Title  */}
                    <div
                      className={`col-6 form-group mb-3 ${
                        values.entitle
                          ? errors.entitle
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">Enter English Title</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i class="fa-brands fa-cuttlefish"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a Title.."
                          name="entitle"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.entitle}
                        />
                        <div
                          id="val-entitle-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.entitle && errors.entitle}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-6 form-group mb-3 ${
                        values.artitle
                          ? errors.artitle
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">Enter Arabic Title</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i class="fa-brands fa-cuttlefish"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a Title.."
                          name="artitle"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.artitle}
                        />
                        <div
                          id="val-artitle-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.artitle && errors.artitle}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* Email  */}
                    <div
                      className={`col-6 form-group mb-3 ${
                        values.email
                          ? errors.email
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">email</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i class="fa-solid fa-envelope"></i>
                        </span>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter a email.."
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        <div
                          id="val-email-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.email && errors.email}
                        </div>
                      </div>
                    </div>
                    {/* Password  */}
                    <div
                      className={`col-6 form-group mb-3 ${
                        values.password
                          ? errors.password
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">Password *</label>
                      <div className="input-group transparent-append mb-2">
                        <span className="input-group-text">
                          {" "}
                          <i className="fa fa-lock" />{" "}
                        </span>

                        <input
                          type={`${showPassword ? "text" : "password"}`}
                          className="form-control"
                          id="val-password1"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Choose a safe one.."
                        />

                        <div
                          className="input-group-text "
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {" "}
                          {showPassword === false ? (
                            <i className="fa fa-eye-slash" />
                          ) : (
                            <i className="fa fa-eye" />
                          )}
                        </div>
                        <div
                          id="val-username1-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.password && errors.password}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* Mobile & Image  */}
                    <div
                      className={`col-12 form-group mb-3 ${
                        values.mobile
                          ? errors.mobile
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">mobile</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i class="fa-solid fa-mobile"></i>
                        </span>
                        <input
                          type="tel"
                          maxLength={11}
                          className="form-control"
                          placeholder="Enter a mobile.."
                          name="mobile"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile}
                        />
                        <div
                          id="val-mobile-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.mobile && errors.mobile}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-12 form-group mb-3 ${
                        imgWallpaper
                          ? imageError
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">
                        Upload Image <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <div className="w-100 custom-upload-file form-group mb-3">
                          <label
                            htmlFor="img-wallpaper"
                            className="form-label"
                            onClick={() => openFileUploaderImgWallpaper}
                          >
                            <i className="fa-solid fa-upload p-2"></i>
                            {imgWallpaper
                              ? imageName
                              : "Upload Image Wallpaper  700 X 450"}
                          </label>
                          <input
                            ref={fileUploaderImgWallpaper}
                            hidden
                            className="form-control form-control-md"
                            name="image"
                            type="file"
                            id="img-wallpaper"
                            accept="image/*"
                            onChange={(e) => {
                              setImgWallpaper("");
                              setImageName("");

                              if (
                                e.target.files[0].type.split("/")[0] === "image"
                              ) {
                                setImgWallpaper(e.target.files[0]);
                                setImageName(e.target.files[0].name);
                                setImageError("");
                              } else {
                                setImageError("Please Insert Image");
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div
                          id="val-image-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {imageError && imageError}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* Description  */}
                    <div
                      className={`col-12 form-group mb-3 ${
                        values.endescription
                          ? errors.endescription
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">
                        Enter English Description
                      </label>
                      <div className="input-group">
                        <textarea
                          rows={5}
                          style={{ resize: "none" }}
                          className="form-control"
                          placeholder="Enter a description.."
                          name="endescription"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.endescription}
                        ></textarea>
                        <div
                          id="val-endescription-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.endescription && errors.endescription}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-12 form-group mb-3 ${
                        values.ardescription
                          ? errors.ardescription
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">
                        Enter Arabic Description
                      </label>
                      <div className="input-group">
                        <textarea
                          rows={5}
                          style={{ resize: "none" }}
                          className="form-control"
                          placeholder="Enter a description.."
                          name="ardescription"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.ardescription}
                        ></textarea>
                        <div
                          id="val-ardescription-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.ardescription && errors.ardescription}
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <>
                      <button className="btn me-2 btn-primary" disabled>
                        <Spinner variant="primary" animation="border" />
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="submit" className="btn me-2 btn-primary">
                        Create Instractor
                      </button>
                    </>
                  )}

                  <button
                    className="btn me-2 btn-danger"
                    onClick={() => props.hideAndReload(false)}
                  >
                    Close
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
