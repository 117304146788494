import React, { Fragment, useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Modal, Spinner } from "react-bootstrap";
import { getAllCourseAndDiplomasApi } from "../../../../store/Slices/subscriptions/getAllCourseAndDiplomasSlicer";
import { sendCertififcateApi } from "../../../../store/Slices/users/sendCertificateToStudentSlicer";

const createCertificate = Yup.object().shape({
  courseID: Yup.string().required("Please Select a course "),
  date: Yup.string().required("Please enter a valid date"),
});

const SendCertificateComponents = ({ show, onHide, onFinshed, userData }) => {
    console.log(userData);
  const { loading, error } = useSelector((state) => state.sendCertificateToStudentSlicer);
  const dispatch = useDispatch();
  const [allCourse, setAllCourse] = useState([]);

  const getAllCourseAndDiplomasFunction = async () => {
    dispatch(getAllCourseAndDiplomasApi()).then((res) => {
      if (res.payload.message === "fetched successfully") {
        setAllCourse(res.payload.data.course);
      }
    });
  };
  useEffect(() => {
    getAllCourseAndDiplomasFunction();
  }, []);

  // Get All Data Function
  const sendCertificateToUser = (values, { resetForm }) => {
    const objectData = {
      studentId: userData.id,
      courseId: values.courseID,
      date: values.date,
    };

    dispatch(sendCertififcateApi(objectData)).then((resulte) => {
      if (resulte.payload.message === "Data Send Successfuly") {
        swal("Good job!", resulte.payload.message, "success");
        resetForm();
        onHide();
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Body>
        <div className="col-xl-12 col-xxl-12">
          <Formik
            initialValues={{
              courseID: "",
              date: "",
            }}
            validationSchema={createCertificate}
            onSubmit={sendCertificateToUser}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form className="row" onSubmit={handleSubmit}>
                <div
                  className={`col-12 form-group mb-3 ${
                    values.courseID
                      ? errors.courseID
                        ? "is-invalid"
                        : "is-valid"
                      : ""
                  }`}
                >
                  <label className="text-label">Select Course </label>
                  <div className="input-group transparent-append mb-2">
                    <select
                      className="form-control"
                      name="courseID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.courseID}
                      placeholder="Chose Course"
                    >
                      <option defaultValue={""}>Please select</option>
                      {allCourse &&
                        allCourse.length != 0 &&
                        allCourse.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.name.en}
                          </option>
                        ))}
                    </select>
                    <div
                      id="val-courseID-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.courseID && errors.courseID}
                    </div>
                  </div>
                </div>
                <div
                  className={` col-lg-12 col-sm-12 form-group mb-3 ${
                    values.date ? (errors.date ? "is-invalid" : "is-valid") : ""
                  }`}
                >
                  <label className="text-label">Enter Date</label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa-solid fa-file-signature"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter a Date EX. 2020/01/01"
                      name="date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.date}
                    />
                    <div
                      id="val-name-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.date && errors.date}
                    </div>
                  </div>
                </div>
                <div className="col">
                  {loading ? (
                    <>
                      <Button variant="primary" disabled>
                        <Spinner animation="grow" variant="info" />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="primary" type="submit">
                        Create
                      </Button>
                    </>
                  )}
                  <Button className="mx-2" variant="danger" onClick={onHide}>
                    Close
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SendCertificateComponents;
