import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Form } from "react-bootstrap";
import { act } from "react-dom/test-utils";
import swal from "sweetalert";
import LogoutFunction from "../services/LogoutFunction";

export const addLessnApi = createAsyncThunk(
  "lessons/addLesson",

  async (data) => {
   
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    // const formData = new FormData();
    // formData.append("name", data.lessonTitile);
    // formData.append("courseId", data.coureseId);
    // formData.append("section", data.sectionNumber);
    // formData.append("video", data.video);

    // const request = await fetch(
    //   `${process.env.REACT_APP_VERSEL_API}/api/users/lessons/create`,
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${tokenData}`,
    //     },
    //     body: formData,
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log(result);
    //     return result;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     formatError(error);
    //     return error;
    //   });
    
 
    let formData = new FormData();
    formData.append("video", data.video);
    formData.append("name", data.lessonTitile);
    formData.append("courseId", data.coureseId);
    formData.append("section", data.sectionNumber);
    
    
  
    const request = await axios.request({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_VERSEL_API}/api/users/lessons/create`,
      headers: { 
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${tokenData}`
      },
      data : formData,
      onUploadProgress : (e)=>{
       
       console.log(Math.round(100 * e.loaded) / e.total);
      
      }
    }).then((result) => {
        console.log(result);
        return result.data;
      })
      .catch((error) => {
        console.log(error);
        formatError(error);
        return error;
      });
     

    return request;
  }
);


function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const addessonSlicer = createSlice({
  name: "addLessons",
  initialState: {
    loading: false,
    respons: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(addLessnApi.pending, (state) => {
      state.loading = true;
      state.respons = null;
      state.error = null;
      console.log("add lessooon pendinnnng");
    });

    builder.addCase(addLessnApi.fulfilled, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      }
      else if (action.payload.message === "lesson added successfully") {
        state.respons = action.payload;
        state.error = null;
      } else {
        
        state.respons = null;
        state.error = action.payload.message;
        formatError(state.error);
      }
    });
    builder.addCase(addLessnApi.rejected, (state, action) => {
      state.loading = false;
      state.respons = null;
      state.error = action.payload.message;
      formatError(state.error);
    });
  },
});

export default addessonSlicer.reducer;
