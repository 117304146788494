import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import swal from "sweetalert";
import { getAllPromocodeApi } from "../../../store/Slices/promocode/getAllPromocodeSlicer";
import PageTitle from "../../layouts/PageTitle";
import { createPromocodeApi } from "../../../store/Slices/promocode/createPromocodeSlicer";
import { handleError } from "../../../services/ErrorHandle";
import { updatePromocodeApi } from "../../../store/Slices/promocode/UpdatePromocodeSlicer";
import { deletePromocodeApi } from "../../../store/Slices/promocode/DeletePromocodeSlicer";
import Summermessage from "./components/SummerMessage";
const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const createPromoCode = Yup.object().shape({
  promocode: Yup.string()
    .required("Please enter a quize name")
    .matches(/^\S+$/, "Promocode Not Have Spaces"),
  discount: Yup.number().required("Please enter a discount"),
  maximum_usage: Yup.number().required("Please enter a max usage"),
});

function NewslistMain() {
 
   return (
    <>
      <div className="widget-heading d-flex justify-content-between align-items-center">
        <h3 className="m-0">Send Emails To All Supescripers</h3>
      
      </div>
      <Summermessage />



      {/* {getAllLoading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {getAllError ? (
            <>
              <div className="row d-flex text-center gap-2">
                <h3>Error Happen </h3>
              </div>
            </>
          ) : (
            <>
              {promocodeData && promocodeData.length > 0 ? (
                <>
                  <Fragment>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <Card.Body>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th className="width80">
                                    <strong>#</strong>
                                  </th>
                                  <th>
                                    <strong>Promocode</strong>
                                  </th>
                                  <th className="text-center">
                                    <strong>Maxmum Used</strong>
                                  </th>
                                  <th className="text-center">
                                    <strong>Number Used</strong>
                                  </th>
                                  <th>
                                    <strong>STATUS</strong>
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {promocodeData.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <strong>{index + 1}</strong>
                                    </td>
                                    <td>{item.promocode}</td>
                                    <td className="text-center">{item.maximum_usage}</td>
                                    <td className="text-center">{item.num_usage}</td>
                                    <td>
                                      <Badge variant="success light">
                                        {item.discount} %
                                      </Badge>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <button
                                          href="#"
                                          className="btn btn-primary shadow btn-xs sharp me-1"
                                          onClick={() => openEditModule(item)}
                                        >
                                          <i className="fas fa-pencil-alt"></i>
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleDeletePromocode(item._id)
                                          }
                                          className="btn btn-danger shadow btn-xs sharp"
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>
                </>
              ) : (
                <>
                  <div className="row d-flex text-center gap-2">
                    <h3>No Data Added Yet</h3>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )} */}

     
    </>
  );
}
export default NewslistMain;
