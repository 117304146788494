import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";

import { getAllDiplomasSubscriptionsApi } from "../../../../store/Slices/subscriptions/diplomas/getAllDiplomasSubscriptionsSlicer";
import { deleteDiplomaSubscriptionApi } from "../../../../store/Slices/subscriptions/diplomas/deleteDiplomaSubscriptionSclicer";
import Swal from "sweetalert2";

const DisplayAllDiplomasSubscriptionsTableData = () => {
  const { loading, error } = useSelector(
    (s) => s.getAllDiplomasSubscriptionsSlicer
  );

  const dispatch = useDispatch();
  const [diplomasSubscriptions, setDiplomasSubscriptions] = useState();
  const [pages, setPages] = useState(1);


  // Handle Delete
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete Subscription",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDiplomaSubscriptionApi(id)).then((resulet) => {
          if (resulet.payload.message === "Data Deleted Successfully") {
            Swal.fire("Deleted!", resulet.payload.message, "success");
            getAllSubscriptionsForDiplomas(1);
          }
        });
      }
    });
  };
  // Handle Get All User Data
  const getAllSubscriptionsForDiplomas = async (pageNumber) => {
    dispatch(getAllDiplomasSubscriptionsApi(pageNumber)).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        console.log(resulet.payload.data);
        setDiplomasSubscriptions(resulet.payload.data);
        resulet.payload.data.total &&
        setPages(Math.ceil(resulet.payload.data.total / 10));
      } else {
        swal("Error!", error, "error");
      }
    });
  };

  const activePagenation = (number, activeNum) => {
    const allListItem = document.querySelectorAll("#pagination-list-item li");
    allListItem.forEach((item) => {
      item.classList.remove("active");
    });
    allListItem[activeNum].classList.add("active");
    getAllSubscriptionsForDiplomas(number);
  };

  useEffect(() => {
    getAllSubscriptionsForDiplomas(1);
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="col-12 d-flex justify-content-center gap-3">
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="primary" />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>{error}</h2>
              </div>
            </>
          ) : (
            <>
              {diplomasSubscriptions && (
                <>
                  {diplomasSubscriptions.subscriptions.length > 0 ? (
                    <>
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="w-100 table-responsive">
                              <div
                                id="example_wrapper"
                                className="dataTables_wrapper"
                              >
                                <table
                                  id="example"
                                  className="display w-100 dataTable"
                                >
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>user Email</th>
                                      <th>Track Name</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {diplomasSubscriptions.subscriptions.map(
                                      (content, index) => (
                                        <tr key={index}>
                                          <>
                                            <td>##</td>
                                            <td>
                                              {content.user_id &&
                                              content.user_id.email
                                                ? content.user_id.email
                                                : "No Data"}
                                            </td>
                                            <td>
                                              {content.diploms_id &&
                                              content.diploms_id.name &&
                                              content.diploms_id.name.en
                                                ? content.diploms_id.name.en
                                                : "No Data"}
                                            </td>
                                            <td>
                                              <Link
                                                to={"#"}
                                                onClick={() =>
                                                  handleDelete(content._id)
                                                }
                                              >
                                                <span className="badge badge-danger">
                                                  <i className="fa-solid fa-trash" />
                                                </span>
                                              </Link>
                                            </td>
                                          </>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pagination-down">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <h4 className="sm-mb-0 mb-3">
                            {/* Showing <span>1-6 </span>from <span>100 </span>data */}
                          </h4>
                          <ul id="pagination-list-item">
                            {/* <li>
                              <Link to={"#"}>
                                <i className="fas fa-chevron-left"></i>
                              </Link>
                            </li> */}

                            {pages &&
                              [...Array(pages)].map((page, index) => (
                                <li key={index}>
                                  <Link
                                    to={"#"}
                                    //className="active"
                                    onClick={() => {
                                      activePagenation(index + 1, index);
                                    }}
                                  >
                                    {index + 1}
                                  </Link>
                                </li>
                              ))}
                            {/* 
                            <li>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  getAllSubscriptionsForCourse(2);
                                }}
                              >
                                2
                              </Link>
                            </li> */}

                            {/* <li>
                              <Link to={"#"}>
                                <i className="fas fa-chevron-right"></i>
                              </Link>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 d-flex justify-content-center gap-3">
                        <h2>No Subscriptions happen yets</h2>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default DisplayAllDiplomasSubscriptionsTableData;
