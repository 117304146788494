import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";

import { FetchPayments } from "../../../store/Slices/payments/getAllPaymentsSlicer";

const DisplayAllPaymentsTableData = () => {
  const { loading, error } = useSelector((s) => s.getAllPaymentsSlicer);

  const dispatch = useDispatch();
  const [payment, setPayment] = useState([]);
  const [backupData, setBackupData] = useState([]);

  // Handle Get All User Data
  const getAllPayments = async () => {
    dispatch(FetchPayments()).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        setPayment(resulet.payload.data);
        setBackupData(resulet.payload.data);
      } else {
        swal("Error!", error, "error");
      }
    });
  };

  const formsFilters = (e) => {
    if (e.target.value === "") {
      setPayment(backupData);
      return;
    }

    const filterArray = backupData.filter((item) => {
      return (
        item.course.length != 0 &&
        item.course[0].name.en
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
        ||
        item.user.length != 0 &&
        item.user[0].email
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });
    setPayment(filterArray);
  };



  useEffect(() => {
    getAllPayments();
  }, []);

  return (
    <>
      <div className="col-xl-12">
        <div className="card students-list">
          <div className="card-header border-0 flex-wrap ">
            <h4>All Transactions ({backupData.length})</h4>
            <div className="input-group search-area w-auto">
              <span className="input-group-text">
                <Link to={"#"}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                      fill="var(--primary)"
                    ></path>
                  </svg>
                </Link>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                onChange={formsFilters}
              />
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <>
          <div className="col-12 d-flex justify-content-center gap-3">
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="primary" />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>{error}</h2>
              </div>
            </>
          ) : (
            <>
              {payment && (
                <>
                  {payment.length > 0 ? (
                    <>
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="w-100 table-responsive">
                              <div
                                id="example_wrapper"
                                className="dataTables_wrapper"
                              >
                                <table
                                  id="example"
                                  className="display w-100 dataTable"
                                >
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>user Email</th>
                                      <th>course Name</th>
                                      <th>Amount</th>
                                      <th>success</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {payment.map((content, index) => (
                                      <tr key={index}>
                                        <>
                                          <td>{content.createdAt}</td>
                                          <td>
                                            {content.user &&
                                            content.user.length != 0
                                              ? content.user[0].email
                                              : "No Data"}
                                          </td>
                                          <td>
                                            {content.course &&
                                            content.course.length != 0
                                              ? content.course[0].name.en
                                              : "no data"}
                                          </td>
                                          <td>
                                            {content.amount_cents / 100} EG
                                          </td>
                                          <td>
                                            {content.success ? (
                                              <>
                                                <span
                                                  variant="success"
                                                  className="badge bg-success"
                                                >
                                                  success
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span
                                                  variant="error"
                                                  className="badge bg-danger"
                                                >
                                                  not success
                                                </span>
                                              </>
                                            )}
                                          </td>
                                        </>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 d-flex justify-content-center gap-3">
                        <h2>No Transations happen yets</h2>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default DisplayAllPaymentsTableData;
