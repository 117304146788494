import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const deleteDiplomeByIdApi = createAsyncThunk(
  "diplome/delete",
  async (diplomeId) => {

    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/diplome/delete/${diplomeId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.log(error);
        formatError(error);
        return error;
      });
    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const deleteDiplomeByIdSlicer = createSlice({
  name: "delete diplome",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteDiplomeByIdApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDiplomeByIdApi.fulfilled, (state, action) => {
      state.loading = true;
      state.response = action.payload;
    });
    builder.addCase(deleteDiplomeByIdApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default deleteDiplomeByIdSlicer.reducer;
