import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const updateQuizeApi = createAsyncThunk(
  "quize/updateById",
  async ({ quizeData, quizId }) => {
    try {
      const tokenData = await JSON.parse(localStorage.getItem("userData"))
        .token;
      return await fetch(
        `${process.env.REACT_APP_VERSEL_API}/api/users/quize/updateById/${quizId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData}`,
          },
          body: JSON.stringify(quizeData),
        }
      ).then((res) => res.json());
    } catch (error) {
      swal("Error", error, "info");
      return error;
    }
  }
);

const updateQuizeSlicer = createSlice({
  name: "update Quizes",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateQuizeApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateQuizeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateQuizeApi.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload.message === "Quize Updated Successfully") {
        state.response = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default updateQuizeSlicer.reducer;
