import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

//images

import course1 from "./../../../images/courses/quiz1.jpg";

import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getAllQuizesApi } from "../../../store/Slices/quizes/getAllQuizesSlicer";
import { deleteQuizeById } from "../../../store/Slices/quizes/deleteQuizeByIdSlicer";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Dropdown, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { createQuizeApi } from "../../../store/Slices/quizes/createQuizeSlicer";
import { updateQuizeApi } from "../../../store/Slices/quizes/updateQuizeSlicer";

const createQuize = Yup.object().shape({
  name: Yup.string()
    .min(6, "quize name must consist of at least 6 characters ")
    .max(50, "quize name must consist of at most 50 characters ")
    .required("Please enter a quize name"),
  description: Yup.string()
    .min(6, "description  must consist of at least 6 characters ")
    .max(200, "description must consist of at most 200 characters ")
    .required("Please enter a  description"),
  duration: Yup.number().required("Please enter a duration in min"),
  number_of_attempts: Yup.number().required(
    "Please enter a number of attempts "
  ),
});
function QuizesMain() {
  const dispatch = useDispatch();
  const {
    loading: QuizesLoading,
    quizes: QuizesData,
    error: QuizesError,
  } = useSelector((state) => state.getAllQuizesSlicer);

  const { loading: createQuizesLoading, error: createQuizesError } =
    useSelector((state) => state.createQuizeSlicer);
  const { loading: updateQuizesLoading, error: updateQuizesError } =
    useSelector((state) => state.updateQuizeSlicer);

  const [createModle, setCreateModle] = useState(false);
  const [editModle, setEditModle] = useState(false);
  const [formIntilaData, setFormIntilaData] = useState({
    quizeId: "",
    name: "",
    description: "",

    duration: 0,
    number_of_attempts: 0,
  });
  const handleCreateQuize = (value, { resetForm }) => {
    const quizeData = {
      name: value.name,
      description: value.description,
      number_of_attempts: value.number_of_attempts,
      duration: value.duration,
    };
    dispatch(createQuizeApi(quizeData)).then((res) => {
      if (res.payload.message === "Quize created Successfully") {
        swal("Success", res.payload.message, "success");
        resetForm();
        setCreateModle(false);
        getAllQuizesfun();
      } else {
        swal("Info", res.payload.message, "error");
      }
    });
  };

  const handleEditQuize = (itemData) => {
    setFormIntilaData({
      quizeId: itemData._id,
      name: itemData.name,

      description: itemData.description,

      duration: itemData.duration,
      number_of_attempts: itemData.number_of_attempts,
    });
    setEditModle(true);
  };
  const handleUpdateQuizeDataAPI = (value, { resetForm }) => {
    const quizId = value.quizeId;
    const quizeData = {
      name: value.name,

      description: value.description,
      number_of_attempts: value.number_of_attempts,
      duration: value.duration,
    };

    dispatch(updateQuizeApi({ quizeData, quizId })).then((res) => {
      if (res.payload.message === "Quize Updated Successfully") {
        swal("Success", res.payload.message, "success");
        resetForm();
        setEditModle(false);
        getAllQuizesfun();
      } else {
        swal("Info", res.payload.message, "error");
      }
    });
  };

  const [allQuizes, setAllQuizes] = useState([]);

  const getAllQuizesfun = async () => {
    dispatch(getAllQuizesApi());
  };

  const handleDeleteQuize = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this Quize",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteQuizeById(id)).then((res) => {
          if (res.payload.message === "Quize Deleted Successfully") {
            Swal.fire("Deleted!", res.payload.message, "success");
            getAllQuizesfun();
          }
        });
      } else {
        Swal.fire("Info!", "Quize Is Safe", "info");
      }
    });
  };

  useEffect(() => {
    getAllQuizesfun();
  }, []);
  useEffect(() => {
    QuizesData && setAllQuizes(QuizesData);
  }, [QuizesData]);

  return (
    <>
      <div className="widget-heading d-flex justify-content-between align-items-center">
        <h3 className="m-0">All Quizes</h3>
        <button
          onClick={() => setCreateModle(true)}
          className="btn btn-primary btn-sm"
        >
          Add New Quize
        </button>
      </div>

      {QuizesLoading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {allQuizes && allQuizes.length > 0 ? (
            <>
              <div className="row">
                {allQuizes.map((item) => (
                  <CustomCourseCard
                    key={item._id}
                    title={item.name}
                    number_of_attempts={item.number_of_attempts}
                    duration={item.duration}
                    _id={item._id}
                    handleDeleteQuize={handleDeleteQuize}
                    handleEditQuize={handleEditQuize}
                    quizeData={item}
                  />
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="row d-flex text-center gap-2">
                <h3>No Data Added Yet</h3>
              </div>
            </>
          )}
        </>
      )}

      {/* CreateQuize */}
      <Modal
        show={createModle}
        onHide={() => setCreateModle(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Body>
          <Formik
            initialValues={{
              name: "",

              description: "",

              duration: 0,
              number_of_attempts: 0,
            }}
            validationSchema={createQuize}
            onSubmit={handleCreateQuize}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form className="row" onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.name
                        ? errors.name
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Quize Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a  name.."
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <div
                        id="val-name-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.name && errors.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.description
                        ? errors.description
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                      Enter Quize Description
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a  description.."
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                      <div
                        id="val-description-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.description && errors.description}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.duration
                        ? errors.duration
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Quize Duration</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter a duration.."
                        name="duration"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duration}
                      />
                      <div
                        id="val-duration-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.duration && errors.duration}
                      </div>
                    </div>
                  </div>
                  <div
                    className={` col-lg-6 col-sm-12 form-group mb-3 ${
                      values.number_of_attempts
                        ? errors.number_of_attempts
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                      Enter Number Of Attempts
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter number of attempts.."
                        name="number_of_attempts"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.number_of_attempts}
                      />
                      <div
                        id="val-number_of_attempts-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.number_of_attempts && errors.number_of_attempts}
                      </div>
                    </div>
                  </div>
                </div>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setCreateModle(false)}
                  >
                    Close
                  </Button>
                  {createQuizesLoading ? (
                    <>
                      <Button variant="primary" disabled>
                        loading ...
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="primary" type="submit">
                        Create Quize
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Edit Quize */}
      <Modal
        show={editModle}
        onHide={() => setEditModle(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Body>
          <Formik
            initialValues={{
              quizeId: formIntilaData.quizeId,
              name: formIntilaData.name,

              description: formIntilaData.description,

              duration: formIntilaData.duration,
              number_of_attempts: formIntilaData.number_of_attempts,
            }}
            validationSchema={createQuize}
            onSubmit={handleUpdateQuizeDataAPI}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form className="row" onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.name
                        ? errors.name
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Quize Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a  name.."
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <div
                        id="val-name-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.name && errors.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.description
                        ? errors.description
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                      Enter Quize Description
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a  description.."
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                      <div
                        id="val-description-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.description && errors.description}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.duration
                        ? errors.duration
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Quize Duration</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter a duration.."
                        name="duration"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duration}
                      />
                      <div
                        id="val-duration-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.duration && errors.duration}
                      </div>
                    </div>
                  </div>
                  <div
                    className={` col-lg-6 col-sm-12 form-group mb-3 ${
                      values.number_of_attempts
                        ? errors.number_of_attempts
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                      Enter Number Of Attempts
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter number of attempts.."
                        name="number_of_attempts"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.number_of_attempts}
                      />
                      <div
                        id="val-number_of_attempts-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.number_of_attempts && errors.number_of_attempts}
                      </div>
                    </div>
                  </div>
                </div>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setEditModle(false)}
                  >
                    Close
                  </Button>
                  {updateQuizesLoading ? (
                    <>
                      <Button variant="primary" disabled>
                        loading ...
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="primary" type="submit">
                        Update Quize
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default QuizesMain;

function CustomCourseCard({
  title,
  duration,
  number_of_attempts,
  _id,
  handleDeleteQuize,
  handleEditQuize,
  quizeData,
}) {
  return (
    <>
      <div className="col-xl-4 col-md-6">
        <div className="card all-crs-wid">
          <div className=" p-2">
            <Dropdown>
              <Dropdown.Toggle
                as="a"
                className="btn-link i-false btn sharp tp-btn-light btn-dark"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
                    fill="#A098AE"
                  />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-end"
                align="right"
              >
                <Dropdown.Item onClick={() => handleEditQuize(quizeData)}>
                  Edit
                </Dropdown.Item>

                <Dropdown.Item onClick={() => handleDeleteQuize(_id)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="card-body">
            <div className="courses-bx">
              <div className="dlab-media">
                <img src={course1} alt="Avatar Quize Image" />
              </div>
              <div className="dlab-info">
                <div className="dlab-title d-flex justify-content-between">
                  <div>
                    <h4>
                      <Link to={`./quizes-details/${_id}`}>{title}</Link>
                    </h4>
                   
                    <p className="m-0">
                      Quize Duration : {duration}
                      <svg
                        className="ms-1"
                        width="4"
                        height="5"
                        viewBox="0 0 4 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2" cy="2.5" r="2" fill="#DBDBDB" />
                      </svg>
                    </p>
                    <p className="m-0">
                      Number Of Attempts : {number_of_attempts}
                      <svg
                        className="ms-1"
                        width="4"
                        height="5"
                        viewBox="0 0 4 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2" cy="2.5" r="2" fill="#DBDBDB" />
                      </svg>
                    </p>
                  </div>
                  {/* <h4 className="text-primary">
                    <span>$</span>
                    {price / 100}
                  </h4> */}
                </div>
                <div className="d-flex justify-content-center content align-items-center">
                  <Link
                    to={`./quizes-details/${_id}`}
                    className="btn btn-primary btn-sm"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
