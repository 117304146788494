import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../services/LogoutFunction";

export const updateUserApi = createAsyncThunk("updateUseData2", async (userData) => {
  console.log(userData);
;
    const updateUserData = new FormData();
    if (userData.image !== "") {
      updateUserData.append("image", userData.image);
    }
    if (userData.role == "teacher") {
      updateUserData.append("title", userData.title);
    }
    updateUserData.append("name", userData.name);
    updateUserData.append("mobile", userData.mobile);
    updateUserData.append("email", userData.email);
    updateUserData.append("role", userData.role);


    if (userData.extra_info) {
      updateUserData.append("extra_info[name.en]", userData.extra_info.enname);
      updateUserData.append("extra_info[name.ar]", userData.extra_info.arname);
      updateUserData.append("extra_info[title.en]", userData.extra_info.entitle);
      updateUserData.append("extra_info[title.ar]", userData.extra_info.artitle);
      updateUserData.append(
        "extra_info[description.en]",
        userData.extra_info.endescription
      );
      updateUserData.append(
        "extra_info[description.ar]",
        userData.extra_info.ardescription
      );
    }


    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(`${process.env.REACT_APP_VERSEL_API}/api/users/update/${userData.id}`,{
        method: "POST",
       
        headers: {          
          Authorization: `Bearer ${tokenData}`,
        },
        body: updateUserData
    }).then((respons) => respons.json())
    .then((resulet) => {
      console.log(resulet);
      return resulet;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });

  return request;


});
function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}


const UpdateUserDataSlicer = createSlice({
  name: "user/updateallinfo",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers :(builder)=>{
    builder.addCase(updateUserApi.pending,(state)=>{
        state.loading = true;
    });


    builder.addCase(updateUserApi.fulfilled,(state,action)=>{
        state.loading = false;
        if (action.payload.message === "jwt expired") {
          state.error = action.payload.message;
          formatError(state.error);
          LogoutFunction();
        }
        else if (action.payload.message=== " User updated Successfully" ) {
            state.data = action.payload;
            state.error = null;
        }else{
            state.data = null;
            state.error = action.payload.message;
            
        }
    });


    builder.addCase(updateUserApi.rejected,(state,action)=>{
        state.loading = false;
        state.data = null;
        state.error = action.payload.message;
    
        
    });
  }
});

export default UpdateUserDataSlicer.reducer;
