import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../services/LogoutFunction";

export const getFormConactApi = createAsyncThunk(
  "form/contact-get",
  async () => {

    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/contact_join/get_contact_us`,
      {
        method: "GET",
        headers: {
          Authorization:
            `Bearer ${tokenData}`,
        },
      }
    ).then(response => response.json()).then(result => {
       
        return result
    }).catch(error =>{
       
        formatError(error)
        return error;
    })
    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const GetFormConactSlicer = createSlice({
  name: "form/contact-get",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getFormConactApi.pending,state =>{
        state.loading = true;
        state.data = null;
        state.error = null;
    });
    builder.addCase(getFormConactApi.fulfilled,(state,action) =>{
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      }
        else if (action.payload.message === "Data sent successfully") {
            state.data = action.payload;
            state.error = null;
        }
       else{
        
            state.data = null;
            state.error = action.payload.message;
            formatError( state.error);
        }


    });
    builder.addCase(getFormConactApi.rejected,(state,action) =>{
        state.loading = false;
            state.lessons = null;
            state.error = action.payload.message;
            formatError(state.error);

    });
  },
});


export default GetFormConactSlicer.reducer;