import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const updateDemoVideoApi = createAsyncThunk(
  "diplome/update video",
  async (diplomeData) => {
    const token = JSON.parse(localStorage.getItem("userData")).token;
    
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/diplome/update-video/${diplomeData.diplomeId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          video : diplomeData.demo_video,
        }),
      }
    )
      .then((res) => res.json())
      .then((resule) => resule)
      .catch((err) => err);
  }
);

const UploadDiplomeDemoVideoSlicer = createSlice({
  name: "diplome/video",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateDemoVideoApi.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateDemoVideoApi.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });
    builder.addCase(updateDemoVideoApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default UploadDiplomeDemoVideoSlicer.reducer;
