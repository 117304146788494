import { useEffect } from "react";
import AddCourseForm from "./AddCourseForm";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function AddCourse() {
    const {inst_id} = useParams()
    useEffect(()=>{
        window.scroll(0,0)
    })
    return <>

    <AddCourseForm inst_id={inst_id} />

    </>
};
