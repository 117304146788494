
export  function LogoutFunction() {
  localStorage.removeItem("userData");

  window.location.href = "/";

}

export function setTimeToLogout(time) {
setTimeout(LogoutFunction, time);
}

export default function checkAutoLogin() {
  const userData =  localStorage.getItem("userData");
  if (!userData) {
      return false;
  }

  const userDataObj = JSON.parse(userData);

  let expireDate = new Date(userDataObj.expiresInDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    LogoutFunction();
      return false;
  }
console.log('logggggggggggggges');
   const timer = expireDate.getTime() - todaysDate.getTime();
   setTimeToLogout( timer);
   return true;
}
