import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../services/LogoutFunction";
import swal from "sweetalert";

export const getsingleUserAPi = createAsyncThunk("userData", async (userID) => {
 
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(`${process.env.REACT_APP_VERSEL_API}/api/users/user/${userID}`,{
        method:"GET",
        headers:{
            "Authorization": `Bearer ${tokenData}`
        }
  })
    .then((response) => response.json())
    .then((resulte) => {
      console.log(resulte);
      return resulte;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  return request;
});

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const getSingleUserSlicer = createSlice({
  name: "user/getSingle",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getsingleUserAPi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getsingleUserAPi.fulfilled, (state,action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      }
      else if (action.payload.message === "fetched successfully") {
        state.data = action.payload.data;
        state.error = null;
      }else{
        state.data = null;
        state.error = action.payload.message;
      }
    });
    builder.addCase(getsingleUserAPi.rejected, (state,action) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload.message;
    });
  },
});

export default getSingleUserSlicer.reducer;
