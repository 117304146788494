import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import DisplayAllPaymentsTableData from "./DisplayAllPaymentsTableData";

export default function DisplayAllPayments() {
  return (
    <>
      <Fragment>
      <PageTitle
        activeMenu="Courses Transactions"
        motherMenu="payment"
      />

        <DisplayAllPaymentsTableData /> 
      </Fragment>
    </>
  );
}
