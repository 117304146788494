// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./ColorStop.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".cs {\n    height: 17px;\n    position: absolute;\n    width: 11px;\n    cursor: pointer;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") right center;\n}\n\n.cs div {\n    height: 7px;\n    left: 2px;\n    width: 7px;\n    position: absolute;\n    top: 8px;\n}\n\n.active {\n    background-position: left center;\n}", ""]);
// Exports
module.exports = exports;
