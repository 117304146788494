import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const deleteQuizeFormCourseApi = createAsyncThunk(
  "quize/deleteFromCourse",
  async (quizeId) => {
    try {
      const tokenData = await JSON.parse(localStorage.getItem("userData"))
        .token;
      return await fetch(
        `${process.env.REACT_APP_VERSEL_API}/api/users/quize/deleteQuiezFromCourse/${quizeId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData}`,
          },
        }
      ).then((res) => res.json());
    } catch (error) {
      swal("Error", error, "info");
      return error;
    }
  }
);

const deleteQuizeToCourseSlicer = createSlice({
  name: "Delete Quize Form Course",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteQuizeFormCourseApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteQuizeFormCourseApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteQuizeFormCourseApi.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload.message === "Quize Removed From Course Successfully") {
        state.response = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default deleteQuizeToCourseSlicer.reducer;
