import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const deleteQuestionById = createAsyncThunk("qiestion/DeteteById", async (questionId) => {
  try {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/questions/deleteById/${questionId}`,{
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    ).then((res) => res.json());
  } catch (error) {
    swal("Error", error, "info");
    return error;
  }
});

const deleteQuestionByIdSlicer = createSlice({
  name: "Delete Question",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteQuestionById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteQuestionById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteQuestionById.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload.message === "Question Deleted Successfully") {
        state.response = action.payload.message;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default deleteQuestionByIdSlicer.reducer;
