import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import DisplayAllDiplomasSubscriptionsTableData from "./components/DisplayAllDiplomasSubscriptionsTableData";

export default function DisplayAllDiplomasSubscriptions() {
  return (
    <>
      <Fragment>
        <PageTitle
          activeMenu="Tracks Subscriptions"
          motherMenu="Subscriptions"
        />

        <DisplayAllDiplomasSubscriptionsTableData />
      </Fragment>
    </>
  );
}
