import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const updateQuestionApi = createAsyncThunk("quize/create", async (questionData) => {
  try {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/questions/updateById/${questionData.questionId}`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData}`
        },
        body: JSON.stringify(questionData.data)
      }
    ).then((res) => res.json());
  } catch (error) {
    swal("Error", error, "info");
    return error;
  }
});

const updateQuestionByIdSlicer = createSlice({
  name: "update Question",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateQuestionApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateQuestionApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateQuestionApi.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload.message === "Question Updated Successfully") {
        state.response = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default updateQuestionByIdSlicer.reducer;
