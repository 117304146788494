import React, { Fragment, useState } from "react";
import Ellipse from "./Ellipse";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import RightSideBar from "./RightSideBar";
import ChatBox from "../ChatBox";
import TeacherSideBar from "./TeacherSideBar";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  const userData = JSON.parse(localStorage.getItem("userData")).data.role;

  return (
    <Fragment>
      <NavHader />
      <Header
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onClick={() => ClickToAddEvent()}
      />
  {userData === "admin" ?<>
  <SideBar />
  </>:<>
    <TeacherSideBar />
  </>}

    </Fragment>
  );
};

export default JobieNav;

/***
 *  
    //الرسمة الي فيها خطوط في الصفحة الرئيسية 
    <Ellipse />	 
  
    // قايمة من الجنب علشان ابدا الشات 
    <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />

  /الايفنتات الي عاوزها من الهدر بس هم البروفايل والاشعارات
  <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      /> 
       
*/
