import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import swal from "sweetalert";
import { LogedOut } from "./isLogedIn";
import LogoutFunction, { setTimeToLogout } from "../services/LogoutFunction";
import checkAutoLogin from "../services/LogoutFunction";

export const login = createAsyncThunk(
  "callApiLogin",
  async (userCredintionals) => {
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/login`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userCredintionals.email,
          password: userCredintionals.password,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
       
        return result;
      })
      .catch((error) => {
        formatError(error);
      });
    const response = request;
    return response;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
  // switch (errorResponse.message) {
  //     case 'EMAIL_EXISTS':
  //         //return 'Email already exists';
  //         swal("Oops", "Email already exists", "error");
  //         break;
  //     case 'EMAIL_NOT_FOUND':
  //         //return 'Email not found';
  //        swal("Oops", "Email not found", "error",{ button: "Try Again!",});
  //        break;
  //     case 'INVALID_PASSWORD':
  //         //return 'Invalid Password';
  //         swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
  //         break;
  //     case 'USER_DISABLED':
  //         return 'User Disabled';

  //     default:
  //         return;
  // }
}


const userLoginSlicer = createSlice({
  name: "UserLogin",
  initialState: {
    loading: false,
    userData: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
       if (action.payload.message === "loggedIn") {
        state.userData = action.payload;
        state.error = null;
        localStorage.setItem("userData", JSON.stringify(action.payload));
        setTimeToLogout(action.payload.expiresIn);
      } else {
        state.userData = null;
        state.error = action.payload.message;
        formatError(state.error);
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.userData = null;
      state.error = action.payload.message;
      formatError(state.error);
    });
  },
});

export default userLoginSlicer.reducer;
