import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const deleteLessonFileApi = createAsyncThunk(
  "lessons/deleteFiel",
  async (lessonId) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/lessons/deleteFile/${lessonId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });
    return request;
  }
);

const DeleteLessonFileSlicer = createSlice({
  name: "deletelessonsfile",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteLessonFileApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteLessonFileApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "File deleted successfully") {
        state.data = action.payload.message;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(deleteLessonFileApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default DeleteLessonFileSlicer.reducer;
