import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import DisplayAllUserTableData from "./components/DisplayAllUserTableData";

export default function DisplayAllUsers() {
  return (
    <>
      <Fragment>
      <PageTitle
        activeMenu="All Admins"
        motherMenu="Dashord"
      />

        <DisplayAllUserTableData /> 
      </Fragment>
    </>
  );
}
