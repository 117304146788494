import React, { useEffect, useRef, useState } from "react";

import {
  Tab,
  Nav,
  Accordion,
  Spinner,
  Dropdown,
  Alert,
  Carousel,
} from "react-bootstrap";
import "react-modal-video/css/modal-video.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as Yup from "yup";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { deleteLessonsApi } from "../../../store/DeleteLessonSlicer";
import Swal from "sweetalert2";
import { getDiplomeByIdApi } from "../../../store/Slices/diplomes/getDiplomeByIdSlicer";

import diplomaAvatar from "./../../../images/avatar/diplome-avatar.jpg";
import { updateDiplomeApi } from "../../../store/Slices/diplomes/updateDiplomeSlicer";
import { uploadDiplomeImageApi } from "../../../store/Slices/diplomes/UpdateImageDiplomeSlicer";
import { updateDemoVideoApi } from "../../../store/Slices/diplomes/UploadDiplomeDemoVideoSlicer";
import { getAllCourseToDiplomeApi } from "../../../store/Slices/diplomes/getAllCoursesToDiplomeSlicer";
import { addCourseToDiplomeApi } from "../../../store/Slices/diplomes/addCourseToDiplomeSlicer";
import { deleteQuizeFormCourseApi } from "../../../store/Slices/quizes/deleteQuizeToCourseSlicer";
import { removeCourseToDiplomeApi } from "../../../store/Slices/diplomes/removeCourseToDiplomeSlicer";
import { getAll } from "../../../store/GetUsersSlicer";

const updateDiolpme = Yup.object().shape({
  enname: Yup.string()
    .min(6, " name must consist of at least 6 characters ")
    .max(200, " name must consist of at most 200 characters ")
    .required("Please enter a  name"),
  arname: Yup.string()
    .min(6, " name must consist of at least 6 characters ")
    .max(200, " name must consist of at most 200 characters ")
    .required("Please enter a  name"),

  entitle: Yup.string()
    .min(10, "title  must consist of at least 10 characters ")
    .max(500, "title must consist of at most 500 characters ")
    .required("Please enter a course title"),
  artitle: Yup.string()
    .min(10, "title  must consist of at least 10 characters ")
    .max(500, "title must consist of at most 500 characters ")
    .required("Please enter a course title"),

  endescription: Yup.string()
    .min(10, "description  must consist of at least 10 characters ")
    .max(3000, "description must consist of at most 3000 characters ")
    .required("Please enter a course description"),
  ardescription: Yup.string()
    .min(10, "description  must consist of at least 10 characters ")
    .max(3000, "description must consist of at most 3000 characters ")
    .required("Please enter a course description"),

  price: Yup.number().required("Please enter a price"),
  access_duration: Yup.number().required("Please enter access duration"),
  teacherId: Yup.array().required("Please select  teacher"),
});

const AccordionItemCourse = ({ data, deleteCourseFromApi }) => {
  return (
    <>
      <div className="acc-courses my-3">
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-start">
            <span className="acc-icon">
              <img
                style={{
                  height: "100%",
                }}
                src={`${process.env.REACT_APP_VERSEL_API}/${data.image}`}
              />
            </span>
            <h4 className="m-0">{data.name && data.name.en}</h4>
          </div>
          <span>
            <Dropdown>
              <Dropdown.Toggle
                as="a"
                className="btn-link i-false btn sharp tp-btn-light btn-dark"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
                    fill="#A098AE"
                  />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-end"
                align="right"
              >
                <Dropdown.Item onClick={() => deleteCourseFromApi(data._id)}>
                  Remove From Diplome
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
        {data.file && (
          <>
            <div>
              <h5>
                <a
                  className="p-1 text-danger"
                  href={`${process.env.REACT_APP_VERSEL_API}/${data.file.path}`}
                  target="_blank"
                >
                  file name :{data.file.name}
                </a>
              </h5>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const CustomDropdown = ({
  handleShowCourseEditModule,
  handleShowupdateImagesModle,
  handlePublish,
}) => {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          as="a"
          className="btn-link i-false btn sharp tp-btn-light btn-dark"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
              fill="#A098AE"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-end"
          align="right"
        >
          <Dropdown.Item
            onClick={() => {
              handleShowCourseEditModule();
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handleShowupdateImagesModle();
            }}
          >
            Update Images
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handlePublish();
            }}
          >
            Publish
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default function DiplomeDetail() {
  /// Update Lesson Area

  const { _id } = useParams();
  const [teachers, setTeachers] = useState();
  const [discountType, setDiscountType] = useState();
  const [discountPrice, setDiscountPrice] = useState();

  const dispatch = useDispatch();

  const getALlTeachers = async () => {
    dispatch(getAll("teacher")).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        setTeachers(resulet.payload.data);
      }
    });
  };

  //#region Diplome Data
  const {
    loading: getDiplomeByIdLoading,
    data: getDiplomeByIdData,
    error: getDiplomeByIdError,
  } = useSelector((state) => state.getDiplomeByIdSlicer);

  const { data: getAllCourseToDiplomeData } = useSelector(
    (state) => state.getAllCoursesToDiplomeSlicer
  );

  const { loading: updateDiplomeLoading } = useSelector(
    (state) => state.updateDiplomeSlicer
  );

  const { loading: updateDiplomeImageLoading } = useSelector(
    (state) => state.UpdateImageDiplomeSlicer
  );

  const { loading: updateDiplomeVideoLoading } = useSelector(
    (state) => state.UploadDiplomeDemoVideoSlicer
  );

  const { loading: addCourseToDiplomeLoading } = useSelector(
    (state) => state.addCourseToDiplomeSlicer
  );

  const [diplomeData, setDiplomeData] = useState();

  const getDiplome = async () => {
    dispatch(getDiplomeByIdApi(_id)).then((res) => {
      console.log(res.payload);
    });
    console.log("Data ", getDiplomeByIdData);
  };

  //#region Update images

  const [showImageUploadModle, setShowImageUploadModle] = useState(false);

  const [intialValueImageUpload, setIntialValueImageUpload] = useState({
    diplomeId: _id,
    imagefile: "",
    imageName: "",
  });

  const fileUploaderImgCourse = useRef(null);

  const openFileUploaderImgCourse = () => {
    fileUploaderImgCourse.current.click();
  };

  const handleShowupdateImagesModle = () => {
    setIntialValueImageUpload({
      diplomeId: _id,
      imagefile: "",
      imageName: "",
    });
    setShowImageUploadModle(true);
  };

  const handleUploadImageCourse = (e) => {
    e.preventDefault();
    dispatch(uploadDiplomeImageApi(intialValueImageUpload)).then((res) => {
      console.log(res);
      if (res.payload.message === "Data updated successfully") {
        Swal.fire("Updated!", "Course Data Has Been Updated", "success");
        setIntialValueImageUpload({
          diplomeId: _id,
          imagefile: "",
          imageName: "",
        });
        setShowImageUploadModle(false);
        getDiplome();
      } else {
        Swal.fire("Error!", "Some Error Happen", "warning");
      }
    });
  };

  //#endregion

  //#region  Update Diplome
  // --------------------- handle update
  const [diplomeEditModule, setDiplomeEditModule] = useState(false);
  const [diplomeFormIntilization, setDiplomeFormInitialization] = useState({
    enname: "",
    arname: "",
    entitle: "",
    artitle: "",
    endescription: "",
    ardescription: "",
    price: 0,
    access_duration: 0,
    teacherId: [],
  });
  const handleCloseCourseEditModule = () => {
    setDiplomeEditModule(false);
    setDiplomeFormInitialization({
      enname: "",
      arname: "",
      entitle: "",
      artitle: "",
      endescription: "",
      ardescription: "",
      price: 0,
      access_duration: 0,
      teacherId: [],
    });
  };
  const handleShowCourseEditModule = () => {
    const objectIdsForTeachers = diplomeData.teacher_id.map(
      (teacher) => teacher._id
    );
    setDiplomeFormInitialization({
      enname: diplomeData.name.en,
      arname: diplomeData.name.ar,
      entitle: diplomeData.title.en,
      artitle: diplomeData.title.ar,
      endescription: diplomeData.description.en,
      ardescription: diplomeData.description.ar,
      price: diplomeData.price / 100,
      access_duration: diplomeData.access_duration
        ? diplomeData.access_duration
        : 24,
      teacherId: objectIdsForTeachers,
    });
    setDiplomeEditModule(true);
  };

  const handleUpdateCourseData = (value, { resetForm }) => {
    const objectData = {
      diplomeId: _id,
      data: {
        name: {
          en: value.enname,
          ar: value.arname,
        },
        title: {
          en: value.entitle,
          ar: value.artitle,
        },
        description: {
          en: value.endescription,
          ar: value.ardescription,
        },
        price: value.price * 100,
        discount_price : discountPrice < 0 ? 0 : discountPrice,
        discount_type : discountType,
        access_duration: value.access_duration,
        teacher_id: value.teacherId,
      },
    };
    console.log(value);

    dispatch(updateDiplomeApi(objectData)).then((res) => {
      if (res.payload.message === "Data updated successfully") {
        Swal.fire("Updated!", "Diplome Data Has Been Updated", "success");
        handleCloseCourseEditModule();
        getDiplome();
      } else {
        Swal.fire("Error!", res.payload.message, "warning");
      }
    });
  };
  //#endregion

  //#region  Select Course
  const [getAllCourse, setGetAllCourse] = useState([]);
  const [courseSelectedData, setCourseSelectedData] = useState("");
  const [showCourseForm, setShowCourseForm] = useState(false);

  const getAllCourseFun = async () => {
    dispatch(getAllCourseToDiplomeApi());
    console.log(getAllCourseToDiplomeData);
  };

  const handleCourseSelectedSubmite = (e) => {
    e.preventDefault();
    const data = {
      courseId: courseSelectedData,
      diplomeId: _id,
    };
    dispatch(addCourseToDiplomeApi(data)).then((res) => {
      if (res.payload.message === "Data added successfully") {
        Swal.fire("Add!", "Course Add To Diplome Successfully", "success");
        setCourseSelectedData("");
        setShowCourseForm(false);
        getDiplome();
      } else {
        Swal.fire("Info!", res.payload.message, "error");
      }
    });
  };

  //#endregion

  //#region Deno Video

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  };

  const [demoUrl, setDemoUrl] = useState("");
  const [demoUrlErrorMessage, setDemoUrlErrorMessage] = useState("");

  const handleUploadDemoUrl = () => {
    if (demoUrlErrorMessage === "") {
      const diplomeDataVideo = {
        diplomeId: _id,
        demo_video: demoUrl,
      };
      dispatch(updateDemoVideoApi(diplomeDataVideo)).then((res) => {
        if (res.payload.message === "Data updated successfully") {
          Swal.fire("Updated!", "Course Data Has Been Updated", "success");
          setDemoUrlErrorMessage("");
          setDemoUrl("");
          getDiplome();
        } else {
          Swal.fire("Error!", res.payload.message, "error");
        }
      });
    }
  };

  //#endregion

  //#region  Remove course
  const deleteCourseFromApi = (courseId) => {
    const data = {
      courseId: courseId,
      diplomeId: _id,
    };
    dispatch(removeCourseToDiplomeApi(data)).then((res) => {
      if (res.payload.message === "Data removed successfully") {
        Swal.fire(
          "Removed!",
          "Course Removed To Diplome Successfully",
          "success"
        );
        getDiplome();
      } else {
        Swal.fire("Info!", res.payload.message, "error");
      }
    });
  };

  //#endregion

  //#region  change visable

  const cahngeVisability = async () => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/diplome/publish/${_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.message === "Data updated successfully") {
          Swal.fire("Updated!", "Data Has Been Updated", "success");
          getDiplome();
        } else {
          Swal.fire("Info!", result.message, "error");
        }
      })
      .catch((error) => {
        Swal.fire("Info", error.message, "error");
      });
  };

  //#endregion

  useEffect(() => {
    getDiplomeByIdData && setDiplomeData(getDiplomeByIdData.data);
    getDiplomeByIdData 
    && 
    getDiplomeByIdData.data.discount_price
    ?
    setDiscountPrice(getDiplomeByIdData.data.discount_price)
    :
    setDiscountPrice(0);
    getDiplomeByIdData
    && 
    getDiplomeByIdData.data.discount_type
    ?
    setDiscountType(getDiplomeByIdData.data.discount_type)
    :
    setDiscountType("fixed")
  }, [getDiplomeByIdData]);
  useEffect(() => {
    getAllCourseToDiplomeData &&
      setGetAllCourse(getAllCourseToDiplomeData.data);
  }, [getAllCourseToDiplomeData]);

  //#endregion

  useEffect(() => {
    getAllCourseFun();
    getDiplome();
    getALlTeachers();
  }, []);

  useEffect(() => {
    if (!teachers) {
      getALlTeachers();
    }
    return;
  }, [teachers]);

  return (
    <>
      {getDiplomeByIdLoading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {getDiplomeByIdError ? (
            <>{getDiplomeByIdError.message}</>
          ) : (
            <>
              {diplomeData && (
                <>
                  {diplomeData ? (
                    <>
                      <div className="row">
                        <div className="col-xl-6 col-xxl-6">
                          <div className="row">
                            <div className="card">
                              <div className="card-body">
                                <div className="course-content ">
                                  <div className="mb-2">
                                    {diplomeData.visable ? (
                                      <>
                                        <h4
                                          style={{
                                            color: "green",
                                          }}
                                        >
                                          <>Published</>
                                        </h4>
                                      </>
                                    ) : (
                                      <>
                                        <h4
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          <>Not Visable</>
                                        </h4>
                                      </>
                                    )}

                                    <CustomDropdown
                                      handleShowCourseEditModule={
                                        handleShowCourseEditModule
                                      }
                                      handleShowupdateImagesModle={
                                        handleShowupdateImagesModle
                                      }
                                      handlePublish={cahngeVisability}
                                    />
                                  </div>

                                  <div>
                                    <h3>
                                      {diplomeData.name && diplomeData.name.en}
                                    </h3>
                                    <p>
                                      Instructors :<br />
                                      {diplomeData.teacher_id != null &&
                                        diplomeData.teacher_id.length > 0 && (
                                          <>
                                            {diplomeData.teacher_id.map(
                                              (teacher, index) => (
                                                <span key={index}>
                                                  {teacher.name} |{" "}
                                                </span>
                                              )
                                            )}
                                          </>
                                        )}
                                    </p>
                                    {
                                      diplomeData.discount_price && parseInt(diplomeData.discount_price) > 0
                                      ?
                                      <p>Discount : <span className="text-danger">{diplomeData.discount_type === "fixed" ? parseInt(diplomeData.discount_price)/100 : parseInt(diplomeData.discount_price)} {diplomeData.discount_type === "fixed" ? "EG" : "%"}</span> </p>
                                      :
                                      ""
                                    }
                                    {
                                      diplomeData.discount_price && parseInt(diplomeData.discount_price) > 0
                                      ?
                                      <p>Price : <del className="text-danger">{diplomeData.price / 100}$</del> <span>{diplomeData.final_price / 100}$</span></p>
                                      :
                                      <p>Price : {diplomeData.price / 100}$</p>
                                    }
                                    {/* <p>
                                      Price :{" "}
                                      {diplomeData.price &&
                                        diplomeData.price / 100}
                                      $
                                    </p> */}
                                    <p>
                                      Access Duration :{" "}
                                      {diplomeData.access_duration &&
                                        diplomeData.access_duration}{" "}
                                      Month
                                    </p>
                                  </div>
                                </div>
                                <div className="video-img style-1 my-3">
                                  <div className="view-demo">
                                    {diplomeData.image !== "" ? (
                                      <>
                                        <img
                                          src={`${process.env.REACT_APP_VERSEL_API}/${diplomeData.image}`}
                                          className="d-block w-100"
                                          alt={`diplome image`}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={diplomaAvatar}
                                          className="d-block w-100"
                                          alt={`diplome image`}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                                <Tab.Container defaultActiveKey="About">
                                  <div className="course-details-tab style-2 mt-4">
                                    <nav>
                                      <Nav
                                        as="div"
                                        className="nav nav-tabs tab-auto"
                                        id="nav-tab"
                                      >
                                        <Nav.Link
                                          as="button"
                                          className="nav-link"
                                          id="nav-about-tab"
                                          eventKey="About"
                                          type="button"
                                        >
                                          English Data
                                        </Nav.Link>
                                        <Nav.Link
                                          as="button"
                                          className="nav-link"
                                          id="nav-aboutar-tab"
                                          eventKey="Aboutar"
                                          type="button"
                                        >
                                          Arabic Data
                                        </Nav.Link>
                                      </Nav>
                                    </nav>
                                    <Tab.Content
                                      className="tab-content"
                                      id="nav-tabContent"
                                      style={{
                                        textAlign: "justify",
                                      }}
                                    >
                                      <Tab.Pane id="nav-about" eventKey="About">
                                        <div className="about-content">
                                          <h4>Diplome Name</h4>
                                          <p>
                                            {diplomeData.name &&
                                              diplomeData.name.en}
                                          </p>
                                          <h4>title</h4>
                                          <p>
                                            {diplomeData.title &&
                                              diplomeData.title.en}
                                          </p>
                                          <h4>About This Diplome</h4>
                                          <p>
                                            {diplomeData.description &&
                                              diplomeData.description.en}
                                          </p>
                                        </div>
                                      </Tab.Pane>
                                      <Tab.Pane
                                        id="nav-about"
                                        eventKey="Aboutar"
                                        dir="rtl"
                                      >
                                        <div className="about-content">
                                          <h4>اسم الدبلومة</h4>
                                          <p>
                                            {diplomeData.name &&
                                              diplomeData.name.ar}
                                          </p>
                                          <h4>عنوان الدبلومة</h4>
                                          <p>
                                            {diplomeData.title &&
                                              diplomeData.title.ar}
                                          </p>
                                          <h4>وصف الدبلومة</h4>
                                          <p>
                                            {diplomeData.description &&
                                              diplomeData.description.ar}
                                          </p>
                                        </div>
                                      </Tab.Pane>
                                    </Tab.Content>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-xxl-6">
                          <div className="card h-auto">
                            <div className="card-header border-0 pb-0">
                              <h4>Demo Video</h4>
                            </div>

                            <div className="card-body pt-0 d-flex gap-1 row">
                              <div className="col-sm-12">
                                <input
                                  type="url"
                                  className="form-control"
                                  placeholder="youtube link"
                                  onChange={(e) => {
                                    setDemoUrlErrorMessage("");
                                    if (!isValidUrl(e.target.value)) {
                                      setDemoUrlErrorMessage("invalid Link");
                                    }

                                    setDemoUrl(e.target.value);
                                  }}
                                  value={demoUrl}
                                />

                                {updateDiplomeVideoLoading ? (
                                  <>
                                    <button className="btn btn-primary mt-2">
                                      <Spinner
                                        animation="grow"
                                        variant="warning"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-primary mt-2"
                                      onClick={handleUploadDemoUrl}
                                    >
                                      Upload
                                    </button>
                                  </>
                                )}

                                {diplomeData.video &&
                                  diplomeData.video !== "" && (
                                    <>
                                      <a
                                        className="btn btn-info mt-2 mx-3"
                                        href={diplomeData.video}
                                        target="_blank"
                                      >
                                        watch
                                      </a>
                                    </>
                                  )}
                              </div>
                              <div className="col-12">
                                {demoUrlErrorMessage && (
                                  <>
                                    <Alert variant="warning">
                                      {demoUrlErrorMessage}
                                    </Alert>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="card h-auto">
                            <div className="card-header border-0 pb-0">
                              <h4>Add Courses</h4>
                            </div>
                            <div className="card-body pt-0 d-flex gap-1">
                              <button
                                className="btn btn-primary"
                                onClick={() => setShowCourseForm(true)}
                              >
                                Add Course
                              </button>
                            </div>
                          </div>
                          <div className="custome-accordion">
                            <Accordion
                              className="accordion"
                              defaultActiveKey={[`0`]}
                              alwaysOpen
                            >
                              <Accordion.Item className="card" eventKey={"0"}>
                                <Accordion.Header
                                  as="h2"
                                  className="accordion-header border-0"
                                >
                                  <span className="acc-heading">
                                    Courses Diplome
                                  </span>
                                </Accordion.Header>
                                {diplomeData.courses_id.length == 0 ? (
                                  <h3 className="text-center">
                                    No Courses Added yet
                                  </h3>
                                ) : (
                                  <>
                                    <Accordion.Collapse eventKey={"0"}>
                                      <div className="accordion-body card-body pt-0">
                                        {diplomeData.courses_id.map(
                                          (item, index) => (
                                            <AccordionItemCourse
                                              key={index}
                                              data={item}
                                              deleteCourseFromApi={
                                                deleteCourseFromApi
                                              }
                                            />
                                          )
                                        )}
                                      </div>
                                    </Accordion.Collapse>
                                  </>
                                )}
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row text-center">
                        <h1>No Data</h1>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {/* Add Course Selector */}
          <Modal
            show={showCourseForm}
            onHide={() => {
              setCourseSelectedData("");
              setShowCourseForm(false);
            }}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <h3>Add Course To This Diplome</h3>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleCourseSelectedSubmite}>
                <div className="row">
                  <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                    <label className="text-label">Select Course</label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        name="question_type"
                        onChange={(e) => {
                          setCourseSelectedData(e.target.value);
                        }}
                        value={courseSelectedData}
                      >
                        <option defaultValue={""}>Please select</option>
                        {getAllCourseToDiplomeData &&
                          getAllCourse &&
                          getAllCourse.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name.en}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setCourseSelectedData("");
                      setShowCourseForm(false);
                    }}
                  >
                    Close
                  </Button>

                  {addCourseToDiplomeLoading ? (
                    <>
                      <Button variant="primary" disabled>
                        Adding ...
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="primary" type="submit">
                        Add Course
                      </Button>
                    </>
                  )}
                  {/* {updatecourseLoading ? (
                        <>
                          <Button variant="primary" disabled>
                            Updateting ...
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button variant="primary" type="submit">
                            Update Course
                          </Button>
                        </>
                      )} */}
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>
          {/* Update Images */}
          <Modal
            show={showImageUploadModle}
            onHide={() => {
              setShowImageUploadModle(false);
            }}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <h3>Update Images</h3>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleUploadImageCourse}>
                <div className="row">
                  <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                    <label
                      className="form-label"
                      onClick={openFileUploaderImgCourse}
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        padding: "2rem",
                        backgroundColor: "#1f88d9",
                        opacity: "0.5",
                        border: "3px dashed blue",
                        borderRadius: "10px",
                        color: "#111",
                        fontSize: "1rem",
                        textAlign: "center",
                        overflow: "hidden",
                      }}
                    >
                      {intialValueImageUpload.imageName
                        ? intialValueImageUpload.imageName
                        : "Upload New Image Course 480 X 250 typr of jpg , png , jpeg , webp"}
                    </label>
                    <div className="input-group">
                      <input
                        ref={fileUploaderImgCourse}
                        hidden
                        className="form-control form-control-md"
                        type="file"
                        onChange={(e) => {
                          setIntialValueImageUpload({
                            ...intialValueImageUpload,
                            imagefile: e.target.files[0],
                            imageName: e.target.files[0].name,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowImageUploadModle(false)}
                  >
                    Close
                  </Button>
                  {intialValueImageUpload.imagefile !== "" && (
                    <>
                      {updateDiplomeImageLoading ? (
                        <>
                          <Button variant="primary" disabled>
                            Uploading ...
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button variant="primary" type="submit">
                            Upload Image
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>
          {/* Update Diplome Data */}
          <Modal
            show={diplomeEditModule}
            onHide={handleCloseCourseEditModule}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Body>
              <Formik
                initialValues={{
                  enname: diplomeFormIntilization.enname,
                  arname: diplomeFormIntilization.arname,

                  entitle: diplomeFormIntilization.entitle,
                  artitle: diplomeFormIntilization.artitle,

                  endescription: diplomeFormIntilization.endescription,
                  ardescription: diplomeFormIntilization.ardescription,

                  price: diplomeFormIntilization.price,
                  access_duration: diplomeFormIntilization.access_duration
                    ? diplomeFormIntilization.access_duration
                    : 24,
                  teacherId: diplomeFormIntilization.teacherId,
                }}
                validationSchema={updateDiolpme}
                onSubmit={handleUpdateCourseData}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div>
                      <h1>Basic Infromation</h1>
                      <section>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.enname
                                ? errors.enname
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Coures Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a english name.."
                                name="enname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.enname}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.enname && errors.enname}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.arname
                                ? errors.arname
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Coures Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a arabic name.."
                                name="arname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.arname}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.arname && errors.arname}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.entitle
                                ? errors.entitle
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English title
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a english title.."
                                name="entitle"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.entitle}
                              />
                              <div
                                id="val-entitle-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.entitle && errors.entitle}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.artitle
                                ? errors.artitle
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic title
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a arabic title.."
                                name="artitle"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.artitle}
                              />
                              <div
                                id="val-artitle-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.artitle && errors.artitle}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.price
                                ? errors.price
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter New Price* (In Dollar)
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter course price .."
                                name="price"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}
                              />
                              <div
                                id="val-price-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.price && errors.price}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.access_duration
                                ? errors.access_duration
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Access Duration
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter course access duration .."
                                name="access_duration"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.access_duration}
                              />
                              <div
                                id="val-access_duration-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.access_duration &&
                                  errors.access_duration}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 
                        
                            `}
                          >
                            <label className="text-label">
                              Choose discount type*
                            </label>
                            <div className="input-group">
                              <select
                                className="form-control"
                                name="discount-type"
                                onChange={(e) => setDiscountType(e.target.value)}
                                // onBlur={handleBlur}
                                defaultValue={discountType}
                              >
                                <option value={"Choose Discount Type.."} disapled>Choose Discount Type..</option>
                                <option value={"fixed"}>Fixed</option>
                                <option value={"percentage"}>Percentage</option>
                              </select>
                              {/* <div
                                id="val-price-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discountType && errors.discountType}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 
                  
                            `}
                          >
                            <label className="text-label">
                              Enter Discount (optional)*
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Diploma discount .."
                                name="discount"
                                onChange={(e) => setDiscountPrice(e.target.value)}
                                onBlur={handleBlur}
                                value={discountPrice}
                                // min={10}
                              />
                            </div>
                          </div>
                        </div>
                        {/* teacher Data */}
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.teacherId
                                ? errors.teacherId
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">Select Teacher</label>
                            <div className="input-group">
                              <select
                                className="form-control"
                                name="teacherId"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.teacherId}
                                multiple
                              >
                                <option defaultValue={null}>
                                  Please select Teacher
                                </option>
                                {teachers &&
                                  teachers.map((item, index) => (
                                    <option key={index} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                              <div
                                id="val-teacherId-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.teacherId && errors.teacherId}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div>
                      <h1>Descriptions </h1>
                      <section>
                        <div className="row ">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.endescription
                                ? errors.endescription
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Description
                            </label>
                            <div className="input-group">
                              <textarea
                                id="subdescription-textarea"
                                rows={5}
                                className="form-control"
                                placeholder="Sub Description"
                                name="endescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endescription}
                              ></textarea>
                              <div
                                id="val-endescription-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endescription && errors.endescription}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.ardescription
                                ? errors.ardescription
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Description
                            </label>
                            <div className="input-group">
                              <textarea
                                id="subdescription-textarea"
                                rows={5}
                                className="form-control"
                                placeholder="Sub Description"
                                name="ardescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ardescription}
                              ></textarea>
                              <div
                                id="val-ardescription-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.ardescription && errors.ardescription}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCloseCourseEditModule}
                      >
                        Close
                      </Button>
                      {updateDiplomeLoading ? (
                        <>
                          <Button variant="primary" disabled>
                            Updateting ...
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button variant="primary" type="submit">
                            Update Diplome
                          </Button>
                        </>
                      )}
                    </Modal.Footer>
                  </form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}
