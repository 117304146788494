import Swal from "sweetalert2";

export const handleError = (error) => {

 if (error) {
    const ms = error.data && error.data.map((e) => e.msg).join(", ");
    Swal.fire({
      icon: "error",
      title: error.message,
      text: ms,
    });
 } else {
    Swal.fire({
        icon: "error",
        title: "Error Happen",
        text: "Try Again Later",
      }); 
 }



};
