import { Fragment } from "react"
import PageTitle from "../../layouts/PageTitle"
import JQueryValidation from "../Forms/jQueryValidation/jQueryValidation"
import AddUserForm from "./components/AddUserForm"
import AddNewTeacher from "./components/AddNewTeacher"
export default function AddUser() {
    return<>
        <Fragment>
        <PageTitle activeMenu="Add Users" motherMenu="Users" />
        <div className="row">
          <AddNewTeacher />
          {/* <JQueryValidation /> */}
          {/* <AddUserForm /> */}
        </div>
      </Fragment>
    </>
};
