import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../../../services/LogoutFunction";

export const getDiplomaByIdForTeacherApi = createAsyncThunk(
  "teacher/diploma/getById",
  async (diplomaId) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
  const request = await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/teacher/getdplomaById/${diplomaId}`,
    {
      method: "GET",
      headers:{
        "Authorization" : `Bearer ${tokenData}`
      }
    }
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      formatError(error);
    });

  return request;
});

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const getByIdTeacherDiplomaSlicer = createSlice({
  name: "get teacher diploma",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getDiplomaByIdForTeacherApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDiplomaByIdForTeacherApi.fulfilled, (state, action) => {
        state.loading = false;

      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      }
     else  if (action.payload.message === "fetched successfully") {
        state.data = action.payload;
     
      } else {
        state.error = action.payload.message;
        formatError(state.error);
      }
    });
    builder.addCase(getDiplomaByIdForTeacherApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      formatError(state.error);
    });

  },
});

export default getByIdTeacherDiplomaSlicer.reducer;

