import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import DropDownBlog from "./DropDownBlog";
//images
import avatarMain from "./../../../images/avatar/avatar-main.png";
import acheiv from "./../../../images/svg/achievement.svg";
import medal from "./../../../images/medal.png";
import pic3 from "./../../../images/courses/pic3.jpg";
import pic4 from "./../../../images/courses/pic4.jpg";
import pic5 from "./../../../images/courses/pic5.jpg";
import pic6 from "./../../../images/courses/pic6.jpg";
import pic7 from "./../../../images/courses/pic7.jpg";
import pic8 from "./../../../images/courses/pic8.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../../store/GetUsersSlicer";
import { Spinner } from "react-bootstrap";
import { deleteUser } from "../../../store/DeleteStudentSlicer";
import Swal from "sweetalert2";
import AddInstrtactorForm from "./CScomponents/AddInstrtactorForm";

const Instructors = () => {
  const [show, setShow] = useState(false);
  const { loading, data, error } = useSelector((state) => state.getUser);
  const [users, setUsers] = useState([]);
  const [backupData, setBackupData] = useState([]);

  const dispatch = useDispatch();

  const handleHide = (e) => {
    if (e) {
      dispatch(getAll("teacher")).then((resulet) => {
        if (resulet.payload.message === "fetched successfully") {
          console.log(resulet.payload.data);
          setUsers(resulet.payload.data);
        }
      });
    }
    setShow(false);
  };
  const handleDelete = (id) => {
    console.log(id);
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this Student",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUser(id)).then((res) => {
          if (res.payload.message === "deleted successfully") {
            Swal.fire("Deleted!", "User has been deleted", "success");
          } else {
            Swal.fire(
              "Error!",
              "Something bad happened ,try again later!!",
              "info"
            );
          }
          dispatch(getAll("teacher")).then((resulet) => {
            if (resulet.payload.message === "fetched successfully") {
              setUsers(resulet.payload.data);
            }
          });
        });
      }
    });
  };
  const instractorFilter = (e) => {
    if (e.target.value === "") {
      setUsers(backupData);
      return;
    }

    const filterArray = backupData.filter((item) => {
      console.log(item);
      return item.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    console.log(filterArray);
    setUsers(filterArray);
  };
  useEffect(() => {
    dispatch(getAll("teacher")).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        console.log(resulet.payload.data);
        setUsers(resulet.payload.data);
        setBackupData(resulet.payload.data);
      }
    });
    // dispatch(GetUsersSlicer('teacher')).
  }, []);

  return (
    <>
      <AddInstrtactorForm show={show} hideAndReload={handleHide} />
      <div className="row">
        <div className="col-xl-12">
          <div className="card students-list">
            <div className="card-header border-0 flex-wrap ">
              <h4>All Instractors ({backupData.length})</h4>
              <div className="input-group search-area w-auto">
                <span className="input-group-text">
                  <Link to={"#"}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                        fill="var(--primary)"
                      ></path>
                    </svg>
                  </Link>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                  onChange={instractorFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="widget-heading d-flex justify-content-between align-items-center">
          <h3 className="m-0">All Instractors</h3>
          <button onClick={() => setShow(!show)} className="btn btn-primary">
            Add Instractor
          </button>
        </div>
      </div>

      <div className="row">
        {loading ? (
          <>
            <div className="row d-flex justify-content-center gap-2">
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="warning" />
            </div>
          </>
        ) : (
          <>
            {users.length == 0 ? (
              <div className="row d-flex text-center gap-2">
                <h3>No Data Found</h3>
              </div>
            ) : (
              <>
                {users &&
                  users.map((item, index) => (
                    <div className="col-xl-4 col-xxl-6 col-md-6" key={index}>
                      <div className="card instructors-box">
                        {/* <div className="card-header border-0">
                <DropDownBlog />
              </div> */}
                        <div className="card-body text-center pb-3">
                          <div className="instructors-media">
                            {item.image ? (
                              <img
                                src={`${process.env.REACT_APP_VERSEL_API}/${item.image}`}
                                alt={item.name}
                              />
                            ) : (
                              <img src={avatarMain} alt={item.name} />
                            )}

                            <div className="instructors-media-info">
                              <h4>{item.name}</h4>
                              {item.title && <p>{item.title}</p>}

                              {/* <div className="custome-badge">
											<Link to={"#"}><span className="badge badge-xl">Design</span></Link>
											<Link to={"#"}><span className="badge badge-xl">Tech</span></Link>
											<Link to={"#"}><span className="badge badge-xl">Research</span></Link>
										</div> */}
                              <div className="d-flex justify-content-center my-3">
                                {/* <div className="info-box">
                        <span>
                          <img src={medal} alt="" /> Courses
                        </span>
                        <h4>100</h4>
                      </div> */}
                                <div className="info-box">
                                  <span>
                                    <img src={acheiv} alt="" /> Courses
                                  </span>
                                  <h4>{item.numberOfCourses}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer d-flex justify-content-between gap-2 pt-0 border-0">
                          <Link
                            to={`./add-courese/${item._id}`}
                            className="btn btn-primary  btn-block"
                          >
                            Add Course
                          </Link>
                          <Link
                            to={`./instractor-profile/${item._id}`}
                            className="btn btn-secondary  btn-block"
                          >
                            View Profile
                          </Link>
                          <button
                            onClick={() => handleDelete(item._id)}
                            className="btn btn-danger  btn-block"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Instructors;
