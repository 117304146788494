import React, { Fragment, useRef, useState } from "react";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
//** Import Image */
import avatarMain from "./../../../../images/avatar/avatar-main.png";

import PageTitle from "../../../layouts/PageTitle";
import CustomCouseCard from "../../Courses/CustomCouseCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getsingleUserAPi } from "../../../../store/GetSingleUSerSlicer";
import { updateUserApi } from "../../../../store/UpdateUserDataSlicer";
import { updateUserPasswordApi } from "../../../../store/Slices/users/updateUserPasswordSlicer";

const creatInstrtactorSchema = Yup.object().shape({
  enname: Yup.string()
    .min(6, "Your name must consist of at least 6 characters ")
    .max(100, "Your name must consist of at most 100 characters ")
    .required("Please enter a name"),
  arname: Yup.string()
    .min(6, "Your name must consist of at least 6 characters ")
    .max(100, "Your name must consist of at most 100 characters ")
    .required("Please enter a name"),

  entitle: Yup.string()
    .min(6, "Your title must consist of at least 6 characters ")
    .max(200, "Your title must consist of at most 200 characters ")
    .required("Please enter a title"),
  artitle: Yup.string()
    .min(6, "Your title must consist of at least 6 characters ")
    .max(200, "Your title must consist of at most 200 characters ")
    .required("Please enter a title"),

  endescription: Yup.string()
    .min(10, "Your title must consist of at least 10 characters ")
    .max(2000, "Your title must consist of at most 2000 characters ")
    .required("Please enter a description"),
  ardescription: Yup.string()
    .min(10, "Your description must consist of at least 10 characters ")
    .max(2000, "Your description must consist of at most 2000 characters ")
    .required("Please enter a description"),

  email: Yup.string()
    .min(3, "Your email is to short ")
    .email()
    .required("Please enter a email"),

  mobile: Yup.number()
    .moreThan(11, "phone number must be 11 degite")
    .required("Please provide a mobile number"),
});

const updatePassword = Yup.object().shape({
  password: Yup.string()
    .min(8, "Your password must be at least 8 characters")
    .max(200, "Your password must be at most 200 characters")
    .required("Please provide a password"),
});
const InstractorProfile = () => {
  const { user_id } = useParams();
  const { loading, data, error } = useSelector((state) => state.getSingleUser);
  const { loading: updateLoading, error: updateError } = useSelector(
    (state) => state.updateUser
  );
  const [userData, setUserData] = useState();
  const [activeToggle, setActiveToggle] = useState("aboutMe");

  //const { loading } = useSelector((state) => state.addUsers);
  const dispatch = useDispatch();

  const [intialData, setIntialData] = useState({
    enname: "",
    arname: "",
    entitle: "",
    artitle: "",
    endescription: "",
    ardescription: "",
    email: "",
    mobile: "",
    role: "teacher",
  });

  const [imgWallpaper, setImgWallpaper] = useState("");
  const [imageName, setImageName] = useState("");

  const fileUploaderImgWallpaper = useRef(null);
  const openFileUploaderImgWallpaper = () => {
    fileUploaderImgWallpaper.current.click();
  };

  ///////////////////////////////
  //Update Form
  const [showUpdateDataForm, setShowUpdateDataForm] = useState(false);

  const handleCloseUpdateForm = () => {
    setShowUpdateDataForm(false);
    setIntialData({
      enname: "",
      arname: "",
      entitle: "",
      artitle: "",
      endescription: "",
      ardescription: "",
      email: "",
      mobile: "",
      role: "teacher",
    });
  };

  const handleShowUpdateForm = () => {
    setShowUpdateDataForm(true);
    setIntialData({
      enname: userData.name,
      arname: userData.extra_info.name.ar ? userData.extra_info.name.ar : "",
      entitle: userData.title,
      artitle: userData.extra_info.title.ar ? userData.extra_info.title.ar : "",
      endescription: userData.extra_info.description.en
        ? userData.extra_info.description.en
        : "",
      ardescription: userData.extra_info.description.ar
        ? userData.extra_info.description.ar
        : "",
      email: userData.email,
      mobile: userData.mobile,
      role: "teacher",
    });
  };

  const getSingleUserDataFromApi = () => {
    dispatch(getsingleUserAPi(user_id)).then((res) => {
      if (res.payload.message === "fetched successfully") {
        setUserData(res.payload.data);
        setIntialData(res.payload.data);
        console.log(res.payload);
      }
    });
  };

  const handleFormSubmite = (value, { resetForm }) => {
    console.log(value);

    var formData = {
      id: user_id,
      name: value.enname,
      title: value.entitle,
      mobile: value.mobile,
      email: value.email,
      password: value.password,
      image: imgWallpaper,
      role: "teacher",
      extra_info: {
        enname: value.enname,
        arname: value.arname,
        entitle: value.entitle,
        artitle: value.artitle,
        endescription: value.endescription,
        ardescription: value.ardescription,
      },
    };

    dispatch(updateUserApi(formData)).then((result) => {
      if (result.payload.message === " User updated Successfully") {
        swal("Good job!", result.payload.message, "success");
        resetForm();
        handleCloseUpdateForm();
        getSingleUserDataFromApi();
      }
    });
  };

  //#region Update Password
  const { loading: updateUserPasswordLoading } = useSelector(
    (state) => state.updateUserPasswordSlicer
  );

  const [updateUserPasswordFormModule, setUpdateUserPasswordFormModule] =
    useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleUpdatePasswordForm = (value, { resetForm }) => {
    console.log(value);

    const objectData = {
      userId: user_id,
      newPassword: value.password,
    };

    dispatch(updateUserPasswordApi(objectData)).then((res) => {
      if (res.payload.message === "Password updated Successfully") {
        setUpdateUserPasswordFormModule(false);
        resetForm();
        swal("!Succedd", res.payload.message, "success");
      }
    });
  };

  //#endregion

  useEffect(() => {
    getSingleUserDataFromApi();
  }, []);
  useEffect(() => {
    if (userData == null) {
      getSingleUserDataFromApi();
    }
    return;
  }, [userData]);

  const userRole = JSON.parse(localStorage.getItem("userData")).data.role;

  return (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="App" />

      {loading ? (
        <div className="row d-flex justify-content-center gap-2">
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="warning" />
        </div>
      ) : (
        <>
          {error ? (
            <h1>{error}</h1>
          ) : (
            <>
              {userData ? (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                          {/* <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div> */}
                          <div className="profile-info">
                            <div
                              className="profile-photo"
                              style={{ marginTop: 0 }}
                            >
                              {userData.image ? (
                                <img
                                  src={`${process.env.REACT_APP_VERSEL_API}/${userData.image}`}
                                  className="img-fluid rounded-circle"
                                  alt={userData.name}
                                />
                              ) : (
                                <img
                                  src={avatarMain}
                                  className="img-fluid rounded-circle"
                                  alt={userData.name}
                                />
                              )}
                            </div>
                            <div className="profile-details">
                              <div className="profile-name px-3 pt-2">
                                <h4 className="text-primary mb-0">
                                  {userData.name}
                                </h4>
                                <p>{userData.role}</p>
                              </div>
                              <div className="profile-email px-2 pt-2">
                                <h4 className="text-muted mb-0">
                                  {userData.email}
                                </h4>
                                <p>Email</p>
                              </div>
                              {userRole === "admin" && (
                                <>
                                  <Dropdown className="dropdown ms-auto">
                                    <Dropdown.Toggle
                                      variant="primary"
                                      className="btn btn-primary light sharp i-false"
                                      data-toggle="dropdown"
                                      aria-expanded="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth="1"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <circle
                                            fill="#000000"
                                            cx="5"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="12"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="19"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                        </g>
                                      </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={handleShowUpdateForm}
                                      >
                                        <i className="fa fa-user-circle text-primary me-2" />
                                        Edite Profile
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={() =>
                                          setUpdateUserPasswordFormModule(true)
                                        }
                                      >
                                        <i className="fa fa-ban text-primary me-2" />
                                        Update Password
                                      </Dropdown.Item>
                                      {/* <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-users text-primary me-2" />
                        Add to close friends
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-plus text-primary me-2" />
                        Add to group
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-ban text-primary me-2" />
                        Block
                      </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="profile-tab">
                            <div className="custom-tab-1">
                              <div className="tab-content">
                                <div
                                  id="about-me"
                                  className={`tab-pane fade ${
                                    activeToggle === "aboutMe"
                                      ? "active show"
                                      : ""
                                  }`}
                                >
                                  <div className="profile-personal-info">
                                    <h4 className="text-primary mb-4">
                                      Personal Information
                                    </h4>
                                    {userData.name && (
                                      <>
                                        <div className="row mb-2">
                                          <div className="col-3">
                                            <h5 className="f-w-500">
                                              English Name
                                              <span className="pull-right">
                                                :
                                              </span>
                                            </h5>
                                          </div>
                                          <div className="col-9">
                                            <span> {userData.name}</span>
                                          </div>
                                        </div>
                                        {userData.extra_info &&
                                          userData.extra_info.name &&
                                          userData.extra_info.name.ar && (
                                            <>
                                              <div className="row mb-2">
                                                <div className="col-3">
                                                  <h5 className="f-w-500">
                                                    Arabic Name
                                                    <span className="pull-right">
                                                      :
                                                    </span>
                                                  </h5>
                                                </div>
                                                <div className="col-9">
                                                  <span>
                                                    {" "}
                                                    {
                                                      userData.extra_info.name
                                                        .ar
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                      </>
                                    )}

                                    {userData.title && (
                                      <>
                                        <div className="row mb-2">
                                          <div className="col-3">
                                            <h5 className="f-w-500">
                                              English Title
                                              <span className="pull-right">
                                                :
                                              </span>
                                            </h5>
                                          </div>
                                          <div className="col-9">
                                            <span> {userData.title}</span>
                                          </div>
                                        </div>
                                        {userData.extra_info &&
                                          userData.extra_info.title &&
                                          userData.extra_info.title.ar && (
                                            <>
                                              <div className="row mb-2">
                                                <div className="col-3">
                                                  <h5 className="f-w-500">
                                                    Arabic title
                                                    <span className="pull-right">
                                                      :
                                                    </span>
                                                  </h5>
                                                </div>
                                                <div className="col-9">
                                                  <span>
                                                    {" "}
                                                    {
                                                      userData.extra_info.title
                                                        .ar
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                      </>
                                    )}
                                    {userData.extra_info && (
                                      <>
                                        {userData.extra_info &&
                                          userData.extra_info.description &&
                                          userData.extra_info.description
                                            .en && (
                                            <>
                                              <div className="row mb-2">
                                                <div className="col-3">
                                                  <h5 className="f-w-500">
                                                    English Description
                                                    <span className="pull-right">
                                                      :
                                                    </span>
                                                  </h5>
                                                </div>
                                                <div className="col-9">
                                                  <span>
                                                    {" "}
                                                    {
                                                      userData.extra_info
                                                        .description.en
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        {userData.extra_info &&
                                          userData.extra_info.description &&
                                          userData.extra_info.description
                                            .ar && (
                                            <>
                                              <div className="row mb-2">
                                                <div className="col-3">
                                                  <h5 className="f-w-500">
                                                    Arabic Description
                                                    <span className="pull-right">
                                                      :
                                                    </span>
                                                  </h5>
                                                </div>
                                                <div className="col-9">
                                                  <span>
                                                    {" "}
                                                    {
                                                      userData.extra_info
                                                        .description.ar
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                      </>
                                    )}
                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          Email
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.email}</span>
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          Phone
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.mobile}</span>
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-3">
                                        <h5 className="f-w-500">
                                          {" "}
                                          role
                                          <span className="pull-right">:</span>
                                        </h5>
                                      </div>
                                      <div className="col-9">
                                        <span> {userData.role}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="row">
                        <h1 className="card-title">Courses</h1>
                        {userData.courses.length > 0 ? (
                          <>
                            {userData.courses.map((item, index) => {
                              return (
                                <>
                                  <CustomCouseCard
                                    title={item.name.en}
                                    subtitle={userData.name}
                                    price={item.price}
                                    _id={item._id}
                                    image={item.image}
                                  />
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <div className="col-12">
                              <h1> No Course</h1>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <Modal
                    show={showUpdateDataForm}
                    onHide={handleCloseUpdateForm}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Body>
                      <form className="form-valide" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-username"
                              >
                                Full Name
                                <span className="text-danger">*</span>
                              </label>
                              <div>
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  id="val-username"
                                  name="val-username"
                                  placeholder="Enter a full name.."
                                  onChange={(e) => {
                                    if (
                                      e.target.value.length < 3 ||
                                      e.target.value.length > 50
                                    ) {
                                      setFullNameErrorMessage(
                                        "Min 3 Characters Max 50 Characters"
                                      );
                                      setFullName(e.target.value);
                                    } else {
                                      setFullNameErrorMessage("");
                                      setFullName(e.target.value);
                                    }
                                  }}
                                  value={fullName}
                                />
                                {fullNameErrorMessage && (
                                  <Alert
                                    variant="warning"
                                    className="alert-dismissible fade mt-1 mb-0"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="me-2"
                                    >
                                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </svg>
                                    <strong>Warrning </strong>
                                    {fullNameErrorMessage}
                                  </Alert>
                                )}
                              </div>
                            </div>
                            {userData.role == "teacher" && (
                              <>
                                <div className="form-group mb-3 row">
                                  <label
                                    className="col-lg-4 col-form-label"
                                    htmlFor="val-title"
                                  >
                                    Teacher Title
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <input
                                      required
                                      type="text"
                                      className="form-control"
                                      id="val-title"
                                      name="val-title"
                                      placeholder="Enter a full name.."
                                      onChange={(e) => {
                                        if (
                                          e.target.value.length < 3 ||
                                          e.target.value.length > 50
                                        ) {
                                          setTitleErrorMessage(
                                            "Min 3 Characters Max 50 Characters"
                                          );
                                          setTitle(e.target.value);
                                        } else {
                                          setTitleErrorMessage("");
                                          setTitle(e.target.value);
                                        }
                                      }}
                                      value={title}
                                    />
                                    {titleErrorMessage && (
                                      <Alert
                                        variant="warning"
                                        className="alert-dismissible fade mt-1 mb-0"
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          fill="none"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="me-2"
                                        >
                                          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                          <line
                                            x1="15"
                                            y1="9"
                                            x2="9"
                                            y2="15"
                                          ></line>
                                          <line
                                            x1="9"
                                            y1="9"
                                            x2="15"
                                            y2="15"
                                          ></line>
                                        </svg>
                                        <strong>Warrning </strong>
                                        {titleErrorMessage}
                                      </Alert>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-email"
                              >
                                Email <span className="text-danger">*</span>
                              </label>
                              <div>
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  id="val-email"
                                  name="val-email"
                                  placeholder="Your valid email.."
                                  onChange={(e) => {
                                    if (e.target.value.length < 10) {
                                      setEmailErrorMessage(
                                        "invaild email min 10 characters"
                                      );
                                      setEmail(e.target.value);
                                    } else {
                                      setEmailErrorMessage("");
                                      setEmail(e.target.value);
                                    }
                                  }}
                                  value={email}
                                />
                                {emailErrorMessage && (
                                  <Alert
                                    variant="warning"
                                    className="alert-dismissible fade mt-1 mb-0"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="me-2"
                                    >
                                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </svg>
                                    <strong>Warrning </strong>
                                    {emailErrorMessage}
                                  </Alert>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-email"
                              >
                                Upload Image{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div>
                                <div className="custom-upload-file form-group mb-3">
                                  <label
                                    htmlFor="img-wallpaper"
                                    className="form-label"
                                    onClick={() => openFileUploaderImgWallpaper}
                                  >
                                    <i className="fa-solid fa-upload p-2"></i>
                                    {imgWallpaper
                                      ? imgWallpaper
                                      : "Upload Image Wallpaper  700 X 450"}
                                  </label>
                                  <input
                                    ref={fileUploaderImgWallpaper}
                                    hidden
                                    className="form-control form-control-md"
                                    type="file"
                                    id="img-wallpaper"
                                    onChange={(e) => {
                                      setImgWallpaperFile(e.target.files[0]);
                                      setImgWallpaper("");
                                      setImgWallpaper(e.target.files[0].name);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group mb-3 row">
                              <label
                                className="col-lg-4 col-form-label"
                                htmlFor="val-phoneus"
                              >
                                Phone (US)
                                <span className="text-danger">*</span>
                              </label>
                              <div>
                                <input
                                  maxLength={11}
                                  type="text"
                                  className="form-control"
                                  id="val-phoneus"
                                  name="val-phoneus"
                                  placeholder="212-999-0000"
                                  onChange={(e) => {
                                    if (e.target.value[0] !== "0") {
                                      setPhoneErrorMessage(
                                        "Should Start With 0"
                                      );
                                    } else if (e.target.value[1] !== "1") {
                                      setPhoneErrorMessage(
                                        "Should Start With 01"
                                      );
                                    } else if (e.target.value.length < 11) {
                                      setPhoneErrorMessage(
                                        "Not Valid Egyption Number 01xxxxxxxxx"
                                      );
                                    } else {
                                      setPhoneErrorMessage("");
                                    }
                                    setPhone(e.target.value);
                                  }}
                                  value={phone}
                                />
                                {phoneErrorMessage && (
                                  <Alert
                                    variant="warning"
                                    className="alert-dismissible fade mt-1 mb-0"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="me-2"
                                    >
                                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </svg>
                                    <strong>Warrning </strong>
                                    {phoneErrorMessage}
                                  </Alert>
                                )}
                              </div>
                            </div>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleCloseUpdateForm}
                              >
                                Close
                              </Button>
                              {updateLoading ? (
                                <>
                                  <Button variant="primary" disabled>
                                    Loding....
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button variant="primary" type="submit">
                                    Update User
                                  </Button>
                                </>
                              )}
                            </Modal.Footer>
                          </div>
                        </div>
                      </form>
                    </Modal.Body>
                  </Modal> */}
                  <Modal
                    size="lg"
                    show={showUpdateDataForm}
                    onHide={handleCloseUpdateForm}
                    backdrop="static"
                    keyboard={false}
                    // keyboard={false}
                  >
                    <Modal.Body>
                      <h4 className="card-title">Update Instractor Form</h4>
                      <div className="basic-form">
                        <Formik
                          initialValues={{
                            enname: intialData.enname,
                            arname: intialData.arname,
                            entitle: intialData.entitle,
                            artitle: intialData.artitle,
                            endescription: intialData.endescription,
                            ardescription: intialData.ardescription,
                            email: intialData.email,
                            mobile: intialData.mobile,
                          }}
                          validationSchema={creatInstrtactorSchema}
                          onSubmit={handleFormSubmite}
                        >
                          {({
                            values,
                            errors,
                            setFieldValue,
                            setValues,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                {/* Name  */}
                                <div
                                  className={`col-6 form-group mb-3 ${
                                    values.enname
                                      ? errors.enname
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Enter English Name
                                  </label>
                                  <div className="input-group">
                                    <span className="input-group-text">
                                      <i className="fa fa-user" />
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter a name.."
                                      name="enname"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.enname}
                                    />
                                    <div
                                      id="val-enname-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.enname && errors.enname}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`col-6 form-group mb-3 ${
                                    values.arname
                                      ? errors.arname
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Enter Arabic Name
                                  </label>
                                  <div className="input-group">
                                    <span className="input-group-text">
                                      <i className="fa fa-user" />
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter a name.."
                                      name="arname"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.arname}
                                    />
                                    <div
                                      id="val-arname-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.arname && errors.arname}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                {/* Title  */}
                                <div
                                  className={`col-6 form-group mb-3 ${
                                    values.entitle
                                      ? errors.entitle
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Enter English Title
                                  </label>
                                  <div className="input-group">
                                    <span className="input-group-text">
                                      <i class="fa-brands fa-cuttlefish"></i>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter a Title.."
                                      name="entitle"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.entitle}
                                    />
                                    <div
                                      id="val-entitle-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.entitle && errors.entitle}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`col-6 form-group mb-3 ${
                                    values.artitle
                                      ? errors.artitle
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Enter Arabic Title
                                  </label>
                                  <div className="input-group">
                                    <span className="input-group-text">
                                      <i class="fa-brands fa-cuttlefish"></i>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter a Title.."
                                      name="artitle"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.artitle}
                                    />
                                    <div
                                      id="val-artitle-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.artitle && errors.artitle}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                {/* Email  */}
                                <div
                                  className={`col-12 form-group mb-3 ${
                                    values.email
                                      ? errors.email
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">email</label>
                                  <div className="input-group">
                                    <span className="input-group-text">
                                      <i class="fa-solid fa-envelope"></i>
                                    </span>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter a email.."
                                      name="email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                    />
                                    <div
                                      id="val-email-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.email && errors.email}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                {/* Mobile & Image  */}
                                <div
                                  className={`col-12 form-group mb-3 ${
                                    values.mobile
                                      ? errors.mobile
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">mobile</label>
                                  <div className="input-group">
                                    <span className="input-group-text">
                                      <i class="fa-solid fa-mobile"></i>
                                    </span>
                                    <input
                                      type="tel"
                                      maxLength={11}
                                      className="form-control"
                                      placeholder="Enter a mobile.."
                                      name="mobile"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.mobile}
                                    />
                                    <div
                                      id="val-mobile-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.mobile && errors.mobile}
                                    </div>
                                  </div>
                                </div>
                                <div className={`col-12 form-group mb-3`}>
                                  <label className="text-label">
                                    Upload Image{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="input-group">
                                    <div className="w-100 custom-upload-file form-group mb-3">
                                      <label
                                        htmlFor="img-wallpaper"
                                        className="form-label"
                                        onClick={() =>
                                          openFileUploaderImgWallpaper
                                        }
                                      >
                                        <i className="fa-solid fa-upload p-2"></i>
                                        {imgWallpaper
                                          ? imageName
                                          : "Upload Image Wallpaper  700 X 450"}
                                      </label>
                                      <input
                                        ref={fileUploaderImgWallpaper}
                                        hidden
                                        className="form-control form-control-md"
                                        type="file"
                                        id="img-wallpaper"
                                        accept="image/*"
                                        onChange={(e) => {
                                          setImgWallpaper("");
                                          setImageName("");

                                          if (
                                            e.target.files[0].type.split(
                                              "/"
                                            )[0] === "image"
                                          ) {
                                            setImgWallpaper(e.target.files[0]);
                                            setImageName(
                                              e.target.files[0].name
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                {/* Description  */}
                                <div
                                  className={`col-12 form-group mb-3 ${
                                    values.endescription
                                      ? errors.endescription
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Enter English Description
                                  </label>
                                  <div className="input-group">
                                    <textarea
                                      rows={5}
                                      style={{ resize: "none" }}
                                      className="form-control"
                                      placeholder="Enter a description.."
                                      name="endescription"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.endescription}
                                    ></textarea>
                                    <div
                                      id="val-endescription-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.endescription &&
                                        errors.endescription}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`col-12 form-group mb-3 ${
                                    values.ardescription
                                      ? errors.ardescription
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Enter Arabic Description
                                  </label>
                                  <div className="input-group">
                                    <textarea
                                      rows={5}
                                      style={{ resize: "none" }}
                                      className="form-control"
                                      placeholder="Enter a description.."
                                      name="ardescription"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.ardescription}
                                    ></textarea>
                                    <div
                                      id="val-ardescription-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.ardescription &&
                                        errors.ardescription}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {updateLoading ? (
                                <>
                                  <button
                                    className="btn me-2 btn-primary"
                                    disabled
                                  >
                                    <Spinner
                                      variant="primary"
                                      animation="border"
                                    />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="submit"
                                    className="btn me-2 btn-primary"
                                  >
                                    Update Instractor
                                  </button>
                                </>
                              )}

                              <button
                                className="btn me-2 btn-danger"
                                onClick={handleCloseUpdateForm}
                              >
                                Close
                              </button>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <Modal className="fade" show={updateUserPasswordFormModule}>
                    <Modal.Header>
                      <Modal.Title>Update Password</Modal.Title>
                      <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setUpdateUserPasswordFormModule(false)}
                      ></Button>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="basic-form">
                        <Formik
                          initialValues={{
                            password: "",
                          }}
                          validationSchema={updatePassword}
                          onSubmit={handleUpdatePasswordForm}
                        >
                          {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div
                                  className={`col-12 form-group mb-3 ${
                                    values.password
                                      ? errors.password
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Password *
                                  </label>
                                  <div className="input-group transparent-append mb-2">
                                    <span className="input-group-text">
                                      {" "}
                                      <i className="fa fa-lock" />{" "}
                                    </span>

                                    <input
                                      type={`${
                                        showPassword ? "text" : "password"
                                      }`}
                                      className="form-control"
                                      id="val-password1"
                                      name="password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                      placeholder="Choose a safe one.."
                                    />

                                    <div
                                      className="input-group-text "
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                    >
                                      {" "}
                                      {showPassword === false ? (
                                        <i className="fa fa-eye-slash" />
                                      ) : (
                                        <i className="fa fa-eye" />
                                      )}
                                    </div>
                                    <div
                                      id="val-username1-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.password && errors.password}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {updateUserPasswordLoading ? (
                                <>
                                  <button
                                    className="btn me-2 btn-primary"
                                    disabled
                                  >
                                    <Spinner
                                      animation="grow"
                                      variant="warning"
                                    />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="submit"
                                    className="btn me-2 btn-primary"
                                  >
                                    Submit
                                  </button>
                                </>
                              )}
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  setUpdateUserPasswordFormModule(false)
                                }
                              >
                                Close
                              </Button>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </Modal.Body>
                  </Modal>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <h1>No data</h1>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </Fragment>
  );
};

export default InstractorProfile;
