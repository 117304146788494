import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import { Tab, Nav, Accordion, Spinner, Dropdown, Alert } from "react-bootstrap";
import "react-modal-video/css/modal-video.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as Yup from "yup";
//Images

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { FetchCoursesByID } from "../../../store/SingleCourseSlicer";
import swal from "sweetalert";
import { set } from "date-fns/esm";
import { addLessnApi, tdrate } from "../../../store/AddessonSlicer";
import { getLessonsApi } from "../../../store/GetAllLessonSlicer";
import { title } from "process";
import { deleteLessonsApi } from "../../../store/DeleteLessonSlicer";
import Swal from "sweetalert2";

//import StudentApexLineChart from './Instructor/StudentApexLineChart';
//import UserApexLineChart from './Instructor/UserApexLineChart';
//import StudentsActivityApex from './Instructor/StudentsActivityApex';

import pic3 from "./../../../images/courses/pic3.jpg";
import pic2 from "./../../../images/courses/pic2.jpg";
import pic4 from "./../../../images/courses/pic4.jpg";
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";
import ReactPlayer from "react-player";
import { updateCourseApi } from "../../../store/UpdateCourseDataSlicer";
import axios from "axios";
import { getLessonByID } from "../../../store/GetSingleLessonDataSlicer";
import { deleteLessonFileApi } from "../../../store/Slices/lessons/DeleteLessonFileSlicer";
import { uploadDemoVideoApi } from "../../../store/Slices/courses/UploadDemoVideoSlicer";
import { getCourseById } from "../../../store/Slices/teacher/getByIdTeacherCourseSlicer";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import 'video-react/dist/video-react.css';

const LessonCard = ({ type, lessonTitile, count }) => {
  return (
    <>
      <div className="acc-courses my-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="acc-icon">
              {type === "lesson" ? (
                <>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 13C3.817 13 3.635 12.95 3.474 12.851C3.32918 12.7611 3.20965 12.6358 3.12671 12.4869C3.04378 12.338 3.00016 12.1704 3 12V4C3 3.653 3.18 3.331 3.474 3.149C3.61914 3.05976 3.7846 3.00891 3.95481 3.00121C4.12502 2.99351 4.29439 3.02923 4.447 3.105L12.447 7.105C12.6131 7.1882 12.7528 7.31599 12.8504 7.47405C12.948 7.63212 12.9997 7.81423 12.9997 8C12.9997 8.18578 12.948 8.36789 12.8504 8.52595C12.7528 8.68402 12.6131 8.8118 12.447 8.895L4.447 12.895C4.307 12.965 4.152 13 4 13Z"
                      fill="var(--primary)"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    fill="#000000"
                    width="16px"
                    height="16px"
                    viewBox="0 0 32 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16.001-7.163 16.001-16s-7.163-16-16.001-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14.001 6.28 14.001 14-6.281 14.032-14.001 14.032zM14.53 25.015h2.516v-2.539h-2.516zM15.97 6.985c-1.465 0-2.672 0.395-3.62 1.184s-1.409 2.37-1.386 3.68l0.037 0.073h2.295c0-0.781 0.261-1.904 0.781-2.308s1.152-0.604 1.893-0.604c0.854 0 1.511 0.232 1.971 0.696s0.689 1.127 0.689 1.989c0 0.725-0.17 1.343-0.512 1.855-0.343 0.512-0.916 1.245-1.721 2.198-0.831 0.749-1.344 1.351-1.538 1.806s-0.297 1.274-0.305 2.454h2.405c0-0.74 0.047-1.285 0.14-1.636s0.36-0.744 0.799-1.184c0.945-0.911 1.703-1.802 2.277-2.674 0.573-0.87 0.86-1.831 0.86-2.881 0-1.465-0.443-2.607-1.331-3.424s-2.134-1.226-3.736-1.226z"></path>
                  </svg>
                </>
              )}
            </span>
            <h4 className="m-0">{lessonTitile}</h4>
          </div>
          <span>
            {count} {type === "lesson" ? "min" : "quetions"}
          </span>
        </div>
      </div>
    </>
  );
};
const AccordionItemLesson = ({ data, displayLessonVideo }) => {
  return (
    <>
      <div className="acc-courses my-3">
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-start">
            <span
              className="acc-icon"
              onClick={() => displayLessonVideo(data.video)}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13C3.817 13 3.635 12.95 3.474 12.851C3.32918 12.7611 3.20965 12.6358 3.12671 12.4869C3.04378 12.338 3.00016 12.1704 3 12V4C3 3.653 3.18 3.331 3.474 3.149C3.61914 3.05976 3.7846 3.00891 3.95481 3.00121C4.12502 2.99351 4.29439 3.02923 4.447 3.105L12.447 7.105C12.6131 7.1882 12.7528 7.31599 12.8504 7.47405C12.948 7.63212 12.9997 7.81423 12.9997 8C12.9997 8.18578 12.948 8.36789 12.8504 8.52595C12.7528 8.68402 12.6131 8.8118 12.447 8.895L4.447 12.895C4.307 12.965 4.152 13 4 13Z"
                  fill="var(--primary)"
                />
              </svg>
            </span>
            <h4 className="m-0">
              {data.name && data.name.ar && data.name.en}
              <br />
              {data.name && data.name.en && data.name.ar}
              {/* {data.name.en && data.name.en} */}
              <br />
              {data.duration && (
                <>
                  <span className=" mx-3 badge bg-success">
                    {data.duration} min
                  </span>
                </>
              )}
            </h4>
          </div>
          <span></span>
        </div>
        {data.file && (
          <>
            <div>
              <h5>
                <a
                  className="p-1 text-danger"
                  href={`${process.env.REACT_APP_VERSEL_API}/${data.file.path}`}
                  target="_blank"
                >
                  file name :{data.file.name}
                </a>
              </h5>
            </div>
          </>
        )}
      </div>
    </>
  );
};
const ListLessons = ({
  eventKey,
  sectionDisplay,
  AllLessonArray,
  uploadFileForm,
  deleteLessonFile,
  deleteLessonFromApi,
  displayLessonVideo,
  handleShowUpdateLessonMedule,
}) => {
  const [lessonSection, setLessonSection] = useState();

  const setAllLessons = () => {
    const LessonData = AllLessonArray.filter(
      (item) => item.section == sectionDisplay
    );
    setLessonSection(LessonData);
  };

  useEffect(() => {
    setAllLessons();
  }, []);

  return (
    <>
      {lessonSection && lessonSection.length != 0 && (
        <>
          <Accordion.Item className="card" eventKey={eventKey}>
            <Accordion.Header as="h2" className="accordion-header border-0">
              <span className="acc-heading">Section {eventKey + 1}</span>
            </Accordion.Header>
            <Accordion.Collapse eventKey={eventKey}>
              <div className="accordion-body card-body pt-0">
                {lessonSection.map((lesson, index) => (
                  <AccordionItemLesson
                    key={`${lesson._id}-${index}`}
                    data={lesson}
                    uploadFileForm={uploadFileForm}
                    deleteLessonFile={deleteLessonFile}
                    deleteLessonFromApi={deleteLessonFromApi}
                    displayLessonVideo={() => displayLessonVideo(lesson._id)}
                    updateLessonFrom={handleShowUpdateLessonMedule}
                  />
                ))}
              </div>
            </Accordion.Collapse>
          </Accordion.Item>
        </>
      )}
    </>
  );
};

const TeacherCourseDetail = () => {
  /// Update Lesson Area

  const { _id } = useParams();

  const dispatch = useDispatch();
  const { loading, error } = useSelector(
    (state) => state.getByIdTeacherCourseSlicer
  );

  //#region

  // Show Videos Model
  const [videoUrl, setVideoUrl] = useState(null);
  const [showVideoLesson, setShowVideoLesson] = useState(false);

  // handle cloase module
  const handleCloseVideoLesson = () => {
    setVideoUrl(null);
    setShowVideoLesson(false);
  };

  // handle Show Lesson
  const displayLessonVideo = (lessonId) => {
    setVideoUrl(
      `${process.env.REACT_APP_VERSEL_API}/dashboard/videos/${lessonId}`
    );
    setShowVideoLesson(true);
  };

  //#endregion

  const [courseData, setCourseData] = useState([]);
  const [courseLessons, setCourseLessons] = useState([]);
  const [courseStudnet, setCourseStudnet] = useState([]);
  const [studentData, setStudentData] = useState([]);

  const getCoures = () => {
    dispatch(getCourseById(_id)).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        setCourseData(resulet.payload.data.course);
        setCourseLessons(resulet.payload.data.lessons);
        setCourseStudnet(resulet.payload.data.students);
        setStudentData(resulet.payload.data.studentDataAndPayment);
      }
    });
  };

  useEffect(() => {
    getCoures();
  }, []);

  useEffect(() => {
    if (!courseData) {
      getCoures();
    }
    return;
  }, [courseData]);

  return (
    <>
      {loading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>{error}</>
          ) : (
            <>
              {courseData && (
                <>
                  <div className="row">
                    <div className="col-xl-6 col-xxl-6">
                      <div className="row">
                        <div className="card">
                          <div className="card-body">
                            <div className="course-content ">
                              <div>
                                <h3>{courseData.name && courseData.name.en}</h3>
                                <ul className="d-flex align-items-center raiting my-0 flex-wrap">
                                  {courseData.teacher_id != null && (
                                    <li>
                                      Instructor : {courseData.teacher_id.name}
                                    </li>
                                  )}
                                  {
                                    courseData.is_free
                                    ?
                                    <li>Free</li>
                                    :
                                    <li>Price : {courseData.price / 100}$</li>
                                  }
                                  {/* <li>Price : {courseData.price / 100}$</li> */}
                                </ul>
                              </div>
                            </div>
                            <div className="video-img style-1">
                              <div className="view-demo">
                                <img
                                  src={`${process.env.REACT_APP_VERSEL_API}/${courseData.image}`}
                                  alt={courseData.name && courseData.name.en}
                                />
                              </div>
                            </div>
                            <Tab.Container defaultActiveKey="About">
                              <div className="course-details-tab style-2 mt-4">
                                <nav>
                                  <Nav
                                    as="div"
                                    className="nav nav-tabs tab-auto"
                                    id="nav-tab"
                                  >
                                    <Nav.Link
                                      as="button"
                                      className="nav-link"
                                      id="nav-about-tab"
                                      eventKey="About"
                                      type="button"
                                    >
                                      English Data
                                    </Nav.Link>
                                    <Nav.Link
                                      as="button"
                                      className="nav-link"
                                      id="nav-aboutar-tab"
                                      eventKey="Aboutar"
                                      type="button"
                                    >
                                      Arabic Data
                                    </Nav.Link>
                                  </Nav>
                                </nav>
                                <Tab.Content
                                  className="tab-content"
                                  id="nav-tabContent"
                                  style={{
                                    textAlign: "justify",
                                  }}
                                >
                                  <Tab.Pane id="nav-about" eventKey="About">
                                    <div className="about-content">
                                      <h4>Course Name</h4>
                                      <p>
                                        {courseData.name && courseData.name.en}
                                      </p>
                                      <h4>About This Course</h4>
                                      <p>
                                        {courseData.description &&
                                          courseData.description.en}
                                      </p>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane
                                    id="nav-about"
                                    eventKey="Aboutar"
                                    dir="rtl"
                                  >
                                    <div className="about-content">
                                      <h4>اسم الكورس</h4>
                                      <p>
                                        {courseData.name && courseData.name.ar}
                                      </p>
                                      <h4>وصف الكورس</h4>
                                      <p>
                                        {courseData.description &&
                                          courseData.description.ar}
                                      </p>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </Tab.Container>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-xxl-6">
                      <div className="card h-auto">
                        <div className="card-header border-0 ">
                          <h4>Demo Video</h4>
                          {courseData.demo_video && (
                            <>
                              <a
                                className="btn btn-info mt-2 mx-3"
                                href={courseData.demo_video}
                                target="_blank"
                              >
                                watch
                              </a>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="custome-accordion">
                        <Accordion
                          className="accordion"
                          defaultActiveKey={[`0`]}
                          alwaysOpen
                        >
                          {courseLessons.length == 0 ? (
                            <h3 className="text-center">
                              No lessons Added yet
                            </h3>
                          ) : (
                            <>
                              {[
                                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
                                26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
                                38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
                                50,
                              ].map((mainitem, mainindex) => (
                                <ListLessons
                                  eventKey={mainindex}
                                  key={mainindex + "lllskdlah"}
                                  sectionDisplay={mainindex}
                                  AllLessonArray={courseLessons}
                                  displayLessonVideo={displayLessonVideo}
                                />
                              ))}
                              {/*    <Accordion.Item
                                    className="card"
                                    eventKey={mainindex}
                                    key={mainindex + "lllskdlah"}
                                  >
                                    <Accordion.Header
                                      as="h2"
                                      className="accordion-header border-0"
                                    >
                                      <span className="acc-heading">
                                        Section {mainindex + 1}
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Collapse eventKey={mainindex}>
                                      <div className="accordion-body card-body pt-0">
                                        {courseLessons.map(
                                          (lesson, index) =>
                                            lesson.section == mainindex && (
                                              <AccordionItemLesson
                                                key={`${lesson._id}-${index}`}
                                                data={lesson}
                                                displayLessonVideo={() =>
                                                  displayLessonVideo(lesson._id)
                                                }
                                              />
                                            )
                                        )}
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion.Item> */}
                              <></>
                            </>
                          )}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="card students-list">
                      <div className="card-header border-0 flex-wrap pb-0">
                        <h4>Students List ({studentData.length})</h4>
                      </div>
                      <div className="card-body py-0">
                        <div className="table-responsive">
                          <div
                            id="student_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <table
                              className="table display mb-4 dataTablesCard order-table card-table text-black application "
                              id="application-tbl1_next"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>mobile</th>
                                  <th >Payment</th>
                                </tr>
                              </thead>
                              <tbody>
                                {studentData &&
                                  studentData.map(
                                    (item, index) =>
                                      item &&
                                      item.user_id != null && (
                                        <>
                                          <tr key={index}>
                                            <td>##</td>
                                            <td>
                                              <div className="d-flex align-items-center">
                                                <h4 className="mb-0 fs-16 font-w500">
                                                  {item.user_id.name}
                                                </h4>
                                              </div>
                                            </td>
                                            <td>{item.user_id.email}</td>
                                            <td>{item.user_id.mobile}</td>
                                            <td className="text-start">{item.amount_cents / 100} EG</td>
                                          </tr>
                                        </>
                                      )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xl-12">
                    <div className="card students-list">
                      <div className="card-header border-0 flex-wrap pb-0">
                        <h4>Students List ({courseStudnet.length})</h4>
                      </div>
                      <div className="card-body py-0">
                        <div className="table-responsive">
                          <div
                            id="student_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <table
                              className="table display mb-4 dataTablesCard order-table card-table text-black application "
                              id="application-tbl1_next"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>mobile</th>
                                </tr>
                              </thead>
                              <tbody>
                                {courseStudnet &&
                                  courseStudnet.map(
                                    (item, index) =>
                                      item &&
                                      item != null && (
                                        <>
                                          <tr key={index}>
                                            <td>##</td>
                                            <td>
                                              <div className="d-flex align-items-center">
                                                <h4 className="mb-0 fs-16 font-w500">
                                                  {item.name}
                                                </h4>
                                              </div>
                                            </td>
                                            <td>{item.email}</td>
                                            <td>{item.mobile}</td>
                                          </tr>
                                        </>
                                      )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
              )}
            </>
          )}

          {/* Add Lesson Form */}

          {/* Display Videso Lesson */}
          <Modal
            show={showVideoLesson}
            onHide={handleCloseVideoLesson}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <h3>play Video</h3>
            </Modal.Header>
            <Modal.Body>
              {videoUrl ? (
                <>
                  {/* <ReactPlayer
                    className="m-auto"
                    url={videoUrl}
                    controls
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                  /> */}
                  <Player
                      autoPlay 
                      // src={testVid} 
                      // src="https://api.spacecavez.com/videos/660b569d7c8d5c73b7c748d3" 
                      // https://api.spacecavez.com/dashboard/videos/660b569d7c8d5c73b7c748d3
                      // src="https://api.spacecavez.com/dashboard/videos/660b569d7c8d5c73b7c748d3"
                      // src={`${process.env.REACT_APP_VERSEL_API}/${videoUrl}`}
                      src={videoUrl}
                      //   src={`${process.env.REACT_APP_VERSEL_API}/videos/${lesssonId}/${userId}/${token}`}
                      onTimeUpdate= {(e) => {
                      const timeWhichPlayed = e.target.currentTime;
                      //   const lessonDuration = e.target.duration;
                      //   const halfTime = lessonDuration / 2;
                      const tolerance = 1;
                      //   if(timeWhichPlayed >= halfTime - tolerance && timeWhichPlayed <= halfTime + tolerance) {
                      //     axios.post(postCompletingLessonApi , {} , {withCredentials : true});
                      //   }
                      }}
                  >
                      {/* <ControlBar autoHide={false} className="my-class" /> */}
                      <ControlBar>
                      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                      </ControlBar>
                  </Player>
                </>
              ) : (
                <>
                  <h2>No Video Url</h2>
                </>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};
export default TeacherCourseDetail;
