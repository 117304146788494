import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";

import { FetchPayments } from "../../../../store/Slices/payments/getAllPaymentsSlicer";
import { getAllCourseSubscriptionsApi } from "../../../../store/Slices/subscriptions/courses/getAllCourseSubscriptionsSlicer";
import { deleteCourseSubscriptionApi } from "../../../../store/Slices/subscriptions/courses/deleteCourseSubscriptionSclicer";
import Swal from "sweetalert2";

const DisplayAllCourseSubscriptionsTableData = () => {
  const { loading, error } = useSelector(
    (s) => s.getAllCourseSubscriptionsSlicer
  );

  const dispatch = useDispatch();
  const [courseSubscriptions, setCourseSubscriptions] = useState();
  const [backupData, setBackupData] = useState([]);

  const [pages, setPages] = useState(1);

  // Handle Delete
  const handleDelete = (id) => {
    console.log(id);

    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete Subscription",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCourseSubscriptionApi(id)).then((resulet) => {
          if (resulet.payload.message === "Data Deleted Successfully") {
            Swal.fire("Deleted!", resulet.payload.message, "success");
            getAllSubscriptionsForCourse(1);
          }
        });
      }
    });
  };
  // Handle Get All User Data
  const getAllSubscriptionsForCourse = async (pageNumber) => {
    dispatch(getAllCourseSubscriptionsApi(pageNumber)).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        setCourseSubscriptions(resulet.payload.data);
        //setBackupData(resulet.payload.data);
        resulet.payload.data.total &&
          setPages(Math.ceil(resulet.payload.data.total / 10));
      } else {
        swal("Error!", error, "error");
      }
    });
  };

  const activePagenation = (number, activeNum) => {
    const allListItem = document.querySelectorAll("#pagination-list-item li");
    allListItem.forEach((item) => {
      item.classList.remove("active");
    });
    allListItem[activeNum].classList.add("active");
    getAllSubscriptionsForCourse(number);
  };

  const formsFilters = (e) => {
    if (e.target.value === "") {
      setCourseSubscriptions(backupData);
      return;
    }

    const filterArray = backupData.filter((item) => {
      return (
        (item.diploma.length != 0 &&
          item.diploma[0].name.en
            .toLowerCase()
            .includes(e.target.value.toLowerCase())) ||
        (item.user.length != 0 &&
          item.user[0].email
            .toLowerCase()
            .includes(e.target.value.toLowerCase()))
      );
    });
    setCourseSubscriptions(filterArray);
  };
  useEffect(() => {
    getAllSubscriptionsForCourse(1);
  }, []);

  return (
    <>
      {/*
     <div className="col-xl-12">
        <div className="card students-list">
          <div className="card-header border-0 flex-wrap ">
            <h4>All Subscriptions ({backupData.length})</h4>
            <div className="input-group search-area w-auto">
              <span className="input-group-text">
                <Link to={"#"}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                      fill="var(--primary)"
                    ></path>
                  </svg>
                </Link>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                onChange={formsFilters}
              />
            </div>
          </div>
        </div>
      </div>
     */}
      {loading ? (
        <>
          <div className="col-12 d-flex justify-content-center gap-3">
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="primary" />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>{error}</h2>
              </div>
            </>
          ) : (
            <>
              {courseSubscriptions && (
                <>
                  {courseSubscriptions.subscriptions.length > 0 ? (
                    <>
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="w-100 table-responsive">
                              <div
                                id="example_wrapper"
                                className="dataTables_wrapper"
                              >
                                <table
                                  id="example"
                                  className="display w-100 dataTable"
                                >
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>user Email</th>
                                      <th>course Name</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {courseSubscriptions.subscriptions.map(
                                      (content, index) => (
                                        <tr key={index}>
                                          <>
                                            <td>##</td>
                                            <td>
                                              {content.user_id &&
                                              content.user_id.email
                                                ? content.user_id.email
                                                : "No Data"}
                                            </td>
                                            <td>
                                              {content.course_id &&
                                              content.course_id.name &&
                                              content.course_id.name.en
                                                ? content.course_id.name.en
                                                : "No Data"}
                                            </td>
                                            <td>
                                              <Link
                                                to={"#"}
                                                onClick={() =>
                                                  handleDelete(content._id)
                                                }
                                              >
                                                <span className="badge badge-danger">
                                                  <i className="fa-solid fa-trash" />
                                                </span>
                                              </Link>
                                            </td>
                                          </>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pagination-down">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <h4 className="sm-mb-0 mb-3">
                            {/* Showing <span>1-6 </span>from <span>100 </span>data */}
                          </h4>
                          <ul id="pagination-list-item">
                            {/* <li>
                              <Link to={"#"}>
                                <i className="fas fa-chevron-left"></i>
                              </Link>
                            </li> */}

                            {pages &&
                              [...Array(pages)].map((page, index) => (
                                <li key={index}>
                                  <Link
                                    to={"#"}
                                    //className="active"
                                    onClick={() => {
                                      activePagenation(index + 1, index);
                                    }}
                                  >
                                    {index + 1}
                                  </Link>
                                </li>
                              ))}
                            {/* 
                            <li>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  getAllSubscriptionsForCourse(2);
                                }}
                              >
                                2
                              </Link>
                            </li> */}

                            {/* <li>
                              <Link to={"#"}>
                                <i className="fas fa-chevron-right"></i>
                              </Link>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 d-flex justify-content-center gap-3">
                        <h2>No Subscriptions happen yets</h2>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default DisplayAllCourseSubscriptionsTableData;
