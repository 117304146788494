import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import DisplayDiplomasPaymentsTableData from "./DisplayDiplomasPaymentsTableData";

export default function DisplayDiplomasPayments() {
  return (
    <>
      <Fragment>
        <PageTitle activeMenu="Traks Transactions" motherMenu="payment" />

        <DisplayDiplomasPaymentsTableData />
      </Fragment>
    </>
  );
}
