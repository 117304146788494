/**
 * The drag supported events
 * Object<String>
 */
export const EVENTS = {
	MOUSEDOWN: 'mousedown',
	MOUSEMOVE: 'mousemove',
	MOUSEUP: 'mouseup',
	TOUCHSTART: 'touchstart',
	TOUCHMOVE: 'touchmove',
	TOUCHEND: 'touchend'
};