import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../services/LogoutFunction";
import swal from "sweetalert";

export const deleteUser = createAsyncThunk("user/delete", async (userId) => {

  const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
  const request = await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/users/delete/${userId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${tokenData}`,
      },
    }
  )
    .then((respons) => respons.json())
    .then((resulet) => {
      console.log(resulet);
      return resulet;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });

  return request;
});

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}
const deleteStudentSlicer = createSlice({
  name: "users/delete",
  initialState: {
    responst: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteUser.fulfilled,(state,action)=>{
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      }

    });
  },
});

export default deleteStudentSlicer.reducer;
