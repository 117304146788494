import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import course1 from "./../../../images/courses/quiz1.jpg";
import { Button, Modal, Nav, Spinner, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import DisplayAllUserTableData from "../Users/components/DisplayAllUserTableData";
import { getQuizeApi } from "../../../store/Slices/quizes/getQuizeByIdSlicer";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { deleteQuestionById } from "../../../store/Slices/quizes/questions/deleteQuestionByIdSlicer";
import { Formik } from "formik";
import * as Yup from "yup";
import { createQuestionApi } from "../../../store/Slices/quizes/questions/addQuestionSlicer";
import { updateQuestionApi } from "../../../store/Slices/quizes/questions/updateQuestionByIdSlicer";

const createQuestion = Yup.object().shape({
  name: Yup.string().required("Please enter a qustion name"),
  question_type: Yup.string().required("Please enter a question type"),
  correct_answer: Yup.number().required("Please enter a quize name"),
});
const QuizeDetails = () => {
  const { _id } = useParams();
  const {
    loading: QuizeLoading,
    data: QuizeData,
    error: QuizeError,
  } = useSelector((state) => state.getQuizeByIdSlicer);
  const { loading: createQuestionLoading, error: createQuestionError } =
    useSelector((state) => state.addQuestionSlicer);
  const { loading: updateQuestionLoading, error: updateQuestionError } =
    useSelector((state) => state.updateQuestionByIdSlicer);

  const dispatch = useDispatch();
  const [quize, setQuize] = useState();
  const [questions, setQuestions] = useState([]);
  const [createModle, setCreateModle] = useState(false);
  const [diplayQuestion, setDiplayQuestion] = useState(false);
  const [intialData, setIntialData] = useState({
    questionId: "",
    name: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    question_type: "",
    correct_answer: 0,
  });

  const showDisplayModle = (data) => {
    const optionLenght = data.options.length;
    console.log(data);
    const typeQuestion =
      data.options.length == 2
        ? "tf"
        : data.options.length == 3
        ? "chose-3-answers"
        : "chose-4-answers";
    console.log(typeQuestion);

    setIntialData({
      questionId: data._id,
      name: data.name,
      option1: data.options[0],
      option2: data.options[1],
      option3: data.options[2],
      option4: data.options[3],
      question_type: typeQuestion,
      correct_answer: data.correct_answer,
    });
    setDiplayQuestion(true);
  };

  const getQuizeDetails = async () => {
    dispatch(getQuizeApi(_id));
  };
  const handleDeleteClick = (questionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this Quize",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteQuestionById(questionId)).then((res) => {
          if (res.payload.message === "Question Deleted Successfully") {
            Swal.fire("Deleted!", res.payload.message, "success");
            getQuizeDetails();
          }
        });
      } else {
        Swal.fire("Info!", "Quize Is Safe", "info");
      }
    });
  };
  const handleCreateQuestion = (value, { resetForm }) => {
    let questionData;

    if (value.question_type === "tf") {
      questionData = {
        quizId: _id,
        data: {
          name: value.name,
          question_type: value.question_type,
          options: [value.option1, value.option2],
          correct_answer: value.correct_answer,
        },
      };
    } else {
      if (value.question_type === "chose-3-answers") {
        questionData = {
          quizId: _id,
          data: {
            name: value.name,
            question_type: "chose",
            options: [value.option1, value.option2, value.option3],
            correct_answer: value.correct_answer,
          },
        };
      } else {
        questionData = {
          quizId: _id,
          data: {
            name: value.name,
            question_type: "chose",
            options: [
              value.option1,
              value.option2,
              value.option3,
              value.option4,
            ],
            correct_answer: value.correct_answer,
          },
        };
      }
    }

    dispatch(createQuestionApi(questionData)).then((res) => {
      if (res.payload.message === "Question created Successfully") {
        Swal.fire("Crreated!", res.payload.message, "success");
        resetForm();
        setCreateModle(false);
        getQuizeDetails();
      } else {
        Swal.fire("Info", res.payload.message, "error");
      }
    });
  };
  const handleUpdateQuestions = (value, { resetForm }) => {
    let questionData;

    if (value.question_type === "tf") {
      questionData = {
        questionId: value.questionId,
        data: {
          name: value.name,

          question_type: value.question_type,
          options: [value.option1, value.option2],
          correct_answer: value.correct_answer,
        },
      };
    } else {
      if (value.question_type === "chose-3-answers") {
        questionData = {
          questionId: value.questionId,
          data: {
            name: value.name,

            question_type: "chose",
            options: [value.option1, value.option2, value.option3],
            correct_answer: value.correct_answer,
          },
        };
      } else {
        questionData = {
          questionId: value.questionId,
          data: {
            name: value.name,

            question_type: "chose",
            options: [
              value.option1,
              value.option2,
              value.option3,
              value.option4,
            ],
            correct_answer: value.correct_answer,
          },
        };
      }
    }

    dispatch(updateQuestionApi(questionData)).then((res) => {
      if (res.payload.message === "Question Updated Successfully") {
        Swal.fire("Updated!", res.payload.message, "success");
        setIntialData({
          questionId: "",
          name: "",
          option1: "",
          option2: "",
          option3: "",
          option4: "",
          question_type: "",
          correct_answer: 0,
        });
        resetForm();
        setDiplayQuestion(false);
        getQuizeDetails();
      } else {
        Swal.fire("Info", res.payload.message, "error");
      }
    });
  };

  useEffect(() => {
    getQuizeDetails();
  }, []);

  useEffect(() => {
    QuizeData && setQuize(QuizeData.quize);
    QuizeData && setQuestions(QuizeData.questions);
  }, [QuizeData]);

  return (
    <>
      {QuizeLoading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {QuizeError ? (
            <>
              <div className="row d-flex justify-content-center gap-2">
                <h3>Some Error Happen</h3>
              </div>
            </>
          ) : (
            <>
              {QuizeData && quize && (
                <>
                  <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="course-content ">
                            <div>
                              <h3>{quize.name}</h3>
                              <ul className="d-flex align-items-center raiting my-0 flex-wrap">
                                <li>
                                  number of attempts :{" "}
                                  {quize.number_of_attempts}
                                </li>
                                <li>duration :{quize.duration}</li>
                              </ul>
                            </div>
                          </div>

                          <Tab.Container defaultActiveKey="About">
                            <div className="course-details-tab style-2 mt-4">
                              <nav>
                                <Nav
                                  as="div"
                                  className="nav nav-tabs tab-auto"
                                  id="nav-tab"
                                >
                                  <Nav.Link
                                    as="button"
                                    className="nav-link"
                                    id="nav-about-tab"
                                    eventKey="About"
                                    type="button"
                                  >
                                    Quize Data
                                  </Nav.Link>
                                </Nav>
                              </nav>
                              <Tab.Content
                                className="tab-content"
                                id="nav-tabContent"
                                style={{
                                  textAlign: "justify",
                                }}
                              >
                                <Tab.Pane id="nav-about" eventKey="About">
                                  <div className="about-content">
                                    <h4>Quize Name</h4>
                                    <p>{quize.name}</p>
                                    <h4>About This Quize</h4>
                                    <p>{quize.description}</p>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-heading d-flex justify-content-between align-items-center">
                    <h3 className="m-0">All Questions</h3>
                    <button
                      onClick={() => setCreateModle(true)}
                      className="btn btn-primary btn-sm"
                    >
                      Add New Question
                    </button>
                  </div>
                  <div className="row">
                    <div className="card">
                      <div className="card-body">
                        <div className="w-100 table-responsive">
                          <div
                            id="example_wrapper"
                            className="dataTables_wrapper"
                          >
                            <table
                              id="example"
                              className="display w-100 dataTable"
                            >
                              <thead>
                                <tr>
                                  <th colSpan={2}>Name</th>

                                  <th>type</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {questions.map((item, index) => (
                                  <tr key={index}>
                                    <>
                                      <td>
                                        <span>{item.name}</span>
                                      </td>
                                      <td></td>
                                      <td>{item.question_type}</td>

                                      <td>
                                        <div className="d-flex">
                                          <a
                                            className="btn btn-secondary shadow btn-xs sharp me-2"
                                            onClick={() =>
                                              showDisplayModle(item)
                                            }
                                          >
                                            <i className="fa-solid fa-display" />
                                          </a>
                                          {/* <a
                                            className="btn btn-secondary shadow btn-xs sharp me-2"
                                            //  onClick={() => handleUpdate(item)}
                                          >
                                            <i className="fa-solid fa-pen"></i>
                                          </a>  */}
                                          <a
                                            className="btn btn-danger shadow btn-xs sharp"
                                            onClick={() =>
                                              handleDeleteClick(item._id)
                                            }
                                          >
                                            <i className="fa fa-trash"></i>
                                          </a>
                                        </div>
                                      </td>
                                    </>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
      {/* Display Questions */}
      <Modal
        show={diplayQuestion}
        onHide={() => setDiplayQuestion(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Body>
          <Formik
            initialValues={{
              questionId: intialData.questionId,
              name: intialData.name,
              option1: intialData.option1,
              option2: intialData.option2,
              option3: intialData.option3,
              option4: intialData.option4,
              question_type: intialData.question_type,
              correct_answer: intialData.correct_answer,
            }}
            validationSchema={createQuestion}
            onSubmit={handleUpdateQuestions}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <form className="row" onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.name
                        ? errors.name
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Question Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a name.."
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <div
                        id="val-name-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.name && errors.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.question_type
                        ? errors.question_type
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Select Question Type</label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        name="question_type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.question_type}
                      >
                        <option defaultValue={null}>Please select</option>
                        <option value="chose-4-answers">chose 4 answers</option>
                        <option value="chose-3-answers">chose 3 answers</option>
                        <option value="tf">T&F</option>
                      </select>
                      <div
                        id="val-question_type-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.question_type && errors.question_type}
                      </div>
                    </div>
                  </div>
                </div>
                {values.question_type === "chose-4-answers" && (
                  <>
                    {/* options */}
                    <div className="row">
                      <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                        <label className="text-label">Enter Options 1</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 1.."
                            name="option1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option1}
                          />
                        </div>
                      </div>
                      <div className={` col-lg-12 col-sm-12 form-group mb-3 `}>
                        <label className="text-label">Enter Options 2</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 2.."
                            name="option2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option2}
                          />
                        </div>
                      </div>
                      <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                        <label className="text-label">Enter Options 3</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 3.."
                            name="option3"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option3}
                          />
                        </div>
                      </div>
                      <div className={` col-lg-12 col-sm-12 form-group mb-3 `}>
                        <label className="text-label">Enter Options 4</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 4.."
                            name="option4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.correct_answer
                            ? errors.correct_answer
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Enter Question Correct Answer
                        </label>
                        <div className="input-group">
                          <select
                            className="form-control"
                            name="correct_answer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.correct_answer}
                          >
                            <option value={null}>
                              Please select Correct Answer
                            </option>
                            <option value={0}>frist</option>
                            <option value={1}>second</option>
                            <option value={2}>third</option>
                            <option value={3}>fourth</option>
                          </select>
                          <div
                            id="val-correct_answer-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.correct_answer && errors.correct_answer}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {values.question_type === "chose-3-answers" && (
                  <>
                    {/* options */}
                    <div className="row">
                      <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                        <label className="text-label">Enter Options 1</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 1.."
                            name="option1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option1}
                          />
                        </div>
                      </div>
                      <div className={` col-lg-12 col-sm-12 form-group mb-3 `}>
                        <label className="text-label">Enter Options 2</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 2.."
                            name="option2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option2}
                          />
                        </div>
                      </div>
                      <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                        <label className="text-label">Enter Options 3</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 3.."
                            name="option3"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option3}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.correct_answer
                            ? errors.correct_answer
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Enter Question Correct Answer
                        </label>
                        <div className="input-group">
                          <select
                            className="form-control"
                            name="correct_answer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.correct_answer}
                          >
                            <option value={null}>
                              Please select Correct Answer
                            </option>
                            <option value={0}>frist</option>
                            <option value={1}>second</option>
                            <option value={2}>third</option>
                          </select>
                          <div
                            id="val-correct_answer-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.correct_answer && errors.correct_answer}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {values.question_type === "tf" && (
                  <>
                    <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                      <label className="text-label">Enter Options 1</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a option 1.."
                          name="option1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.option1}
                        />
                      </div>
                    </div>
                    <div className={` col-lg-12 col-sm-12 form-group mb-3 `}>
                      <label className="text-label">Enter Options 2</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a option 2.."
                          name="option2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.option2}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.correct_answer
                            ? errors.correct_answer
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Enter Question Correct Answer
                        </label>
                        <div className="input-group">
                          <select
                            className="form-control"
                            name="correct_answer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.correct_answer}
                          >
                            <option value={null}>
                              Please select Correct Answer
                            </option>
                            <option value={0}>true</option>
                            <option value={1}>false</option>
                          </select>
                          <div
                            id="val-correct_answer-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.correct_answer && errors.correct_answer}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setDiplayQuestion(false)}
                  >
                    Close
                  </Button>
                  {updateQuestionLoading ? (
                    <>
                      <Button variant="primary" disabled>
                        loading ...
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="primary" type="submit">
                        Updated Question
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Add Question  */}
      <Modal
        show={createModle}
        onHide={() => setCreateModle(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Body>
          <Formik
            initialValues={{
              name: "",
              option1: "",
              option2: "",
              option3: "",
              option4: "",
              question_type: "",
              correct_answer: 0,
            }}
            validationSchema={createQuestion}
            onSubmit={handleCreateQuestion}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form className="row" onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.name
                        ? errors.name
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter question</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a name.."
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <div
                        id="val-name-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.name && errors.name}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.question_type
                        ? errors.question_type
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Select Question Type</label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        name="question_type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.question_type}
                      >
                        <option defaultValue={null}>Please select</option>
                        <option value="chose-4-answers">
                          chose with 4 answers
                        </option>
                        <option value="chose-3-answers">
                          chose with 3 answers
                        </option>
                        <option value="tf">T&F</option>
                      </select>
                      <div
                        id="val-question_type-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.question_type && errors.question_type}
                      </div>
                    </div>
                  </div>
                </div>

                {values.question_type === "chose-4-answers" && (
                  <>
                    {/* options */}
                    <div className="row">
                      <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                        <label className="text-label">Enter Options 1</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 1.."
                            name="option1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option1}
                          />
                        </div>
                      </div>
                      <div className={` col-lg-12 col-sm-12 form-group mb-3 `}>
                        <label className="text-label">Enter Options 2</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 2.."
                            name="option2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option2}
                          />
                        </div>
                      </div>
                      <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                        <label className="text-label">Enter Options 3</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 3.."
                            name="option3"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option3}
                          />
                        </div>
                      </div>
                      <div className={` col-lg-12 col-sm-12 form-group mb-3 `}>
                        <label className="text-label">Enter Options 4</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 4.."
                            name="option4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.correct_answer
                            ? errors.correct_answer
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Enter Question Correct Answer
                        </label>
                        <div className="input-group">
                          <select
                            className="form-control"
                            name="correct_answer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.correct_answer}
                          >
                            <option value={null}>
                              Please select Correct Answer
                            </option>
                            <option value={0}>frist</option>
                            <option value={1}>second</option>
                            <option value={2}>third</option>
                            <option value={3}>fourth</option>
                          </select>
                          <div
                            id="val-correct_answer-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.correct_answer && errors.correct_answer}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {values.question_type === "chose-3-answers" && (
                  <>
                    {/* options */}
                    <div className="row">
                      <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                        <label className="text-label">Enter Options 1</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 1.."
                            name="option1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option1}
                          />
                        </div>
                      </div>
                      <div className={` col-lg-12 col-sm-12 form-group mb-3 `}>
                        <label className="text-label">Enter Options 2</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 2.."
                            name="option2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option2}
                          />
                        </div>
                      </div>
                      <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                        <label className="text-label">Enter Options 3</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a option 3.."
                            name="option3"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.option3}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.correct_answer
                            ? errors.correct_answer
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Enter Question Correct Answer
                        </label>
                        <div className="input-group">
                          <select
                            className="form-control"
                            name="correct_answer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.correct_answer}
                          >
                            <option value={null}>
                              Please select Correct Answer
                            </option>
                            <option value={0}>frist</option>
                            <option value={1}>second</option>
                            <option value={2}>third</option>
                          </select>
                          <div
                            id="val-correct_answer-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.correct_answer && errors.correct_answer}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {values.question_type === "tf" && (
                  <>
                    <div className={` col-lg-12 col-sm-12 form-group mb-3`}>
                      <label className="text-label">Enter Options 1</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a option 1.."
                          name="option1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.option1}
                        />
                      </div>
                    </div>
                    <div className={` col-lg-12 col-sm-12 form-group mb-3 `}>
                      <label className="text-label">Enter Options 2</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a option 2.."
                          name="option2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.option2}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.correct_answer
                            ? errors.correct_answer
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Enter Question Correct Answer
                        </label>
                        <div className="input-group">
                          <select
                            className="form-control"
                            name="correct_answer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.correct_answer}
                          >
                            <option value={null}>
                              Please select Correct Answer
                            </option>
                            <option value={0}>true</option>
                            <option value={1}>false</option>
                          </select>
                          <div
                            id="val-correct_answer-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.correct_answer && errors.correct_answer}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setCreateModle(false)}
                  >
                    Close
                  </Button>
                  {createQuestionLoading ? (
                    <>
                      <Button variant="primary" disabled>
                        loading ...
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="primary" type="submit">
                        Create Question
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default QuizeDetails;
