import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import DisplayAllCourseSubscriptionsTableData from "./components/DisplayAllCourseSubscriptionsTableData";

export default function DisplayAllCourseSubscriptions() {
  return (
    <>
      <Fragment>
        <PageTitle
          activeMenu="Courses Subscriptions"
          motherMenu="Subscriptions"
        />

        <DisplayAllCourseSubscriptionsTableData />
      </Fragment>
    </>
  );
}
