import React, { Component, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { sendMessateToNewslistApi } from "../../../../store/Slices/NewslistMessage/NewsListMessageSlicer";
import Swal from "sweetalert2";
const createMessage = Yup.object().shape({
  subject: Yup.string().required("Please enter a quize name"),
  message: Yup.string().required("Please enter a Message"),
});

const Summermessage = () => {
  const { loading: messageLoading, error: messageError } = useSelector(
    (state) => state.NewsListMessageSlicer
  );
  const dispatch = useDispatch();

  const handleSendMessage = (value, { resetForm }) => {
    const data = {
      subject: value.subject,
      message: value.message,
    };
    dispatch(sendMessateToNewslistApi(data)).then((res) => {
      if (res.payload.message === "Email Sent Successfully") {
        Swal.fire({
          icon: "success",
          title: res.payload.message,
          text: `Message send to ${res.payload.num_of_accepted} supescripers`,
        });
        resetForm();
      }
    });
  };

  //   state = {
  //     value: RichTextEditor.createEmptyValue()
  //   }

  //   onChange = (value) => {
  //     this.setState({value});
  //     if (this.props.onChange) {
  //       // Send the changes up to the parent component as an HTML string.
  //       // This is here to demonstrate using `.toString()` but in a real app it
  //       // would be better to avoid generating a string on each change.
  //       this.props.onChange(
  //         value.toString('html')
  //       );
  //     }
  //   };

  return (
    <div className="h-80">
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">write Subject And Message</h4>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{
                  subject: "",
                  message: "",
                }}
                validationSchema={createMessage}
                onSubmit={handleSendMessage}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="row">
                      <div
                        className={` col-lg-12 col-sm-12 form-group mb-3 ${
                          values.subject
                            ? errors.subject
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">Enter Subject</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-solid fa-file-signature"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter subject.."
                            name="subject"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.subject}
                          />
                          <div
                            id="val-subject-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.subject && errors.subject}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={` col-lg-12 col-sm-12 form-group mb-3 ${
                          values.message
                            ? errors.message
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">Enter message</label>
                        <div className="input-group">
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter message.."
                            name="message"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                            rows={10}
                          />
                          <div
                            id="val-message-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.message && errors.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6  d-flex gap-2 ">
                        {messageLoading ? (
                          <>
                            <Button variant="primary" disabled>
                              loading ...
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button variant="primary" type="submit">
                              Create Promocode
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summermessage;
