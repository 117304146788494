import React, { Fragment, useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./courseStyle.css";
import PageTitle from "../../layouts/PageTitle";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { addCourseApi } from "../../../store/AddCourseSlicer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { toast } from "react-toastify";
const createCoureSchema = Yup.object().shape({
  enname: Yup.string()
    .min(6, "course name must consist of at least 6 characters ")
    .max(300, "course name must consist of at most 300 characters ")
    .required("Please enter a course name"),
  arname: Yup.string()
    .min(6, "course name must consist of at least 6 characters ")
    .max(300, "course name must consist of at most 300 characters ")
    .required("Please enter a course name"),
  // endescription: Yup.string()
  //   .min(10, "description  must consist of at least 10 characters ")
  //   .max(3000, "description must consist of at most 3000 characters ")
  //   .required("Please enter a course description"),
  // ardescription: Yup.string()
  //   .min(10, "description  must consist of at least 10 characters ")
  //   .max(3000, "description must consist of at most 3000 characters ")
  //   .required("Please enter a course description"),
  price: Yup.number().required("Please enter a price"),
  discount: Yup.number().required("Please enter a discount"),
  // discountType: Yup.string()
  // .min("Choose Discount Type", "Please enter a discount type (% or EG)")
  // .required("Please enter a discount type (% or EG)"),
  image: Yup.mixed().required("Please upload a image JPG , PNG, JPNG ,WEBP"),
  wallpaper: Yup.mixed().required("Please upload a image JPG , PNG, JPNG,WEBP"),
});

const AddCourseForm = ({ inst_id }) => {
  console.log(inst_id);

  const { loading, error } = useSelector((state) => state.addCourse);

  const dispatch = useDispatch();
  const [userdata, setUserData] = useState();
  const [freeStatus , setFreeStatus] = useState(false);
  const [visipilatyStatus , setvisipilatyStatus] = useState(false);
  const [courseEnglishDescription , setCourseEnglishDescription] = useState("");
  const [courseArabicDescription , setCourseArabicDescription] = useState("");
  const [errEnDescription , setErrEnDescription] = useState(true);
  const [errArDescription , setErrArDescription] = useState(true);
  const [discountType , setDiscountType] = useState("Choose Discount Type..");
  const history = useHistory();
  // File Refrances Basic Images
  const [courseFormIntilization, setCourseFormInitialization] = useState({
    enname: "",
    arname: "",
    endescription: "",
    ardescription: "",
    image: "",
    imageName: "",
    wallpaper: "",
    wallpaperName: "",
    price: 1,
    discount: 0,
    discountType : "Choose Discount Type..",
    teacherId: inst_id,
  });
  const fileUploaderImgCourse = useRef(null);
  const openFileUploaderImgCourse = () => {
    fileUploaderImgCourse.current.click();
  };

  const fileUploaderWallpaperCourse = useRef(null);
  const openFileUploaderWallpaperCourse = () => {
    fileUploaderWallpaperCourse.current.click();
  };

  // Get All Data Function
  const createCourseFunction = (value, { resetForm }) => {
  
    console.log(value);
    
    let formData = new FormData();

    formData.append("name[en]", value.enname);
    formData.append("name[ar]", value.arname);
    // formData.append("description[en]", value.endescription);
    formData.append("description[en]", courseEnglishDescription);
    // formData.append("description[ar]", value.ardescription);
    formData.append("description[ar]", courseArabicDescription);
    formData.append("image", value.image);
    formData.append("image", value.wallpaper);
    // freeStatus ? formData.append("price", 0) : formData.append("price", value.price);
    freeStatus ? formData.append("price", 0) : formData.append("price", value.price * 100);
    freeStatus ? formData.append("discount_price", 0) : formData.append("discount_price", value.discount)
    freeStatus ? formData.append("discount_type", "fixed") : formData.append("discount_type", discountType)
    // freeStatus ? formData.append("is_free", 1) : formData.append("is_free", 0);
    formData.append("is_free", freeStatus);
    formData.append("is_visible", visipilatyStatus);
    formData.append("teacherId", inst_id);
    
    const sendDataFunc = () => {
      dispatch(addCourseApi(formData)).then((resulte) => {
        if (resulte.payload.message === "course added successfully") {
          Swal.fire({
            title: resulte.payload.message,
            text: "Go to course details to added lessons/quizes",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, stay and add new course",
            confirmButtonText: "Yes, Go to add lessons",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`/course-details-2/${resulte.payload.data._id}`);
            } else {
              resetForm();
              setFreeStatus(false);
              setCourseEnglishDescription("");
              setCourseArabicDescription("");
              setvisipilatyStatus(false)
            }
          });
        } else {
          swal("error", resulte.payload.message, "warning");
        }
      });
    }

    if(errArDescription || errEnDescription) {
      toast.error("arabic or english description must not be less than 10 letters");
    }else {
      if(freeStatus) {
        sendDataFunc();
      }else {
        if(value.price && parseInt(value.price) > 0) {
          if(parseInt(value.price) < 10) {
            Swal.fire({
              title: "Oops!",
              text: "Min Price 10$!",
              icon: "warning"
            });
          }else {
            if(value.price &&  discountType === "Choose Discount Type..") {
              toast.error("Choose Discount Type !");
            }else {
              if(value.discount < 1) {
                toast.error("Enter Discount Value !");
              }else {
                if(discountType === "percentage" && parseInt(value.discount) > 100){
                  toast.error("Discount Must Not be More Than 100% !");
                }else {
                  if(value.discount > parseInt(value.price)){
                    toast.error("Discount Must Not be More Than Course Price !");
                  }else {
                    sendDataFunc();
                  }
                }
              }
            }
          }
        }
      }
    }

    // if(value.price) {
    //   if(discountType === "Choose Discount Type!") {
    //     toast.error("Please Choose Discount Type!");
    //   }else {
    //     if(value.price !== "" && parseInt(value.price) < 10) {
    //       Swal.fire({
    //         title: "Oops!",
    //         text: "Min Price 10$!",
    //         icon: "warning"
    //       });
    //     }else {
    //       if(errArDescription || errEnDescription) {
    //         toast.error("arabic or english description must not be less than 10 letters");
    //       }else {
    //         // dispatch(addCourseApi(formData)).then((resulte) => {
    //         //   if (resulte.payload.message === "course added successfully") {
    //         //     Swal.fire({
    //         //       title: resulte.payload.message,
    //         //       text: "Go to course details to added lessons/quizes",
    //         //       icon: "success",
    //         //       showCancelButton: true,
    //         //       confirmButtonColor: "#3085d6",
    //         //       cancelButtonColor: "#d33",
    //         //       cancelButtonText: "No, stay and add new course",
    //         //       confirmButtonText: "Yes, Go to add lessons",
    //         //     }).then((result) => {
    //         //       if (result.isConfirmed) {
    //         //         history.push(`/course-details-2/${resulte.payload.data._id}`);
    //         //       } else {
    //         //         resetForm();
    //         //         setFreeStatus(false);
    //         //         setCourseEnglishDescription("");
    //         //         setCourseArabicDescription("");
    //         //         setvisipilatyStatus(false)
    //         //       }
    //         //     });
    //         //   } else {
    //         //     swal("error", resulte.payload.message, "warning");
    //         //   }
    //         // });
    //         sendDataFunc()
    //       }
    //     }
    //   }
    // }else {
    //   if(errArDescription || errEnDescription) {
    //     toast.error("arabic or english description must not be less than 10 letters");
    //   }else {
    //     // dispatch(addCourseApi(formData)).then((resulte) => {
    //     //   if (resulte.payload.message === "course added successfully") {
    //     //     Swal.fire({
    //     //       title: resulte.payload.message,
    //     //       text: "Go to course details to added lessons/quizes",
    //     //       icon: "success",
    //     //       showCancelButton: true,
    //     //       confirmButtonColor: "#3085d6",
    //     //       cancelButtonColor: "#d33",
    //     //       cancelButtonText: "No, stay and add new course",
    //     //       confirmButtonText: "Yes, Go to add lessons",
    //     //     }).then((result) => {
    //     //       if (result.isConfirmed) {
    //     //         history.push(`/course-details-2/${resulte.payload.data._id}`);
    //     //       } else {
    //     //         resetForm();
    //     //         setFreeStatus(false);
    //     //         setCourseEnglishDescription("");
    //     //         setCourseArabicDescription("");
    //     //         setvisipilatyStatus(false)
    //     //       }
    //     //     });
    //     //   } else {
    //     //     swal("error", resulte.payload.message, "warning");
    //     //   }
    //     // });
    //     sendDataFunc()
    //   }
    //   console.log("done")
    // }


  };

  useEffect(() => {
    window.scroll(0, 0);
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setUserData(data);
    } else {
      history.push("/login");
    }
  }, []);

  const colors = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
    '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
    '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
    '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
    '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
    '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
  ];
  const modules = {
      toolbar: {
      container: [
      [{"color" : colors}],
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['clean']
      ],
  }
  };
  // const handleChangeEnDesc = (value) => {
  //   // Remove HTML tags from the value
  //   const textWithoutTags = value.replace(/<[^>]+>/g, '');
  //   setCourseEnglishDescription(value);
  // };


  return (
    <Fragment>
      <PageTitle activeMenu="Add Course" motherMenu="Courses" />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  enname: courseFormIntilization.enname,
                  arname: courseFormIntilization.arname,

                  // endescription: courseFormIntilization.endescription,
                  // ardescription: courseFormIntilization.ardescription,

                  image: courseFormIntilization.image,
                  imageName: courseFormIntilization.imageName,

                  wallpaper: courseFormIntilization.wallpaper,
                  wallpaperName: courseFormIntilization.wallpaperName,

                  price: courseFormIntilization.price,
                  discount: courseFormIntilization.discount,
                  discountType: courseFormIntilization.discountType,
                  teacherId: courseFormIntilization.teacherId,
                }}
                validationSchema={createCoureSchema}
                onSubmit={createCourseFunction}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div>
                      <h1>Basic Infromation</h1>
                      <section>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.enname
                                ? errors.enname
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Coures Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a english name.."
                                name="enname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.enname}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.enname && errors.enname}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.arname
                                ? errors.arname
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Coures Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a arabic name.."
                                name="arname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.arname}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.arname && errors.arname}
                              </div>
                            </div>
                          </div>
                          <div className="free-status-div row">
                            <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                              <input id="avilable-for-free"  type="checkbox" className="mr-1 cursor-pointer scale2"
                                onChange={(e) => {
                                  setFreeStatus(!freeStatus);
                                }}
                                checked = {freeStatus}
                              />
                              <label htmlFor="avilable-for-free" className="is-free-label cursor-pointer pl-2 mb-0">Avilable For Free</label>
                            </div>
                          </div>
                          <div className="visiblaty-div row">
                            <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                              <input id="visiblaty"  type="checkbox" className="mr-1 cursor-pointer scale2"
                                onChange={(e) => {
                                  setvisipilatyStatus(!visipilatyStatus);
                                }}
                                checked = {visipilatyStatus}
                              />
                              <label htmlFor="visiblaty" className="is-free-label cursor-pointer pl-2 mb-0">Visability</label>
                            </div>
                          </div>
                        </div>
                        {
                          !freeStatus
                          &&
                          <>
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.price
                                    ? errors.price
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Enter New Price* (Egyption pound)
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter course price .."
                                    name="price"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.price}
                                    // min={10}
                                  />
                                  <div
                                    id="val-price-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.price && errors.price}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 
                            
                                `}
                              >
                                <label className="text-label">
                                  Choose discount type*
                                </label>
                                <div className="input-group">
                                  <select
                                    className="form-control"
                                    name="discount-type"
                                    onChange={(e) => setDiscountType(e.target.value)}
                                    // onBlur={handleBlur}
                                    defaultValue={discountType}
                                  >
                                    <option value={"Choose Discount Type.."}>Choose Discount Type..</option>
                                    <option value={"fixed"}>Fixed</option>
                                    <option value={"percentage"}>Percentage</option>
                                  </select>
                                  <div
                                    id="val-price-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.discountType && errors.discountType}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.discount
                                    ? errors.discount
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Enter Discount*
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter course discount .."
                                    name="discount"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.discount}
                                    // min={10}
                                  />
                                  <div
                                    id="val-price-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.discount && errors.discount}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.image
                                ? errors.image
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <p className="text-danger">
                              * Uploade New Image, with type of png , jpg , jpeg
                              , webp
                            </p>
                            <label
                              className="form-label"
                              onClick={openFileUploaderImgCourse}
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                padding: "2rem",
                                backgroundColor: "#1f88d9",
                                opacity: "0.5",
                                border: "3px dashed blue",
                                borderRadius: "10px",
                                color: "#111",
                                fontSize: "1rem",
                                textAlign: "center",
                                overflow: "hidden",
                              }}
                            >
                              {values.imageName
                                ? values.imageName
                                : "Upload New Image type of jpg ,png ,jpeg, webp"}
                            </label>
                            <div className="input-group">
                              <input
                                ref={fileUploaderImgCourse}
                                hidden
                                className="form-control form-control-md"
                                type="file"
                                name="image"
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    imageName: e.target.files[0].name,
                                    image: e.target.files[0],
                                  });
                                }}
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-image-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.image && errors.image}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.wallpaper
                                ? errors.wallpaper
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <p className="text-danger">
                              * Uploade New Wallpaper, with type of png , jpg ,
                              jpeg ,webp
                            </p>
                            <label
                              className="form-label"
                              onClick={openFileUploaderWallpaperCourse}
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                padding: "2rem",
                                backgroundColor: "#1f88d9",
                                opacity: "0.5",
                                border: "3px dashed blue",
                                borderRadius: "10px",
                                color: "#111",
                                fontSize: "1rem",
                                textAlign: "center",
                                overflow: "hidden",
                              }}
                            >
                              {values.wallpaperName
                                ? values.wallpaperName
                                : "Upload New Wallpaper Course type of jpg ,png ,jpeg ,webp"}
                            </label>
                            <div className="input-group">
                              <input
                                ref={fileUploaderWallpaperCourse}
                                hidden
                                className="form-control form-control-md"
                                type="file"
                                name="wallpaper"
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    wallpaperName: e.target.files[0].name,
                                    wallpaper: e.target.files[0],
                                  });
                                }}
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-wallpaper-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.wallpaper && errors.wallpaper}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div>
                      <h1>Descriptions </h1>
                      <section>
                        <div className="row ">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.endescription
                                ? errors.endescription
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Description
                            </label>
                            {/* <div className="input-group">
                              <textarea
                                id="subdescription-textarea"
                                rows={5}
                                className="form-control"
                                placeholder="Sub Description"
                                name="endescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endescription}
                              ></textarea>
                              <div
                                id="val-endescription-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endescription && errors.endescription}
                              </div>
                            </div> */}
                            <ReactQuill
                              modules={modules}
                              className="p-0 border-none outline-none form-control bg-white my-3"
                              value={courseEnglishDescription}
                              theme="snow"
                              onChange={(value) => {
                                setCourseEnglishDescription(value)
                                const textWithoutTags = value.replace(/<[^>]+>/g, '');
                                textWithoutTags.length < 10
                                ?
                                setErrEnDescription(true)
                                :
                                setErrEnDescription(false)
                              }}
                            />
                            {
                              errEnDescription
                              &&
                              <p className="mt-1 text-danger">description must not be less than 10 letters</p>
                            }
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.ardescription
                                ? errors.ardescription
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Description
                            </label>
                            {/* <div className="input-group">
                              <textarea
                                id="subdescription-textarea"
                                rows={5}
                                className="form-control"
                                placeholder="Sub Description"
                                name="ardescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ardescription}
                              ></textarea>
                              <div
                                id="val-ardescription-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.ardescription && errors.ardescription}
                              </div>
                            </div> */}
                            <ReactQuill
                              modules={modules}
                              className="p-0 border-none outline-none form-control bg-white my-3"
                              value={courseArabicDescription}
                              theme="snow"
                              onChange={(value) => {
                                setCourseArabicDescription(value)
                                const textWithoutTags = value.replace(/<[^>]+>/g, '');
                                textWithoutTags.length < 10
                                ?
                                setErrArDescription(true)
                                :
                                setErrArDescription(false)
                              }}
                            />
                            {
                              errArDescription 
                              &&
                              <p className="mt-1 text-danger">description must not be less than 10 letters</p>
                            }
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="row">
                      <div className="col">
                        {loading ? (
                          <>
                            <Button variant="primary" disabled>
                              <Spinner animation="grow" variant="info" />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button variant="primary" type="submit">
                              Create
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddCourseForm;

// jshdj

// // // // // <form className="form-wizard" onSubmit={createCourseFunction}>
// // // // <div>
// // // //   <h1>Basic Infromation</h1>
// // // //   <section>
// // // //     <div className="row">
// // // //       <div className="col-lg-6 mb-2">
// // // //         <div className="form-group mb-3">
// // // //           <label className="text-label">Course Name*</label>
// // // //           <input
// // // //             type="text"
// // // //             name="courseName"
// // // //             className="form-control"
// // // //             placeholder="Introduction to something ....."
// // // //             required
// // // //             onChange={(e) => {
// // // //               setCourseTitle(e.target.value);
// // // //             }}
// // // //             value={courseTitle}
// // // //           />
// // // //         </div>
// // // //       </div>
// // // //       <div className="col-lg-6 mb-2">
// // // //         <div className="row">
// // // //           <div className="col-sm-12">
// // // //             <label className="text-label">
// // // //               Course Price* (Egyption pound)
// // // //             </label>
// // // //             <input
// // // //               required
// // // //               type="number"
// // // //               className="form-control"
// // // //               placeholder="Couse Price"
// // // //               onChange={(e) => {
// // // //                 setCoursePrice(e.target.value);
// // // //               }}
// // // //               value={coursePrice}
// // // //             />
// // // //           </div>
// // // //           {/* <div className="col-sm-6 mt-2 mt-sm-0">
// // // //             <label className="text-label">
// // // //               Course Language*
// // // //             </label>
// // // //             <select
// // // //               required
// // // //               className="form-control"
// // // //               onChange={(e) => {
// // // //                 setCourseLanguage(e.target.value);
// // // //               }}
// // // //               value={courseLanguage}
// // // //             >
// // // //               <option defaultValue={null}>
// // // //                 Select Language
// // // //               </option>
// // // //               <option value={"arabic"}>Arabic</option>
// // // //               <option value={"english"}>English</option>
// // // //             </select>
// // // //           </div> */}
// // // //         </div>
// // // //       </div>
// // // //       {/* <div className="col-lg-6 mb-2">
// // // //         <div className="custom-upload-file form-group mb-3">
// // // //           <label
// // // //             htmlFor="img-wallpaper"
// // // //             className="form-label"
// // // //             onClick={() => openFileUploaderImgWallpaper}
// // // //           >
// // // //             <i className="fa-solid fa-upload p-2"></i>
// // // //             {imgWallpaper
// // // //               ? imgWallpaper
// // // //               : "Upload Image Wallpaper 1920 X 700"}
// // // //           </label>
// // // //           <input
// // // //             required
// // // //             ref={fileUploaderImgWallpaper}
// // // //             hidden
// // // //             className="form-control form-control-md"
// // // //             type="file"
// // // //             id="img-wallpaper"
// // // //             onChange={(e) => {
// // // //               setImgWallpaperFile(e.target.files[0]);
// // // //               setImgWallpaper("");
// // // //               setImgWallpaper(e.target.files[0].name);
// // // //             }}
// // // //           />
// // // //         </div>
// // // //       </div> */}
// // // //       <div className="col-lg-12 mb-2">
// // // //         <div className="custom-upload-file form-group mb-3">
// // // //           <label
// // // //             htmlFor="img-course"
// // // //             className="form-label"
// // // //             onClick={() => openFileUploaderImgCourse}
// // // //           >
// // // //             <i className="fa-solid fa-upload p-2"></i>
// // // //             {imgCourse
// // // //               ? imgCourse
// // // //               : "Upload Image Course 700 X 450"}
// // // //           </label>
// // // //           <input

// // // //             ref={fileUploaderImgCourse}
// // // //             hidden
// // // //             className="form-control form-control-md"
// // // //             type="file"
// // // //             id="img-course"
// // // //             onChange={(e) => {
// // // //               setImgCourseFile(e.target.files[0]);
// // // //               setImgCourse("");
// // // //               setImgCourse(e.target.files[0].name);
// // // //             }}
// // // //           />
// // // //         </div>
// // // //       </div>
// // // //     </div>
// // // //   </section>
// // // // </div>
// // // // <div>
// // // //   <h1>Descriptions </h1>
// // // //   <section>
// // // //     <div className="row ">
// // // //       <div className="col-lg-12 mb-2">
// // // //         <div className="form-group mb-3">
// // // //           <label className="text-label">
// // // //             Small Descriptions For Course*
// // // //           </label>
// // // //           <textarea
// // // //             required
// // // //             id="subdescription-textarea"
// // // //             rows={5}
// // // //             className="form-control"
// // // //             placeholder="Sub Description"
// // // //             onChange={(e) => {
// // // //               setSubDescription(e.target.value);
// // // //             }}
// // // //             value={subDescription}
// // // //           ></textarea>
// // // //         </div>
// // // //       </div>

// // // //       {/* <div className="row">
// // // //         <div className="col-xl-12 col-xxl-12">
// // // //           <h4>
// // // //             write Details Description For The Course
// // // //             *(Commitment to description){" "}
// // // //           </h4>
// // // //           <div className="summernote">
// // // //             <Editor
// // // //               initialValue={superDescription}
// // // //               init={{
// // // //                 height: 500,
// // // //                 menubar: false,
// // // //                 plugins: [
// // // //                   "advlist autolink lists link image code charmap print preview anchor",
// // // //                   "searchreplace visualblocks code fullscreen",
// // // //                   "insertdatetime media table paste code help wordcount ",
// // // //                 ],
// // // //                 toolbar:
// // // //                   "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
// // // //                   "bullist numlist outdent indent | removeformat | help ",
// // // //                 content_style: "body { color: #828282 }",
// // // //               }}
// // // //               onEditorChange={handleEditorChange}
// // // //             />
// // // //           </div>
// // // //         </div>
// // // //       </div> */}
// // // //       {/* <div className="col-lg-12 mb-2">
// // // //       <div className="col-sm-4 mt-2 mt-sm-0">
// // // //             <label className="text-label">
// // // //              Number Of Sections *
// // // //             </label>
// // // //             <select
// // // //               required
// // // //               className="form-control"
// // // //               onChange={(e) => {
// // // //                 setNumberOfSection(e.target.value);
// // // //               }}
// // // //               value={numberOfSection}
// // // //             >
// // // //               <option defaultValue={null}>
// // // //                 Select Section Number
// // // //               </option>

// // // //               <option value={1}>1</option>
// // // //               <option value={2}>2</option>
// // // //               <option value={3}>3</option>
// // // //               <option value={4}>4</option>
// // // //               <option value={5}>5</option>
// // // //               <option value={6}>6</option>
// // // //               <option value={7}>7</option>
// // // //               <option value={8}>8</option>
// // // //               <option value={9}>9</option>
// // // //               <option value={10}>10</option>
// // // //             </select>
// // // //           </div>
// // // //       </div> */}
// // // //     </div>
// // // //   </section>
// // // // </div>
// // // // <div className="text-end toolbar toolbar-bottom p-2">
// // // // {loading ? <>
// // // //   <button
// // // //     className="btn btn-primary sw-btn-next ms-1"
// // // //     type="submit"
// // // //     disabled
// // // //   >
// // // //     Loading.....
// // // //   </button>
// // // // </> :
// // // // <>
// // // // <button
// // // //     className="btn btn-primary sw-btn-next ms-1"
// // // //     type="submit"
// // // //   >
// // // //     Submit
// // // //   </button>
// // // // </>}
// // // // </div>
// // // // </form>
