import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const getAllPromocodeApi = createAsyncThunk("promocode/getAll", async (promocodeData) => {
  try {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/user/promocode/`,{
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenData}`
        }
      }
    ).then((res) => res.json());
  } catch (error) {
    swal("Error", error, "info");
    return error;
  }
});

const getAllPromocodeSlicer = createSlice({
  name: "Get All Promocodes",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPromocodeApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPromocodeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getAllPromocodeApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data Fetched Successfully") {
        state.response = action.payload.promocode;
      } else {
        state.error = action.payload.message;
      }
    });
  },
});

export default getAllPromocodeSlicer.reducer;
