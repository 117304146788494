import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const deleteUser = createAsyncThunk("user/delete", async (userId) => {
  const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
  const request = await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/users/delete/${userId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${tokenData}`,
      },
    }
  )
    .then((respons) => respons.json())
    .then((resulet) => {
      return resulet;
    })
    .catch((e) => {
      return e;
    });

  return request;
});


const deleteUserByIdSlicer = createSlice({
  name: "users/delete",
  initialState: {
    responst: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      if (action.payload.message === "deleted successfully") {
        state.responst = action.payload.message;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.error = action.payload.message;
    });
  },
});

export default deleteUserByIdSlicer.reducer;
