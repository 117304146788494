import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Spinner } from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import { addJobFunc } from "../../../store/Slices/jobs/AddJobSlicer";
import ReactQuill from "react-quill";
const createJobSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter Email"),

  enpostion: Yup.string()
    // .min(6, "Job postion must consist of at least 3 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter a Job english postion"),
  arpostion: Yup.string()
    // .min(6, "Job postion must consist of at least 3 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter a Job arabic postion"),

  enlocation: Yup.string()
    // .min(6, "Job location must consist of at least 3 characters ")
    // .max(300, "Job location must consist of at most 300 characters ")
    .required("Please enter a Job english location"),
  arlocation: Yup.string()
    // .min(6, "Job postion must consist of at least 3 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter a Job arabic loction"),
  enCompanyName: Yup.string()
    // .min(6, "Job postion must consist of at least 3 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter English Company Name"),
  arCompanyName: Yup.string()
    // .min(6, "Job postion must consist of at least 3 characters ")
    // .max(300, "Job postion must consist of at most 300 characters ")
    .required("Please enter Arabic Company Name"),
});

const NewJobForm = () => {

  const { loading, error } = useSelector((state) => state.addJobSlicer);

  const dispatch = useDispatch();
  const [userdata, setUserData] = useState();
  const [visipilatyStatus , setvisipilatyStatus] = useState(false);
  const [workType , setWorkType] = useState("remotly");
  const [paidStatus , setPaidStatus] = useState(true);
  // const [paidStatus , setPaidStatus] = useState("paid");
  const [enDescription , setEnDescription] = useState("");
  const [arDescription , setArDescription] = useState("");
  const [errEnDescription , setErrEnDescription] = useState(true);
  const [errArDescription , setErrArDescription] = useState(true);
  const [image , setImage] = useState(null)
  const [imageViewr , setImageViewr] = useState(null);
  const [inputKey, setInputKey] = useState(Date.now());
  const history = useHistory();
  // File Refrances Basic Images
  const [courseFormIntilization, setCourseFormInitialization] = useState({
    email : "",
    enpostion: "",
    arpostion: "",
    enlocation: "",
    paidStatus: "",
  });

  // Get All Data Function
  const handleSubmitData = (value, { resetForm }) => {
    // const jobData = new FormData();
    // jobData.append("postion[ar]" , value.arpostion)
    // jobData.append("postion[en]" , value.enpostion)
    // jobData.append("location[ar]" , value.arlocation)
    // jobData.append("location[en]" , value.enlocation)
    // jobData.append("description[ar]" , arDescription)
    // jobData.append("description[en]" , enDescription)
    // jobData.append("company[ar]" , value.arCompanyName)
    // jobData.append("company[en]" , value.enCompanyName)
    // jobData.append("type" , workType)
    // jobData.append("paid" , paidStatus)
    // jobData.append("visable" , visipilatyStatus)
    // jobData.append("email" , value.email)
    // jobData.append("image" , image)
    const jobData1 = {
        ar : {
            postion : value.arpostion,
            location : value.arlocation,
            description : arDescription,
            company : value.arCompanyName
          },
          en : {
            postion : value.enpostion,
            location : value.enlocation,
            description : enDescription,
            company : value.enCompanyName
          },
        type : workType,
        paid : paidStatus,
        visable : visipilatyStatus,
        email : value.email
    }
    const createFormData = (data) => {
      const formData = new FormData();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
            // Handle nested objects
            for (const nestedKey in data[key]) {
              if (data[key].hasOwnProperty(nestedKey)) {
                formData.append(`${key}[${nestedKey}]`, data[key][nestedKey]);
              }
            }
          } else {
            formData.append(key, data[key]);
          }
        }
      }
      image && formData.append("image" , image)
      return formData;
    };
    const jobData = createFormData(jobData1);
    const sendDataFunc = () => {
      dispatch(addJobFunc(jobData)).then((resulte) => {
        if (resulte.payload.message === "Postion created successfully") {
          Swal.fire({
            title: resulte.payload.message,
            text: "Go to Jobs Page !",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, stay and add new Job",
            confirmButtonText: "Yes, Go to Jobs Page",
          }).then((result) => {
            if (result.isConfirmed) {
                history.push(`/jobs`);
            } else {
              resetForm();
              setvisipilatyStatus(false)
            }
          });
          setArDescription("");
          setEnDescription("");
          setPaidStatus(true);
          setvisipilatyStatus(false);
          setWorkType("remotly");
          setInputKey(Date.now());
          setImageViewr(null)
        } else {
          swal("error", resulte.payload.message, "warning");
        }
      });
    }
    if(arDescription === "" || enDescription === "") {
      Swal.fire({
        title: "Description",
        text: "Job description is required!",
        icon: "warning",
      })
    }else {
      sendDataFunc();
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setUserData(data);
    } else {
      history.push("/login");
    }
  }, []);

  const colors = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
    '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
    '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
    '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
    '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
    '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
  ];
  const modules = {
      toolbar: {
      container: [
      [{"color" : colors}],
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['clean']
      ],
  }
  };


  return (
    <Fragment>
      <PageTitle activeMenu="New Job" motherMenu="Jobs" />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  email: courseFormIntilization.email,
                  
                  enpostion: courseFormIntilization.enpostion,
                  arpostion: courseFormIntilization.arpostion,

                  enlocation: courseFormIntilization.enlocation,
                  arlocation: courseFormIntilization.arlocation,

                  enCompanyName: courseFormIntilization.enCompanyName,
                  arCompanyName: courseFormIntilization.arCompanyName,

                }}
                validationSchema={createJobSchema}
                onSubmit={handleSubmitData}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div>
                      <h1>Job Infromation</h1>
                      <section>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.email
                                ? errors.email
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Email
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Email.."
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.email && errors.email}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.enpostion
                                ? errors.enpostion
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Job Position
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a english Position.."
                                name="enpostion"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.enpostion}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.enpostion && errors.enpostion}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.arpostion
                                ? errors.arpostion
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Job Position
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a arabic postion.."
                                name="arpostion"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.arpostion}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.arpostion && errors.arpostion}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.enCompanyName
                                    ? errors.enCompanyName
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter English Company Name
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter a English Company Name.."
                                    name="enCompanyName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.enCompanyName}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.enCompanyName && errors.enCompanyName}
                                </div>
                                </div>
                            </div>
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.arCompanyName
                                    ? errors.arCompanyName
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter Arabic Company Name
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter a arabic Company Name.."
                                    name="arCompanyName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.arCompanyName}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.arCompanyName && errors.arCompanyName}
                                </div>
                                </div>
                            </div>
                          </div>
                          <div className="row">
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.enlocation
                                    ? errors.enlocation
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter English Job Location
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter a English location.."
                                    name="enlocation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.enlocation}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.enlocation && errors.enlocation}
                                </div>
                                </div>
                            </div>
                            <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                values.enlocation
                                    ? errors.enlocation
                                    ? "is-invalid"
                                    : "is-valid"
                                    : ""
                                }`}
                            >
                                <label className="text-label">
                                Enter Arabic Job Location
                                </label>
                                <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter a arabic location.."
                                    name="arlocation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.arlocation}
                                />
                                <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                >
                                    {errors.arlocation && errors.arlocation}
                                </div>
                                </div>
                            </div>
                          </div>
                          <div className="visiblaty-div row mb-2">
                            <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                              <input id="visiblaty"  type="checkbox" className="mr-1 cursor-pointer scale2"
                                onChange={(e) => {
                                  setvisipilatyStatus(!visipilatyStatus);
                                }}
                                checked = {visipilatyStatus}
                              />
                              <label htmlFor="visiblaty" className="is-free-label cursor-pointer pl-2 mb-0">Visability</label>
                            </div>
                          </div>
                          <div className="row">
                                <div
                                  className={` col-lg-12 col-sm-12 form-group mb-3 `}
                                    >
                                        <label htmlFor="english-work-type">Work Type</label>
                                        <select
                                            className="form-control"
                                            id="english-work-type"
                                            onChange={(e) => {
                                                setWorkType(e.target.value)
                                            }}
                                        >
                                            <option value={"remotly"}>Remotly</option>
                                            <option value={"on site"}>On Site</option>
                                        </select>
                                </div>
                                <div
                                  className={` col-lg-12 col-sm-12 form-group mb-3 `}
                                    >
                                        <label htmlFor="english-work-type">Paid Status</label>
                                        <select
                                            className="form-control"
                                            id="english-work-type"
                                            onChange={(e) => {
                                                setPaidStatus(e.target.value)
                                            }}
                                        >
                                            <option value={true}>Paid</option>
                                            <option value={false}>Not Paid</option>
                                        </select>
                                </div>
                          </div>
                          <div>
                            <h1>Descriptions </h1>
                            <section>
                              <div className="row ">
                                <div
                                  className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                    values.endescription
                                      ? errors.endescription
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Enter English Description
                                  </label>
                                  <ReactQuill
                                    modules={modules}
                                    className="p-0 border-none outline-none form-control bg-white my-3"
                                    value={enDescription}
                                    theme="snow"
                                    onChange={(value) => {
                                      setEnDescription(value)
                                      const textWithoutTags = value.replace(/<[^>]+>/g, '');
                                      textWithoutTags.length < 1
                                      ?
                                      setErrEnDescription(true)
                                      :
                                      setErrEnDescription(false)
                                    }}
                                  />
                                  {
                                    errEnDescription
                                    &&
                                    <p className="mt-1 text-danger">description must not be empty</p>
                                  }
                                </div>
                                <div
                                  className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                    values.ardescription
                                      ? errors.ardescription
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Enter Arabic Description
                                  </label>
                                  <ReactQuill
                                    modules={modules}
                                    className="p-0 border-none outline-none form-control bg-white my-3"
                                    value={arDescription}
                                    theme="snow"
                                    onChange={(value) => {
                                      setArDescription(value)
                                      const textWithoutTags = value.replace(/<[^>]+>/g, '');
                                      textWithoutTags.length < 1
                                      ?
                                      setErrArDescription(true)
                                      :
                                      setErrArDescription(false)
                                    }}
                                  />
                                  {
                                    errArDescription 
                                    &&
                                    <p className="mt-1 text-danger">description must not be empty</p>
                                  }
                                </div>
                              </div>
                            </section>
                          </div>
                          {
                              imageViewr
                              &&
                              <div className="my-2 d-flex justify-content-center">
                                  <img src={imageViewr} alt="img" style={{width : "100px"}} />
                              </div>
                          }
                          <div className="row">
                            <div
                                className={`col-lg-12 col-sm-12 form-group mb-3`}
                            >
                                <label className="text-label">
                                Enter Image (Optional)
                                </label>
                                <div className="input-group">
                                  <input
                                      key={inputKey}
                                      type="file"
                                      className="form-control"
                                      name="image"
                                      onChange={(e) => {
                                          const file = e.target.files[0]
                                          if(file) {
                                              setImage(file);
                                              setImageViewr(URL.createObjectURL(file));
                                          }
                                      }}
                                  />
                                </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="row">
                      <div className="col">
                        {loading ? (
                          <>
                            <Button variant="primary" disabled>
                              <Spinner animation="grow" variant="info" />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button variant="primary" type="submit">
                              Create
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewJobForm;
