import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import * as Yup from "yup";
import { updateJobFunc } from "../../../store/Slices/jobs/UpdateJobSlicer";
import { getJobsFunc } from "../../../store/Slices/jobs/GetJobsSlicer";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import { updateFeedbackFunc } from "../../../store/Slices/feedbacks/UpdateFeedbackSlicer";
const updateFeedbackSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter Email"),
    name: Yup.string()
      .required("Please enter Name"),
    message: Yup.string()
      .required("Please enter Feedback Message"),
  });

export default function UpdateFeedback({show , hide , oldData , refetchData}) {
    const {loading: updateFeedbackLoading} = useSelector(
        state => state.updateFeedbackSlicer
    )
    const [initializeData, setInintializeData] = useState({
        email : "",
        name: "",
        message: "",
      });
    const dispatch = useDispatch();
    const [visipilatyStatus , setvisipilatyStatus] = useState(false);
    const [jobFormIntilization, setJobFormIntilization] = useState({});
    const [id , setId] = useState()
    const [rating , setRating] = useState();
    const [image , setImage] = useState();
    const [imageViewr , setImageViewr] = useState(null);
    useEffect(() => {
        if(oldData) {
            console.log(oldData)
            setInintializeData({name : oldData.name , email : oldData.email , message : oldData.message})
            setvisipilatyStatus(oldData.publish)
            setJobFormIntilization({
                email: oldData.email,
                name: oldData.name,
                message: oldData.message,
            })
            setImageViewr(oldData.image)
            setRating(oldData.rate)
            setId(oldData._id);
        }
    },[oldData])
      const handleSubmitData = (value, { resetForm }) => {
        const feedbackForm = new FormData();
        feedbackForm.append("name" , value.name)
        feedbackForm.append("email" , value.email)
        feedbackForm.append("message" , value.message)
        feedbackForm.append("rate" , rating)
        image && feedbackForm.append("image" , image)
        feedbackForm.append("publish" , visipilatyStatus)
        const sendDataFunc = () => {
          dispatch(updateFeedbackFunc({data : feedbackForm , id})).then((resulte) => {
            if (resulte.payload.message === "Done") {
                resetForm();
                setvisipilatyStatus(false);
                hide();
                refetchData()
                
            } else {
              swal("error", resulte.payload.message, "warning");
            }
          });
        }
        sendDataFunc();
      };
    return (
        <>
            <Modal
            show={show}
            onHide={hide}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Body>
                {
                    oldData && Object.keys(jobFormIntilization).length !== 0
                    &&
                    <Formik
                    initialValues={{
                      email: initializeData.email,
                      name: initializeData.name,
                      message: initializeData.message,
                    }}
                    validationSchema={updateFeedbackSchema}
                    onSubmit={handleSubmitData}
                  >
                    {({
                      values,
                      errors,
                      setValues,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                    }) => (
                      <form className="row" onSubmit={handleSubmit}>
                        <div>
                          <h1>Update Review Infromation</h1>
                          <section>
    
                            {/*email*/}
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.email
                                    ? errors.email
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Enter Email
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email.."
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                  />
                                  <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.email && errors.email}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*name*/}
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.name
                                    ? errors.name
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Enter Name
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Name.."
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                  />
                                  <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.name && errors.name}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {
                                imageViewr
                                &&
                                <div className="my-2 d-flex justify-content-center">
                                    <img src={`${process.env.REACT_APP_VERSEL_API}/${imageViewr}`} alt="img" style={{width : "100px"}} />
                                </div>
                            }
                            {/* image */}
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3`}
                              >
                                <label className="text-label">
                                  Enter Image (optional)
                                </label>
                                <div className="input-group">
                                  {/* <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span> */}
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="image"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if(file) {
                                            setImage(file);
                                            setImageViewr(URL.createObjectURL(file));
                                        }
                                    }}
                                    // onBlur={handleBlur}
                                    // value={values.image}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* message */}
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.message
                                    ? errors.message
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Enter Review Message
                                </label>
                                <div className="input-group">
                                  {/* <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span> */}
                                  <textarea 
                                  rows={5}
                                  className="form-control"
                                  placeholder="Enter Feedback Message.."
                                  name="message"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.message}
                                  >
                                  </textarea>
                                  <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.message && errors.message}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* publish */}
                            <div className="visiblaty-div row mb-2">
                                <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                                  <input id="visiblaty"  type="checkbox" className="mr-1 cursor-pointer scale2"
                                    onChange={(e) => {
                                      setvisipilatyStatus(!visipilatyStatus);
                                    }}
                                    checked = {visipilatyStatus}
                                  />
                                  <label htmlFor="visiblaty" className="is-free-label cursor-pointer pl-2 mb-0">Visability</label>
                                </div>
                            </div>
                            {/* rating */}
                            <div className="row">
                              <div
                                className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                  values.message
                                    ? errors.message
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Select Review Rating
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-file-signature"></i>
                                  </span>
                                    <select
                                    className="form-control"
                                    name="message"
                                    onChange={(e) => {
                                        setRating(e.target.value)
                                    }}
                                    value={rating}
                                    >
                                        {[...Array(5)].map((item , index) => (
                                            <option value={index+1}>
                                                {
                                                    index+1 > 1
                                                    ?
                                                    `${index + 1} Stars`
                                                    :
                                                    `${index + 1} Star`
                                                }
                                            </option>
                                        ))}
                                    </select>
                                  <div
                                    id="val-name-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.rate && errors.rate}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                        <div className="row">
                          <div className="col">
                            <Button
                                variant="secondary"
                                onClick={hide}
                                style={{marginRight : "4px"}}
                            >
                                Close
                            </Button>
                            {updateFeedbackLoading ? (
                              <>
                                <Button variant="primary" disabled>
                                  <Spinner animation="grow" variant="info" />
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button variant="primary" type="submit">
                                  Update
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                }
            </Modal.Body>
            </Modal>
        
        </>
    )
}
