import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../services/LogoutFunction";

export const getLessonsApi = createAsyncThunk(
  "lessons/getall",
  async (coureseId) => {

    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/lessons/getAll/${coureseId}`,
      {
        method: "GET",
        headers: {
          Authorization:
            `Bearer ${tokenData}`,
        },
      }
    ).then(response => response.json()).then(result => {
      console.log(result);
        return result
    }).catch(error =>{
      console.log(error);
        formatError(error)
        return error;
    })
    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const getAllLessonsSlicer = createSlice({
  name: "getLessons",
  initialState: {
    loading: false,
    lessons: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonsApi.pending,state =>{
        state.loading = true;
        state.lessons = null;
        state.error = null;
    });
    builder.addCase(getLessonsApi.fulfilled,(state,action) =>{
        state.loading = false;
        if (action.payload.message === "jwt expired") {
          state.error = action.payload.message;
          formatError(state.error);
          LogoutFunction();
        }
        else if (action.payload.data) {
            state.lessons = action.payload.data;
            state.error = null;
        }
       else{
       
            state.lessons = null;
            state.error = action.payload.message;
            formatError( state.error);
        }


    });
    builder.addCase(getLessonsApi.rejected,(state,action) =>{
        state.loading = false;
            state.lessons = null;
            state.error = action.payload.message;
            formatError(state.error);

    });
  },
});


export default getAllLessonsSlicer.reducer;