import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../../../services/LogoutFunction";

export const addSubscription = createAsyncThunk(
  "user/addSubscription",
  async (subscriptionData) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/createSubscription`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
        body: subscriptionData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        formatError(error);
        return error;
      });

    return request;
  }
);

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}
const addSubscriptionSlicer = createSlice({
  name: "addSubscription",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(addSubscription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addSubscription.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      } else if (action.payload.message === "Data Add Successfully") {
        state.data = action.payload.data;
      } else {
        state.error = action.payload.message;
        formatError(state.error);
      }
    });
    builder.addCase(addSubscription.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      formatError(state.error);
    });
  },
});

export default addSubscriptionSlicer.reducer;
