import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

export const getAllCourseToDiplomeApi = createAsyncThunk(
  "diplome/getAllCourse",
  async () => {
    try {
      const tokenData = await JSON.parse(localStorage.getItem("userData"))
        .token;
      return await fetch(
        `${process.env.REACT_APP_VERSEL_API}/api/users/diplome/getAllCourses`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokenData}`,
          },
        }
      ).then((res) => res.json());
    } catch (error) {
      swal("Error", error, "info");
      return error;
    }
  }
);

const getAllCoursesToDiplomeSlicer = createSlice({
  name: "get All Course",
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCourseToDiplomeApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCourseToDiplomeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getAllCourseToDiplomeApi.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
  },
});

export default getAllCoursesToDiplomeSlicer.reducer;
